import React from 'react';
import {useController} from 'react-hook-form';
import {Select, FormControl, InputLabel, MenuItem, FormHelperText, Typography, ListSubheader} from '@mui/material';
import {capitalize} from 'lodash';

export default function SelectField(props) {
    const {name, rule: rawRules, required, label, options, fullWidth, margin, sx, helperText, allowBlank = false, ...rest} = props;
    const rules = {...rawRules};
    const fieldLabel = label !== false ? label || capitalize(name) : null;

    if (required && !rules.required) {
        rules.required = fieldLabel ? `${fieldLabel} is required` : 'This field is required';
    }

    const {field, fieldState: {error}} = useController({name, rules});
    const {value, ...restField} = field;

    return (
        <FormControl fullWidth={fullWidth} margin={margin} sx={sx} error={!!error} required={required}>
            {fieldLabel && <InputLabel id={`${name}-label`}>{label}</InputLabel>}
            <Select
                label={fieldLabel}
                value={value || ''}
                {...rest}
                {...restField}
                error={!!error}
            >
                {allowBlank && <MenuItem value=""><em>None</em></MenuItem>}
                {options.map(option => {
                    const {value, label, subLabel, subheader} = option;

                    if (subheader) {
                        return (
                            <ListSubheader key={subheader}>
                                {subheader}
                            </ListSubheader>
                        );
                    }

                    return (
                        <MenuItem key={value} value={value}>
                            {label}
                            {subLabel && <Typography component="span" sx={{color: 'text.secondary', ml: 0.5, fontStyle: 'italic'}}>{subLabel}</Typography>}
                        </MenuItem>
                    );
                })}
            </Select>
            {error && helperText !== false && <FormHelperText error>{error.message || helperText}</FormHelperText>}
        </FormControl>
    );
};
