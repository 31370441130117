import React, {useEffect, useCallback, useContext, useState} from 'react';
import {Typography, Divider, Button, Grid2 as Grid, Paper, Stack, Box, IconButton, TableContainer, Table, TableHead, TableRow, TableCell, TableBody} from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {useFieldArray} from 'react-hook-form';
import {Viewer, Worker} from '@react-pdf-viewer/core';
import {thumbnailPlugin} from '@react-pdf-viewer/thumbnail';
import {ref, getDownloadURL} from 'firebase/storage';
import {useSnackbar} from 'notistack';
import {get} from 'lodash';
import moment from 'moment';
import HideImageIcon from '@mui/icons-material/HideImage';
import UploadIcon from '@mui/icons-material/Upload';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import ReorderIcon from '@mui/icons-material/Reorder';
import WindowIcon from '@mui/icons-material/Window';
import {useConfirm} from 'material-ui-confirm';

import {gray} from '-/style/themePrimitives';

import {storage} from '-/firebase';
import {UserContext} from '-/contexts/User';

export const pageThumbnailPlugin = props => {
    const {PageThumbnail} = props;

    return {
        renderViewer: renderProps => {
            let {slot} = renderProps;

            slot.children = PageThumbnail;

            // Reset the sub slot
            slot.subSlot.attrs = {};
            slot.subSlot.children = <></>;

            return slot;
        }
    };
};

const PDFThumbnail = ({url}) => {
    const thumbnailPluginInstance = thumbnailPlugin();
    const {Cover} = thumbnailPluginInstance;
    const pageThumbnailPluginInstance = pageThumbnailPlugin({
        PageThumbnail: <Cover getPageIndex={() => 0} />
    });

    return (
        <Worker workerUrl={'https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js'}>
            <Viewer fileUrl={url} plugins={[pageThumbnailPluginInstance, thumbnailPluginInstance]} />
        </Worker>
    );
};

const File = ({file, index, disabled, onDelete, onDownload, ...rest}) => {
    const {filePath, file: rawFile, thumbnailPath, mimeType: incomingMimeType, uploadedAt} = file;
    const mimeType = incomingMimeType || get(rawFile, 'type');
    const isPdf = mimeType === 'application/pdf';

    const [url, setUrl] = useState(null);
    const [thumbnailUrl, setThumbnailUrl] = useState(null);
    const {currentUser} = useContext(UserContext);
    const dateFormatLong = get(currentUser, 'settings.dateFormatLong') || 'LLL';

    const fetchUrls = useCallback(async() => {
        if (rawFile) {
            const {type} = rawFile;
            if (type.startsWith('image/')) {
                const imageUrl = URL.createObjectURL(rawFile);
                setThumbnailUrl(imageUrl);
            } else if (type === 'application/pdf') {
                const pdfUrl = URL.createObjectURL(rawFile);
                setUrl(pdfUrl);
            }

            return;
        }

        if (!filePath) {
            return;
        }
        
        try {
            const url = await getDownloadURL(ref(storage, filePath));
            setUrl(url);

            if (thumbnailPath) {
                const thumbnailUrl = await getDownloadURL(ref(storage, thumbnailPath));
                setThumbnailUrl(thumbnailUrl);
            }
        } catch(e) {
            console.warn(e);
        }
    }, [filePath, rawFile]);

    useEffect(() => {
        fetchUrls();
    }, [filePath]);

    const handleDelete = e => {
        if (e.target.closest('.MuiIconButton-root')) {
            e.stopPropagation();
        }

        onDelete(file, index);
    };

    const label = name || get(file, 'file.name');
    
    return (
        <Grid size={{xs: 6, sm: 4, md: 3}} {...rest}>
            <Paper sx={{aspectRatio: '3/4', p: 1, display: 'flex', alignItems: 'stretch', overflow: 'hidden'}}>
                <Stack
                    spacing={1}
                    sx={{
                        width: '100%',
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'stretch'
                    }}
                >
                    <Box
                        onClick={() => onDownload(file, index)}
                        sx={{
                            position: 'relative',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                            flex: 1,
                            border: '1px solid',
                            borderColor: 'divider',
                            borderRadius: 0.5,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            backgroundColor: 'grey.200',
                            overflow: 'hidden',
                            ...thumbnailUrl && {backgroundImage: `url(${thumbnailUrl})`}
                        }}
                    >
                        {!thumbnailUrl && !isPdf && !url && <HideImageIcon sx={{color: 'grey.500', fontSize: 40}} />}
                        {isPdf && url && <PDFThumbnail url={url} />}
                        {!disabled && (
                            <Stack sx={{position: 'absolute', top: 8, right: 8}} direction="row" spacing={1}>
                                <IconButton onClick={handleDelete}>
                                    <DeleteIcon />
                                </IconButton>
                            </Stack>
                        )}
                    </Box>
                    {label && (
                        <Typography component="div" variant="caption" color="text.secondary" sx={{textAlign: 'center'}}>{label}</Typography>
                    )}
                    {uploadedAt && (
                        <Typography component="div" variant="caption" color="text.secondary" sx={{textAlign: 'center'}}>Uploaded {moment(uploadedAt).format(dateFormatLong)}</Typography>
                    )}
                </Stack>
            </Paper>
        </Grid>
    );
};

const FilesList = ({files, onDownload, onDelete, Footer, ...rest}) => {
    return (
        <Box {...rest}>
            <TableContainer component={Paper} variant="outlined">
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {files.length === 0 && (
                            <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                <TableCell colSpan={2} align="center">
                                    No files
                                </TableCell>
                            </TableRow>
                        )}
                        {files.map((file, index) => {
                            const {name, filePath} = file;
                            const label = name || get(file, 'file.name');
                            
                            return (
                                <TableRow key={file.id} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                    <TableCell>
                                        <Typography variant="body">{label}</Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        {filePath && (
                                            <IconButton onClick={() => onDownload(file, index)}>
                                                <DownloadIcon />
                                            </IconButton>
                                        )}
                                        <IconButton onClick={() => onDelete(file, index)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
                
                {Footer && <Footer sx={{borderTop: '1px solid', borderColor: gray[200]}} />}
            </TableContainer>
        </Box>
    );
};

const FilesPreviewGrid = ({files, sx, Footer, ...rest}) => {
    return (
        <Paper variant="outlined">
            <Grid container spacing={1} sx={sx}>
                {files.length === 0 && (
                    <Typography component="div" variant="body2" sx={{width: '100%', p: 2, textAlign: 'center'}}>No files</Typography>
                )}
                {files.map((file, index) => (
                    <File key={`file-${index}`} file={file} index={index} {...rest} />
                ))}
            </Grid>

            <Divider />

            {Footer && <Footer />}
        </Paper>
    );
};

export default function ControlledFileField(props) {
    const {
        name = 'files',
        accept = 'image/*,video/*,application/pdf,.txt,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        multiple = true,
        disabled,
        ...rest
    } = props;

    const {enqueueSnackbar} = useSnackbar();
    const [component, setComponent] = useState('grid');
    const confirm = useConfirm();

    const {fields, append, remove} = useFieldArray({
        name
    });

    const handleDelete = async (file, index) => {
        if (disabled) {
            return;
        }

        const onDelete = async() => {
            remove(index);
        };

        const {file: rawFile} = file;
        if (rawFile) {
            onDelete();
            return;
        }

        const {confirmed} = await confirm({
            description: 'Are you sure you want to delete this file?',
            confirmationText: 'Delete File'
        });

        if (confirmed) {
            onDelete();
        }
    };

    const handleDownload = async file => {
        const {file: rawFile, filePath} = file;
        if (rawFile) {
            return;
        }

        try {
            const url = await getDownloadURL(ref(storage, filePath));
            window.open(url, '_blank');
        } catch(e) {
            enqueueSnackbar('Failed to download file', {variant: 'error'});
        }
    };

    const Component = component === 'list' ? FilesList : FilesPreviewGrid;

    const UploadButton = (
        <Button
            variant="outlined"
            component="label"
            size="small"
            disabled={disabled}
            startIcon={<UploadIcon />}
        >
            Add File
            <input
                type="file"
                hidden
                multiple={multiple}
                {...accept && {accept}}
                onChange={e => {
                    for (const file of e.target.files) {
                        append({file});
                    }

                    e.target.value = null;
                }}
            />
        </Button>
    );

    const Footer = props => {
        const {sx, ...rest} = props;

        return (
            <Stack direction="row" justifyContent="space-between" spacing={1} sx={{p: 1, ...sx}} {...rest}>
                <ToggleButtonGroup
                    value={component}
                    onChange={(e, newComponent) => newComponent && setComponent(newComponent)}
                    exclusive
                    size="small"
                >
                    <ToggleButton size="small" value="grid">
                        <WindowIcon />
                    </ToggleButton>
                    <ToggleButton size="small" value="list">
                        <ReorderIcon />
                    </ToggleButton>
                </ToggleButtonGroup>

                {UploadButton}
            </Stack>
        );
    };

    return (
        <Component
            files={fields}
            disabled={disabled}
            onDownload={handleDownload}
            onDelete={handleDelete}
            {...rest}
            Footer={Footer}
        />
    );
};
