import React, {useState, useEffect, useContext} from 'react';
import {useMediaQuery, Grid2 as Grid, Skeleton, Divider, TableContainer, Table, TableBody, TableCell, TableRow, Typography, Stack, Box} from '@mui/material';
import {PieChart, LineChart} from '@mui/x-charts';
import {collection, query, where} from 'firebase/firestore';
import moment from 'moment';
import {get, groupBy, reduce} from 'lodash';
import {useTheme} from '@mui/material/styles';
import {FirefighterRanks, IncidentTypes} from '@embertracking/common';

import {db} from '-/firebase';
import {UserContext} from '-/contexts/User';
import {StationsContext} from '-/contexts/Stations';

import {joinWithAnd, formatDuration} from '-/utils';

import {hasPermission, populateUsers, getCollection, populateKeyFromCollection, colorForIncidentType} from '-/data/utils';

import Apparatuses from './apparatuses/Grid';

import StatCard from '-/components/stats/StatCard';

const MemberResponseTableRow = props => {
    const {index, fullName, incidents, ...rest} = props;

    return (
        <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}} {...rest}>
            <TableCell component="th" scope="row" sx={{py: 0.5, fontSize: '0.8rem', fontWeight: 'bold', width: 30}}>
                {index}
            </TableCell>
            <TableCell component="th" scope="row" sx={{px: 0, py: 0.5, fontSize: '0.8rem'}}>
                {fullName}
            </TableCell>
            <TableCell align="right" sx={{py: 0.5, display: 'flex', alignItems: 'flex-end', fontSize: '0.8rem'}}>{incidents}</TableCell>
        </TableRow>
    );
};

const IncidentsDaysCard = ({loading, incidents = [], days = 30}) => {
    const filtered = incidents.filter(incident => {
        const {date} = incident;
        return date.isAfter(moment().subtract(days, 'days'));
    });
    const grouped = groupBy(filtered, 'type');

    return (
        <StatCard
            heading={filtered.length}
            title="Incidents"
            interval={`Last ${days} days`}
            overlap
            sx={{height: 180}}
        >
            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: 10}}>
                <PieChart
                    loading={loading}
                    width={150}
                    height={150}
                    margin={{left: 0, right: 0, top: 0, bottom: 0}}
                    series={[{
                        data: Object.keys(grouped).map(type => ({
                            label: IncidentTypes[type],
                            value: grouped[type].length,
                            color: colorForIncidentType(type)
                        })),
                        highlightScope: {fade: 'global', highlight: 'item'},
                        label: 'Number of Incidents',
                        innerRadius: 20,
                        paddingAngle: 2,
                        cornerRadius: 2
                        // cx: '80%'
                    }]}
                    slotProps={{
                        legend: {
                            hidden: true
                            // labelStyle: {
                            //     fontSize: 10
                            // },
                            // itemMarkWidth: 10,
                            // itemMarkHeight: 3,
                            // markGap: 7,
                            // itemGap: 5
                        }
                    }}
                />
            </Box>
        </StatCard>
    );
};

const IncidentsYearCard = ({loading, incidents: allIncidents = []}) => {
    const startDate = moment().startOf('year');
    const incidents = allIncidents.filter(incident => {
        const {date} = incident;
        return date.isAfter(startDate);
    });

    const incidentsByType = reduce(incidents.reduce((result, doc) => {
        const {type} = doc;

        if (!result[type]) {
            result[type] = {
                value: 0,
                label: IncidentTypes[type],
                type,
                incidents: []
            };
        }

        result[type].value++;
        result[type].incidents.push(doc);

        return result;
    }, {}), (result, value, key) => {
        const {type, incidents} = value || {};
        const groupedByMonth = groupBy(incidents, incident => {
            const {date} = incident;
            return moment(date).format('YYYY-M');
        });

        result.push({
            id: key,
            ...value,
            type,
            groupedByMonth,
            color: colorForIncidentType(type)
        });

        return result;
    }, []).sort((a, b) => {
        return b.value < a.value ? 1 : -1;
    });

    const incidentsByMonth = reduce(incidents.reduce((result, doc) => {
        const {date} = doc;
        const month = moment(date).format('YYYY-M');

        if (!result[month]) {
            result[month] = {
                date,
                value: date,
                label: moment(date).format('MMMM YYYY'),
                incidents: []
            };
        }

        result[month].incidents.push(doc);

        return result;
    }, {}), (result, value, key) => {
        const {incidents} = value || {};
        const groupedByType = groupBy(incidents, 'type');

        result.push({
            id: key,
            ...value,
            groupedByType
        });

        return result;
    }, []);

    return (
        <StatCard heading={incidents.length} title="Incidents" interval="This year" overlap sx={{height: 180}}>
            <LineChart
                loading={loading}
                height={150}
                leftAxis={null}
                xAxis={[{
                    scaleType: 'point',
                    data: incidentsByMonth.map(({label}) => label)
                }]}
                series={incidentsByType.map(byType => {
                    const {type, label, groupedByMonth} = byType;
                    const data = incidentsByMonth.map(byMonth => {
                        const {id} = byMonth;

                        const [year, month] = id.split('-');
                        let total = 0;
                        
                        for (let i = 1; i <= parseInt(month); i++) {
                            const grouped = groupedByMonth[`${year}-${i}`] || [];
                            total += grouped.length;
                        }

                        return total;
                    });

                    return {
                        type: 'line',
                        stack: 'total',
                        color: colorForIncidentType(type),
                        label,
                        data,
                        area: true,
                        curve: 'natural',
                        showMark: false
                    };
                })}
                axisHighlight={{
                    x: 'band',
                    y: 'none'
                }}
                slotProps={{
                    legend: {
                        hidden: true
                    }
                }}
                margin={{top: 0, left: 0, right: 0, bottom: 0}}
            />
        </StatCard>
    );
};

const IncidentsResponseCard = ({loading, station, incidents = []}) => {
    const {users} = useContext(UserContext);
    const filtered = incidents.filter(incident => {
        const {date} = incident;
        return date.isAfter(moment().subtract(90, 'days'));
    });

    const attendance = users.reduce((result, user) => {
        const {uid, role, station: userStationUid, archived, oldUserUid, ...rest} = user;
        if (!FirefighterRanks.includes(role)) {
            return result;
        }

        if (archived) {
            return result;
        }
        
        if (station && get(station, 'uid') !== userStationUid) {
            return result;
        }

        const userIncidents = filtered.reduce((result, incident) => {
            const {users = [], members = []} = incident;
            const allUsers = [
                ...users,
                ...members
            ];

            if (allUsers.filter(Boolean).find(user => (user.uid === uid || user.oldUserUid === uid || user === uid))) {
                result.push(incident);
            }

            return result;
        }, []);

        result.push({
            uid,
            ...rest,
            incidents: userIncidents
        });

        return result;
    }, []).sort((a, b) => {
        return b.incidents.length < a.incidents.length ? -1 : 1;
    }).slice(0, 5);

    if (!loading && !attendance.length) {
        return null;
    }

    return (
        <StatCard title="Member Response" interval="Last 90 days" legend={station ? `Station ${station.name}` : null}>
            <TableContainer sx={{mt: 1}}>
                <Table size="small">
                    <TableBody>
                        {loading ? Array.from({length: 5}).map((row, index) => (
                            <MemberResponseTableRow
                                key={index}
                                index={index + 1}
                                fullName={<Skeleton variant="text" width={100} />}
                                incidents={<Skeleton variant="text" width={30} />}
                            />
                        )) : attendance.map((row, index) => {
                            const {uid, fullName, incidents} = row;

                            return (
                                <MemberResponseTableRow key={uid} index={index + 1} fullName={fullName} incidents={incidents.length} />
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </StatCard>
    );
};

const LastIncidentStat = ({loading, incident}) => {
    if (!incident && !loading) {
        return null;
    }

    const {type, location, date, durations, apparatus = [], members = [], users = []} = incident;
    const {total: totalDuration} = durations || {};
    const {latitude, longitude} = location || {};
    const allUsers = [...members, ...users];

    const color = 'red';
    let mapUrl;
    if (latitude && longitude) {
        mapUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&markers=color:${color}%7Clabel:S%7C${latitude},${longitude}&zoom=13&size=640x300&scale=2&maptype=roadmap&key=AIzaSyCCI_7230YkA7R8LJXiqrg6_zVlfy9eSfk`;
    }

    return (
        <StatCard
            loading={loading}
            title="Last Incident"
            heading={loading ? <Skeleton width={150} /> : IncidentTypes[type]}
            interval={loading ? <Skeleton width={100} /> : date && date.format('LLL')}
            legend={loading ? <Skeleton width={50} /> : date && date.fromNow()}
        >
            <Box
                sx={{
                    position: 'absolute',
                    inset: 0,
                    opacity: 1,
                    maskImage: 'linear-gradient(135deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5))',
                    ...mapUrl && {background: `url(${mapUrl}) no-repeat 35% 45%`}
                }}
            />
            
            {(loading || allUsers.length > 0 || apparatus.length > 0 || !!totalDuration) && (
                <Divider sx={{my: 1}} />
            )}

            <Stack>
                {(loading || allUsers.length > 0) && (
                    <Typography variant="caption" sx={{color: 'text.secondary'}}>
                        {loading ? <Skeleton width={100} /> : (
                            <>
                                Attendance: <strong>{allUsers.length}</strong>
                            </>
                        )}
                    </Typography>
                )}

                {(loading || apparatus.length > 0) && (
                    <Typography variant="caption" sx={{color: 'text.secondary'}}>
                        {loading ? <Skeleton width={100} /> : (
                            <>
                                Apparatus: <strong>{joinWithAnd(apparatus.map(a => get(a, 'tag') || 'Unknown'))}</strong>
                            </>
                        )}
                    </Typography>
                )}

                {!!totalDuration && (
                    <Typography variant="caption" sx={{color: 'text.secondary'}}>
                        {loading ? <Skeleton width={100} /> : (
                            <>
                                On scene time: <strong>{formatDuration(totalDuration)}</strong>
                            </>
                        )}
                    </Typography>
                )}
            </Stack>
        </StatCard>
    );
};

const IncidentsCards = () => {
    const [incidents, setIncidents] = useState([]);
    const [loading, setLoading] = useState(false);
    const {stations} = useContext(StationsContext);

    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    async function fetch() {
        setLoading(true);

        try {
            const endDate = moment().endOf('day');
            let startDate = moment(endDate).startOf('year');

            if (endDate.diff(startDate, 'days') < 90) {
                startDate = moment(endDate).subtract(90, 'days');
            }

            const ref = collection(db, 'incidents');
            const q = query(ref, where('date', '>=', startDate.toDate()), where('date', '<=', endDate.toDate()));
            let docs = await getCollection(db, q);
            docs = await populateUsers(db, docs);
            docs = await populateKeyFromCollection(db, docs, 'apparatus', 'apparatus');

            setIncidents(docs);
        } catch (e) {
            console.error('Error fetching incidents:', e);
        }

        setLoading(false);
    }

    useEffect(() => {
        fetch();
    }, []);

    const lastIncident = incidents.length && incidents[incidents.length - 1];
    const showYearCard = new Date().getMonth() > 1;

    const hasMultipleStations = stations.length > 1;
    const responseCards = hasMultipleStations ? (
        <>
            {stations.map((station, index) => (
                <IncidentsResponseCard key={`incidents-response-station-${index}`} loading={loading} incidents={incidents} station={station} />
            ))}
        </>
    ) : (
        <IncidentsResponseCard loading={loading} incidents={incidents} />
    );

    const incidentCards = (
        <>
            <IncidentsDaysCard loading={loading} incidents={incidents} days={90} />
            {showYearCard && <IncidentsYearCard loading={loading} incidents={incidents} />}
        </>
    );

    if (isSmall) {
        return (
            <Stack spacing={1}>
                <LastIncidentStat loading={loading} incident={lastIncident} />
                {incidentCards}
                {responseCards}
            </Stack>
        );
    }

    return (
        <Grid container spacing={1}>
            <Grid size={6}>
                <Stack spacing={1}>
                    <LastIncidentStat loading={loading} incident={lastIncident} />
                    {hasMultipleStations ? incidentCards : responseCards}
                </Stack>
            </Grid>

            <Grid size={6}>
                <Stack spacing={1}>
                    {hasMultipleStations ? responseCards : incidentCards}
                </Stack>
            </Grid>
        </Grid>
    );
};

export default function() {
    const {currentUser} = useContext(UserContext);

    return (
        <>
            <Typography variant="h5" gutterBottom>Overview</Typography>
            {hasPermission(currentUser, 'incidents.stats') && <IncidentsCards />}

            {hasPermission(currentUser, 'apparatus.read') && (
                <>
                    <Typography variant="h5" gutterBottom sx={{mt: 4}}>Apparatus</Typography>
                    <Apparatuses />
                </>
            )}
        </>
    );
};