import {projectId} from '-/firebase';

const isDev = projectId === 'embertracking-demo';
const isJRFD = projectId === 'jrfd-log';

const features = {
    authIssues: true,
    jibcConsent: true,
    memberStatistics: false,
    // impersonateUser: false,
    vectorSolutionsSync: false,
    ropes: false,
    respond: false,
    training: true,
    trainingStatistics: false,
    incidents: true,
    importIncidentsFromIAR: true,
    driverLogs: false,
    importIncidentAndTrainingRecordsFromIAR: isJRFD,
    // tasks: isDev,
    quizzes: isDev || isJRFD
};

export const hasFeature = feature => {
    return features[feature] === true;
};