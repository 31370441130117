import React, {useState, useEffect, useContext} from 'react';
import {TextField, Autocomplete, Chip} from '@mui/material';
import {useController} from 'react-hook-form';
import {capitalize, orderBy, isString} from 'lodash';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import {FirefighterRanks} from '@embertracking/common';

import {UserContext} from '-/contexts/User';
import {StationsContext} from '-/contexts/Stations';

import {hasPermission} from '-/data/utils';

const getUserLabel = (user, users = []) => {
    if (!user) {
        return;
    }

    const {uid, fullName, email} = user || {};
    if (!fullName && !email) {
        const oldUser = (users || []).find(u => u.oldUserUid && u.oldUserUid === uid);
        if (oldUser) {
            return getUserLabel(oldUser, users);
        }
    }

    return fullName || email;
};

export default function ControlledAutocompleteField(props) {
    const {name, label, rules: rawRules, required, helperText, showCount = false, limitToStation, onFilter, allowedRanks, multiple = true, ...rest} = props;
    let fieldLabel = label !== false ? label || capitalize(name) : null;
    const rules = {...rawRules};
    if (required && !rules.required) {
        rules.required = fieldLabel ? `${fieldLabel} is required` : 'This field is required';
    }

    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const {currentUser, users} = useContext(UserContext);
    const {stations} = useContext(StationsContext);
    const {station} = currentUser;
    
    useEffect(() => {
        let docs = users.slice();
        if (limitToStation && !hasPermission(currentUser, 'admin') && station && stations.length > 1) {
            docs = docs.filter(doc => doc.station === currentUser.station);
        }

        docs = docs.filter(doc => doc.archived !== true);
        docs = docs.filter(doc => doc.role !== 'STATION');

        if (allowedRanks) {
            docs = docs.filter(user => {
                const {role} = user || {};
                return allowedRanks.includes(role);
            });
        }

        docs = orderBy(docs, [
            user => FirefighterRanks.indexOf(user.role),
            'lastName'
        ], ['desc', 'asc']);

        setOptions(docs);
    }, [users, currentUser, limitToStation]);

    useEffect(() => {
        if (onFilter) {
            setOptions(prevOptions => onFilter(prevOptions));
        }
    }, [onFilter]);

    const handleRenderTags = (value, getTagProps) => {
        return value.map((option, index) => {
            const {key, ...tagProps} = getTagProps({index});
            const user = typeof option === 'string' ? users.find(u => u.uid === option || u.oldUserUid === option) : option;
            const label = getUserLabel(user, users);

            return (
                <Chip label={label || 'Unknown'} key={key} {...tagProps} />
            );
        });
    };

    const {field, fieldState: {error}} = useController({name, rules});
    const {value, onChange, ...restField} = field;

    if (showCount && value && value.length) {
        fieldLabel = `${fieldLabel}: ${value.length}`;
    }

    return (
        <Autocomplete
            {...rest}
            {...restField}
            multiple={multiple}
            value={value || []}
            options={options}
            onChange={(e, newValue) => {
                if (!multiple) {
                    if (!newValue) {
                        onChange(null);
                        return;
                    }

                    onChange(isString(newValue) ? newValue : newValue.uid);
                    setOpen(false);

                    return;
                }

                onChange(newValue.map(v => {
                    if (isString(v)) {
                        return v;
                    }

                    return v.uid;
                }));
            }}
            getOptionLabel={option => {
                if (isString(option)) {
                    option = options.find(o => o.uid === option);
                }

                return getUserLabel(option) || '';
            }}
            isOptionEqualToValue={(option, value) => {
                if (value) {
                    if (isString(value)) {
                        return option.uid === value;
                    }

                    return option.uid === value.uid;
                }

                return false;
            }}
            onInputChange={(e, newInputValue) => {
                if (e && newInputValue) {
                    setOpen(true);
                }
            }}
            // onFocus={() => {
            //     setOpen(true);
            // }}
            filterSelectedOptions
            renderInput={params => (
                <TextField
                    name={name}
                    {...params}
                    required={required}
                    error={!!error}
                    helperText={error ? error.message : helperText}
                    onClick={() => {
                        setOpen(!open);
                    }}
                    label={fieldLabel}
                />
            )}
            renderTags={handleRenderTags}
            renderOption={(props, option, {inputValue}) => {
                const {key, ...optionProps} = props;
                const label = getUserLabel(option);
                const matches = match(label, inputValue, {insideWords: true});
                const parts = parse(label, matches);
        
                return (
                    <li key={key} {...optionProps}>
                        <div>
                            {parts.map((part, index) => (
                                <span key={index} style={{fontWeight: part.highlight ? 700 : 400}}>
                                    {part.text}
                                </span>
                            ))}
                        </div>
                    </li>
                );
            }}
            open={open}
            onClose={e => {
                const {type} = e;

                if (type === 'blur') {
                    setOpen(false);
                }
            }}
        />
    );
};
