import React, {useRef, useState, useEffect, useCallback, useContext} from 'react';
import {useTheme} from '@mui/material/styles';
import {Box, Grid2 as Grid, Paper, Stack, InputAdornment, FormControl, FormControlLabel, Checkbox, ButtonGroup, CircularProgress, Divider, useMediaQuery, Accordion, Button, AccordionSummary, Typography} from '@mui/material';
import {useParams, useNavigate} from 'react-router-dom';
import {useSnackbar} from 'notistack';
import moment from 'moment';
import {doc, addDoc, collection, where, query, limit, orderBy, onSnapshot, updateDoc, deleteDoc} from 'firebase/firestore';
import useDebouncedEffect  from  'use-debounced-effect';
import {useForm, FormProvider} from 'react-hook-form';
import {get, isNil, castArray} from 'lodash';
import {httpsCallable} from 'firebase/functions';
import SignatureCanvas from 'react-signature-canvas';
import {OfficerRanks, DrivingCodes} from '@embertracking/common';

import {UserContext} from '-/contexts/User';
import {db, functions} from '-/firebase';

import {hasFeature} from '-/features';

import {green, orange, red} from '-/style/themePrimitives';

import {hasPermission, ensureJSDates, populateUsers, processRawDoc, getCollection, getCollectionDoc} from '-/data/utils';

import SelectField from '-/form/SelectField';
import NumberField from '-/form/NumberField';
import UserAutocompleteField from '-/form/UserAutocompleteField';
import TextField from '-/form/TextField';
import InchField from '-/form/InchField';
import ToggleButtonField from '-/form/ToggleButtonField';

const isDev = process.env.NODE_ENV === 'development';

const defaultChecks = [
    {text: 'Cab'},
    {text: 'Devices'},
    {text: 'Cargo Secure'},
    {text: 'Drive Controls'},
    {text: 'Driver Seat'},
    {text: 'Emergency Lighting'},
    {text: 'Parking Brake'},
    {text: 'Horn'},
    {text: 'Emergency Equipment'},
    {text: 'Rear Vision Mirrors'},
    {text: 'Exhaust System'},
    {text: 'Fuel System'},
    {text: 'Hydraulic Brake System', hasAirBrakes: false},
    {text: 'Lamps & Reflectors'},
    {text: 'Steering Mechanism'},
    {text: 'Service Brakes', hasAirBrakes: true},
    {text: 'Safety'},
    {text: 'Suspension System'},
    {text: 'Tires'},
    {text: 'Wheels'},
    {text: 'Hubs & Fasteners'},
    {text: 'Windshield'},
    {text: 'Wipers & Washers'},
    {text: 'Coupling Devices', hasCouplingDevices: true}
];

const CheckedButton = props => {
    const {isChecked, isSavable, children, ...rest} = props;

    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Button
            variant={isChecked ? 'contained' : 'outlined'}
            color="primary"
            size={isSmall ? 'small' : 'large'}
            disabled={!isSavable}
            sx={{
                ...isSmall && {
                    flex: 1,
                    mt: 0.5
                }
            }}
            {...rest}
        >
            {children}
        </Button>
    );
};

const calculateExpandedIndex = checks => {
    let nextIndex = -1;

    for (let i = 0; i < checks.length; i++) {
        const {status} = checks[i];
        if (!status) {
            nextIndex = i;
            break;
        }
    }

    return nextIndex;
};

const EditChecks = () => {
    const {id: uid, checkId: checkUid} = useParams();
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [completing, setCompleting] = useState(false);
    const [checkRecord, setCheckRecord] = useState(checkUid && {uid: checkUid});
    const [lastCheckRecord, setLastCheckRecord] = useState(null);
    const [checks, setChecks] = useState([]);
    const [checksIsDirty, setChecksIsDirty] = useState(false);
    const [signatureIsDirty, setSignatureIsDirty] = useState(false);
    const [logDriverTraining, setLogDriverTraining] = useState(false);
    const [lastSave, setLastSave] = useState(null);
    const [apparatus, setApparatus] = useState(null);
    const [hasDefectsOrRepairs, setHasDefectsOrRepairs] = useState(false);
    const finishCheck = httpsCallable(functions, 'finishCheck');
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const navigate = useNavigate();
    const {currentUser} = useContext(UserContext);
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const dateFormatLong = get(currentUser, 'settings.dateFormatLong') || 'LLL';
    const signatureRef = useRef(null);

    const {active} = checkRecord || {};
    const isSavable = checkRecord && active;
    const {hasAirBrakes = false, hasCouplingDevices = false, axles = 1} = apparatus || {};

    let isSubscribed = true;
    let firstLoad = true;

    const methods = useForm({
        defaultValues: {
            users: [],
            odometer: '',
            notes: '',
            pretripType: 'pretrip'
        },
        mode: 'onChange'
    });
    const {watch, formState, clearErrors, getValues, trigger, reset} = methods;
    const {errors, isValid, isDirty} = formState;

    const users = watch('users', []);
    const drivingLog = watch('drivingLog');

    useEffect(() => {
        if (signatureRef.current) {
            if (isSavable) {
                signatureRef.current.on();
            } else {
                signatureRef.current.off();
            }
        }
    }, [isSavable, signatureRef]);

    const handleClearSignature = () => {
        signatureRef.current.clear();
        setSignatureIsDirty(true);
        setLastSave(new Date());
    };

    const handleFetch = apparatus => async checkRecordRaw => {
        try {
            if (checkRecordRaw.exists() === false) {
                if (isSubscribed) {
                    navigate(`/apparatus/${uid}`);
                }

                return;
            }

            let newCheckRecord = processRawDoc(checkRecordRaw);
            newCheckRecord = await populateUsers(db, newCheckRecord);
            if (!newCheckRecord) {
                return;
            }

            const {users = [], loggedChecks = [], signature, ...rest} = newCheckRecord;
            let newChecks = loggedChecks;
            if (loggedChecks.length === 0) {
                newChecks = [...defaultChecks.filter(check => {
                    if (!isNil(check.hasAirBrakes)) {
                        return check.hasAirBrakes === apparatus.hasAirBrakes;
                    }
                    
                    if (check.hasCouplingDevices && !hasCouplingDevices) {
                        return false;
                    }

                    return true;
                })];
            }

            if (isSubscribed) {
                if (newCheckRecord.active === false && checkRecord.active === true) {
                    navigate(`/apparatus/${uid}`);
                    return;
                }

                if (signature) {
                    signatureRef.current.fromDataURL(signature);
                }

                setCheckRecord(newCheckRecord);
                setChecks(newChecks);
                setHasDefectsOrRepairs(newChecks.some(check => check.status === 'DEFECTIVE' || check.status === 'REPAIRED'));

                const [user] = users.filter(Boolean).map(user => user.id).filter(Boolean);

                reset({
                    ...newCheckRecord,
                    users: user,
                    ...rest
                }, {
                    keepErrors: true
                });

                if (firstLoad) {
                    firstLoad = false;
                }
            }
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        if (isSubscribed) {
            setLoading(false);
        }
    };

    useEffect(() => {
        let snapshot;

        const fetchLast = async() => {
            const ref = collection(db, 'apparatus', uid, 'checks');
            const docs = await getCollection(db, query(ref, where('active', '==', false), where('type', '==', 'pretrips'), orderBy('createdAt', 'desc'), limit(1)));

            if (docs.length) {
                setLastCheckRecord(docs[0]);
            }
        };

        const fetch = async() => {
            const newApparatus = await getCollectionDoc(db, 'apparatus', uid);
            setApparatus(newApparatus);

            const ref = doc(db, 'apparatus', uid, 'checks', checkUid);
            snapshot = onSnapshot(ref, handleFetch(newApparatus));
        };

        fetch();
        fetchLast();
        
        return () => {
            snapshot();
            isSubscribed = false;
        };
    }, [enqueueSnackbar, db, uid]);

    const handleChange = (index, type) => {
        return e => {
            if (!isSavable) {
                return;
            }

            if (e) {
                e.stopPropagation();
            }

            const newChecks = checks.map((check, i) => {
                if (i === index) {
                    const {status, ...rest} = check;
                    return {
                        ...rest,
                        status: status === type ? null : type
                    };
                }

                return check;
            });

            setChecksIsDirty(true);
            setChecks(newChecks);

            const newHasDefectsOrRepairs = newChecks.some(check => check.status === 'DEFECTIVE' || check.status === 'REPAIRED');
            setHasDefectsOrRepairs(newHasDefectsOrRepairs);

            if (!newHasDefectsOrRepairs) {
                clearErrors('notes');
            }

            setLastSave(new Date());
        };
    };

    const handleChangeToggle = useCallback(index => {
        const existingStatus = get(checks, [index, 'status']);
        let newStatus = null;
        if (!existingStatus) {
            newStatus = 'CHECKED';
        } else if (existingStatus === 'CHECKED') {
            newStatus = 'DEFECTIVE';
        } else if (existingStatus === 'DEFECTIVE') {
            newStatus = 'REPAIRED';
        }

        handleChange(index, newStatus)();
    }, [checks]);

    const handleSave = async complete => {
        if (!isDirty && !checksIsDirty && !signatureIsDirty) {
            return;
        }

        if (completing) {
            return;
        }

        const data = getValues();

        try {
            setSaving(true);

            let signature = null;
            if (!signatureRef.current.isEmpty()) {
                signature = signatureRef.current.toDataURL();
            }

            const docRef = doc(db, 'apparatus', uid, 'checks', checkRecord.uid);
            const {users = []} = data;
            
            await updateDoc(docRef, ensureJSDates({
                ...data,
                loggedChecks: checks,
                updatedAt: new Date(),
                users: castArray(users).filter(Boolean),
                signature,
                ...(complete ? {
                    active: false
                } : {
                    active: true
                })
            }));

            setChecksIsDirty(false);
            setSignatureIsDirty(false);
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        setSaving(false);
    };

    const handleFinish = useCallback(async () => {
        await trigger();

        await handleSave();

        if (!isValid) {
            const [firstError] = Object.keys(errors);
            let message = 'Please correct any errors before finishing the pretrip';
            
            if (firstError) {
                const field = document.querySelector(`[name="${firstError}"]`);
                if (field) {
                    if (field.scrollIntoViewIfNeeded) {
                        field.scrollIntoViewIfNeeded();
                    }
                    
                    field.focus();
                }
                
                if (firstError === 'users') {
                    message = 'Please select a driver before finishing the pretrip';
                } else if (firstError === 'odometer') {
                    message = 'Please enter the odometer reading before finishing the pretrip';
                } else if (firstError === 'notes') {
                    message = 'Please enter any notes for the defects or repairs before finishing the pretrip';
                } else if (firstError === 'airBrakes') {
                    message = 'Please enter all air brake measurements before finishing the pretrip';
                }
            }
            
            enqueueSnackbar(message, {variant: 'error'});

            return;
        }

        if (signatureRef.current.isEmpty()) {
            enqueueSnackbar('Please sign the pretrip before finishing', {variant: 'error'});
            return;
        }

        const onFinish = async () => {
            setCompleting(true);

            try {
                if (logDriverTraining) {
                    const ref = collection(db, 'driverLogs');
                    const data = {
                        ...drivingLog,
                        createdAt: checkRecord.createdAt,
                        apparatus: uid,
                        user: users
                    };

                    await addDoc(ref, ensureJSDates(data));
                }

                await finishCheck({
                    apparatus: uid,
                    check: checkUid,
                    debugEmail: isDev
                });

                navigate(`/apparatus/${uid}`);
            } catch(e) {
                enqueueSnackbar(e.message, {variant: 'error'});

                setCompleting(false);
            }
        };

        const allCompleted = checks.length && calculateExpandedIndex(checks) === -1;
        if (!allCompleted) {
            enqueueSnackbar('You have incomplete checks - do you still want to finish the pretrip?', {
                variant: 'warning',
                action: key => {
                    return (
                        <>
                            <Button onClick={() => {
                                closeSnackbar(key);
                                onFinish();
                            }}>
                                Finish
                            </Button>
                            <Button onClick={() => closeSnackbar(key)}>
                                Cancel
                            </Button>
                        </>
                    );
                }
            });
        } else {
            onFinish();
        }
    }, [uid, isValid, checkUid, handleSave, errors, finishCheck, enqueueSnackbar, closeSnackbar, navigate, users, checks, hasDefectsOrRepairs]);

    const handleDelete = useCallback(() => {
        const onDelete = async() => {
            try {
                setDeleting(true);
    
                const docRef = doc(db, 'apparatus', uid, 'checks', checkRecord.uid);
                await deleteDoc(docRef);
            } catch(e) {
                enqueueSnackbar(e.message, {variant: 'error'});
            }
    
            setDeleting(false);
    
            navigate(`/apparatus/${uid}`);
        };

        if (active) {
            onDelete();
            return;
        }

        enqueueSnackbar('Are you sure you want to delete this pretrip?', {
            variant: 'warning',
            action: key => {
                return (
                    <>
                        <Button onClick={() => {
                            closeSnackbar(key);
                            onDelete();
                        }}>
                            Delete
                        </Button>
                        <Button onClick={() => closeSnackbar(key)}>
                            Cancel
                        </Button>
                    </>
                );
            }
        });
    }, [db, enqueueSnackbar, navigate, uid, closeSnackbar, checkRecord, active]);

    const handleSignatureEnd = () => {
        setSignatureIsDirty(true);
        setLastSave(new Date());
    };

    useDebouncedEffect(async() => {
        if (lastSave) {
            await handleSave();
        }
    }, 1000, [lastSave]);

    useEffect(() => {
        const {unsubscribe} = watch(() => {
            setLastSave(new Date());
        });
        
        return () => unsubscribe();
    }, [watch]);

    return (
        <FormProvider {...methods}>
            <Box>
                <Box sx={{pb: 2}}>
                    <Typography variant="h5" sx={{flex: 1}}>
                        Pretrip
                    </Typography>
                
                    <Box sx={{display: 'flex'}}>
                        <Box sx={{flex: 1}}>
                            <Typography variant="caption">
                                <strong>Started:</strong> {moment(checkRecord && checkRecord.createdAt ? (checkRecord.createdAt.toDate ? checkRecord.createdAt.toDate() : checkRecord.createdAt) : new Date()).format(dateFormatLong)}
                            </Typography>
                        </Box>
                        {(saving || loading) && (
                            <Box sx={{display: 'flex'}}>
                                {!isSmall && (
                                    <Typography sx={{mr: 1}} variant="caption">{!saving && loading ? 'loading' : 'saving'}</Typography>
                                )}
                                <CircularProgress size={20} />
                            </Box>
                        )}
                    </Box>

                    <Divider sx={{mt: 1}} />
                </Box>
            
                <Grid container spacing={1} rowSpacing={2}>
                    <Grid size={{xs: 12, lg: 5}}>
                        <UserAutocompleteField
                            label="Driver"
                            name="users"
                            multiple={false}
                            limitToStation
                            required
                            disabled={!isSavable}
                            fullWidth
                        />
                    </Grid>
                    <Grid size={{xs: 6, lg: 3}}>
                        <ToggleButtonField
                            sx={{flex: 1}}
                            fullWidth
                            exclusive
                            size="large"
                            variant="contained"
                            color="primary"
                            name="pretripType"
                            options={[
                                {label: 'Pre Trip', value: 'pretrip'},
                                {label: 'Post Trip', value: 'posttrip'}
                            ]}
                        />
                    </Grid>
                    <Grid size={{xs: 6, lg: 4}}>
                        <TextField
                            label="Odometer"
                            name="odometer"
                            type="number"
                            placeholder={lastCheckRecord ? lastCheckRecord.odometer : ''}
                            required
                            rules={{
                                required: 'Please enter the odometer reading',
                                pattern: {
                                    value: /^\d+$/,
                                    message: 'Please enter a valid odometer reading'
                                }
                            }}
                            min={lastCheckRecord ? lastCheckRecord.odometer : 0}
                            disabled={!isSavable}
                            fullWidth
                        />
                    </Grid>
                </Grid>

                <Divider sx={{mt: 1, mb: 2}} />
            
                <Box>
                    {checks.map((check, index) => {
                        const {text, status} = check;
                        const isCompleted = status === 'CHECKED';
                        const isDefective = status === 'DEFECTIVE';
                        const isRepaired = status === 'REPAIRED';

                        let accordionSx = {
                            '& .MuiAccordionSummary-content': {
                                m: 0.5
                            },
                            p: 0,
                            paddingLeft: 0.5,
                            borderRadius: 0,
                            borderLeft: '4px solid transparent'
                        };
                    
                        if (isCompleted) {
                            accordionSx.borderLeftColor = green[400];
                        } else if (isRepaired) {
                            accordionSx.borderLeftColor = orange[400];
                        } else if (isDefective) {
                            accordionSx.borderLeftColor = red[400];
                        }

                        return (
                            <Accordion key={index} onClick={() => handleChangeToggle(index)}>
                                <AccordionSummary component="div" sx={accordionSx}>
                                    <Stack direction="row" sx={{flex: 1, alignItems: 'center'}}>
                                        <Typography
                                            variant="h6"
                                            sx={{
                                                flex: 1,
                                                ...isSmall && {
                                                    fontSize: '1rem'
                                                },
                                                ...status && {
                                                    opacity: 0.7,
                                                    textDecoration: 'line-through'
                                                }
                                            }}
                                        >
                                            {text}
                                        </Typography>

                                        <ButtonGroup>
                                            <CheckedButton
                                                isChecked={isCompleted}
                                                isSavable={isSavable}
                                                onClick={handleChange(index, 'CHECKED')}
                                            >
                                                Checked
                                            </CheckedButton>
                                            <CheckedButton
                                                isChecked={isDefective}
                                                isSavable={isSavable}
                                                onClick={handleChange(index, 'DEFECTIVE')}
                                            >
                                                Defective
                                            </CheckedButton>
                                            <CheckedButton
                                                isChecked={isRepaired}
                                                isSavable={isSavable}
                                                onClick={handleChange(index, 'REPAIRED')}
                                            >
                                                Repaired
                                            </CheckedButton>
                                        </ButtonGroup>
                                    </Stack>
                                </AccordionSummary>
                            </Accordion>
                        );
                    })}
                </Box>

                {hasAirBrakes && (
                    <>
                        <Divider sx={{mt: 2}} />

                        <Typography variant="h5" sx={{mt: 1}}>
                            Air Brakes
                        </Typography>

                        <Grid container spacing={2} sx={{mt: 1}}>
                            <Grid size={4}>
                                <Grid container rowSpacing={2} columnSpacing={1}>
                                    <Grid size={6}>
                                        <InchField
                                            name="airBrakes.lf"
                                            label="LF"
                                            fullWidth
                                            required
                                            helperText={false}
                                            disabled={!isSavable}
                                        />
                                    </Grid>
                                    <Grid size={6}>
                                        <InchField
                                            name="airBrakes.rf"
                                            label="RF"
                                            fullWidth
                                            required
                                            helperText={false}
                                            disabled={!isSavable}
                                        />
                                    </Grid>

                                    <Grid size={6}>
                                        <InchField
                                            name="airBrakes.lr"
                                            label="LR"
                                            fullWidth
                                            required
                                            helperText={false}
                                            disabled={!isSavable}
                                        />
                                    </Grid>
                                    <Grid size={6}>
                                        <InchField
                                            name="airBrakes.rr"
                                            label="RR"
                                            fullWidth
                                            required
                                            helperText={false}
                                            disabled={!isSavable}
                                        />
                                    </Grid>

                                    {axles > 1 && (
                                        <>
                                            <Grid size={6}>
                                                <InchField
                                                    name="airBrakes.lrr"
                                                    label="LRR"
                                                    fullWidth
                                                    helperText={false}
                                                    required
                                                    disabled={!isSavable}
                                                />
                                            </Grid>
                                            <Grid size={6}>
                                                <InchField
                                                    name="airBrakes.rrr"
                                                    label="RRR"
                                                    required
                                                    fullWidth
                                                    helperText={false}
                                                    disabled={!isSavable}
                                                />
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid size={8}>
                                <Stack spacing={2}>
                                    <TextField
                                        name="airBrakes.governorCutIn"
                                        placeholder={lastCheckRecord ? get(lastCheckRecord, 'airBrakes.governorCutIn', '') : ''}
                                        label="Governor Cut In"
                                        fullWidth
                                        required
                                        helperText={false}
                                        disabled={!isSavable}
                                        slotProps={{
                                            input: {
                                                endAdornment: <InputAdornment position="end">psi</InputAdornment>
                                            }
                                        }}
                                    />
                                    <TextField
                                        name="airBrakes.lowAirBuzzer"
                                        placeholder={lastCheckRecord ? get(lastCheckRecord, 'airBrakes.lowAirBuzzer', '') : ''}
                                        label="Low Air Buzzer & Light"
                                        fullWidth
                                        required
                                        helperText={false}
                                        disabled={!isSavable}
                                        slotProps={{
                                            input: {
                                                endAdornment: <InputAdornment position="end">psi</InputAdornment>
                                            }
                                        }}
                                    />
                                    <TextField
                                        name="airBrakes.springBrakes"
                                        placeholder={lastCheckRecord ? get(lastCheckRecord, 'airBrakes.springBrakes', '') : ''}
                                        label="Spring Brakes Apply"
                                        fullWidth
                                        required
                                        helperText={false}
                                        disabled={!isSavable}
                                        slotProps={{
                                            input: {
                                                endAdornment: <InputAdornment position="end">psi</InputAdornment>
                                            }
                                        }}
                                    />
                                    <TextField
                                        name="airBrakes.compressorBuildUp"
                                        placeholder={lastCheckRecord ? get(lastCheckRecord, 'airBrakes.compressorBuildUp', '') : ''}
                                        label="Compressor Build Up Time (50-90 psi)"
                                        fullWidth
                                        required
                                        helperText={false}
                                        disabled={!isSavable}
                                        slotProps={{
                                            input: {
                                                endAdornment: <InputAdornment position="end">minute(s)</InputAdornment>
                                            }
                                        }}
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                    </>
                )}

                <Divider sx={{mt: 2}} />

                <TextField
                    sx={{mt: 2}}
                    label="Notes"
                    name="notes"
                    disabled={!isSavable}
                    rules={{
                        ...hasDefectsOrRepairs && {
                            required: 'Please enter any notes for the defects or repairs'
                        }
                    }}
                    fullWidth
                />

                <Divider sx={{mt: 2}} />

                <Typography variant="h5" sx={{mt: 1}}>
                    Signature
                </Typography>

                <Paper
                    variant="outlined"
                    sx={{
                        position: 'relative',
                        mt: 1,
                        height: 200,
                        '& .signature-canvas': {
                            width: '100%',
                            height: '100%'
                        }
                    }}
                >
                    <SignatureCanvas
                        ref={signatureRef}
                        clearOnResize={false}
                        canvasProps={{className: 'signature-canvas'}}
                        onEnd={handleSignatureEnd}
                    />

                    <Button sx={{position: 'absolute', right: 10, bottom: 10}} onClick={handleClearSignature}>Clear</Button>
                </Paper>

                {active && hasFeature('driverLogs') && (
                    <>
                        <Divider sx={{mt: 2}} />

                        <Stack spacing={2} sx={{mt: 2}} direction="column">
                            <FormControl>
                                <FormControlLabel
                                    labelPlacement="end"
                                    control={
                                        <Checkbox
                                            checked={logDriverTraining}
                                            onChange={e => setLogDriverTraining(e.target.checked)}
                                        />
                                    }
                                    label="Log Driver Training?"
                                />
                            </FormControl>

                            {logDriverTraining && (
                                <Stack spacing={2} direction={{xs: 'column', sm: 'row'}}>
                                    <UserAutocompleteField
                                        name="drivingLog.supervisor"
                                        label="Right Seat"
                                        fullWidth
                                        required
                                        allowedRanks={[
                                            ...OfficerRanks,
                                            'TEAM_LEADER'
                                        ]}
                                        disabled={loading}
                                        multiple={false}
                                    />
                                    <SelectField
                                        name="drivingLog.code"
                                        label="Code"
                                        fullWidth
                                        required
                                        disabled={loading}
                                        options={Object.keys(DrivingCodes).map(key => ({value: key, label: DrivingCodes[key]}))}
                                    />
                                    <NumberField
                                        name="drivingLog.hours"
                                        label="Hours"
                                        fullWidth
                                        required
                                        disabled={loading}
                                    />
                                </Stack>
                            )}
                        </Stack>
                    </>
                )}
            
                {isSavable && (
                    <Button
                        variant="contained"
                        sx={{mt: 2}}
                        onClick={handleFinish}
                        loading={completing || saving}
                        disabled={deleting || completing || saving}
                        size="large"
                        fullWidth
                    >
                        Finish Pretrip
                    </Button>
                )}

                {(hasPermission(currentUser, 'apparatus.write') || active) && (
                    <Button
                        variant="outlined"
                        sx={{mt: 1}}
                        onClick={handleDelete}
                        loading={deleting}
                        disabled={deleting || completing}
                        fullWidth
                    >
                        {active ? 'Cancel' : 'Delete'} Pretrip
                    </Button>
                )}
            </Box>
        </FormProvider>
    );
};

export default EditChecks;