import React, {useState, useEffect, useCallback, useContext} from 'react';
import {Typography, Button, Divider, Box, Tabs, Tab} from '@mui/material';
import {useNavigate, useParams} from 'react-router-dom';
import {useForm, FormProvider} from 'react-hook-form';
import {doc, collection, setDoc, deleteDoc, updateDoc} from 'firebase/firestore';
import {useSnackbar} from 'notistack';
import {useConfirm} from 'material-ui-confirm';
import moment from 'moment';
import {sortBy, isNil} from 'lodash';

import {SettingsContext} from '-/contexts/Settings';

import {db} from '-/firebase';
import {ensureJSDates, getCollectionDoc, uploadFile} from '-/data/utils';

import useDocumentTitle from '-/hooks/useDocumentTitle';

import MemberHours from '-/components/members/Hours';

import Details from '-/pages/incident/Details';
import Additional from '-/pages/incident/Additional';
import Responses from '-/pages/incident/Responses';

import FileField from '-/form/FileField';

export default function Incident() {
    const {id: uid} = useParams();
    const isNew = !uid;
    const [loading, setLoading] = useState(!!uid);
    const [deleting, setDeleting] = useState(false);
    const [activeTab, setActiveTab] = useState('details');
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const confirm = useConfirm();
    const {incidentMinimumHours = 0, incidentFieldCategories = []} = useContext(SettingsContext);

    const methods = useForm({
        defaultValues: {
            date: moment(),
            type: '',
            additionalFields: {}
        },
        mode: 'onChange'
    });
    const {handleSubmit, formState, reset, watch, setValue} = methods;
    const {isDirty, dirtyFields} = formState;

    const incidentNumber = watch('incidentNumber');
    const date = watch('date');
    const dispatchTime = watch('dispatchTime');
    const arrivalTime = watch('arrivalTime');
    const sceneCleared = watch('sceneCleared');
    const users = watch('users') || [];
    const durations = watch('durations');
    const type = watch('type');
    
    const matchedCategories = incidentFieldCategories.filter(category => !category.types.length || category.types.includes(type));

    const {salary, salaryOverrides} = durations || {};

    const handleHourChange = useCallback((e, member) => {
        const newSalaryOverrides = salaryOverrides || {};
        const existingValue = newSalaryOverrides[member.id];
        const rawValue = e.target.value;
        let newValue = rawValue === '' ? undefined : parseFloat(rawValue);

        if (isNil(newValue)) {
            delete newSalaryOverrides[member.id];
        } else if (isNil(existingValue)) {
            newSalaryOverrides[member.id] += parseFloat(salary);
        } else {
            newSalaryOverrides[member.id] = newValue;
        }

        setValue('durations.salaryOverrides', newSalaryOverrides, {shouldDirty: true});
    }, [salary, salaryOverrides]);

    useEffect(() => {
        if (date && typeof dispatchTime === 'string') {
            try {
                const [hour, minute] = dispatchTime.split(':');

                const newDispatchTime = moment(date).set({
                    hour,
                    minute,
                    second: 0
                });

                setValue('dispatchTime', newDispatchTime, {shouldDirty: true});
            } catch(e) {
                //
            }
        }

        if (date && typeof arrivalTime === 'string') {
            try {
                const [hour, minute] = arrivalTime.split(':');

                let newArrivalTime = moment(date).set({
                    hour,
                    minute,
                    second: 0
                });

                if (newArrivalTime.isBefore(date)) {
                    newArrivalTime.add(1, 'day');
                }

                setValue('arrivalTime', newArrivalTime, {shouldDirty: true});
            } catch(e) {
                //
            }
        }

        if (date && typeof sceneCleared === 'string') {
            try {
                const [hour, minute] = sceneCleared.split(':');

                let newSceneCleared = moment(date).set({
                    hour,
                    minute,
                    second: 0
                });

                if (newSceneCleared.isBefore(date)) {
                    newSceneCleared.add(1, 'day');
                }

                setValue('sceneCleared', newSceneCleared, {shouldDirty: true});
            } catch(e) {
                //
            }
        }
    }, [dispatchTime, arrivalTime, sceneCleared, date]);

    useEffect(() => {
        if (date && typeof dispatchTime === 'object') {
            const newDateWithTime = moment(date).set({
                hour: dispatchTime.hours(),
                minute: dispatchTime.minutes(),
                second: 0
            });

            const isSame = newDateWithTime.isSame(date);
            if (!isSame) {
                setValue('date', newDateWithTime, {shouldDirty: true});
            }
        }
    }, [date, dispatchTime]);

    useEffect(() => {
        const values = [
            date,
            dispatchTime,
            arrivalTime,
            sceneCleared
        ];

        for (const value of values) {
            if (!value || typeof value !== 'object' || !value.isValid || !value.isValid()) {
                return;
            }
        }

        let newDispatchTime = moment(dispatchTime).clone().year(date.year()).month(date.month()).date(date.date());
        let newArrivalTime = moment(arrivalTime).clone().year(date.year()).month(date.month()).date(date.date());
        let newSceneCleared = moment(sceneCleared).clone().year(date.year()).month(date.month()).date(date.date());

        if (!dispatchTime.isSame(newDispatchTime)) {
            setValue('dispatchTime', newDispatchTime, {shouldDirty: true});
        }

        if (newArrivalTime.isBefore(date)) {
            newArrivalTime.add(1, 'day');
        }

        if (!arrivalTime.isSame(newArrivalTime)) {
            setValue('arrivalTime', newArrivalTime, {shouldDirty: true});
        }

        if (newSceneCleared.isBefore(date)) {
            newSceneCleared.add(1, 'day');
        }

        if (!sceneCleared.isSame(newSceneCleared)) {
            setValue('sceneCleared', newSceneCleared, {shouldDirty: true});
        }

        const {salary} = durations || {};
        const newDuration = moment.duration(newSceneCleared.diff(newDispatchTime));
        let newSalary = newDuration.asHours();
        newSalary = Math.max(Math.ceil(newSalary * 4) / 4, incidentMinimumHours);

        if (salary !== newSalary) {
            setValue('durations.salary', newSalary, {shouldDirty: true});
        }
    }, [date, dispatchTime, arrivalTime, sceneCleared, durations, incidentMinimumHours]);

    useDocumentTitle(incidentNumber ? incidentNumber : 'Incident');

    const onSubmit = useCallback(async data => {
        const {files: rawFiles = []} = data;
        const dirtyKeys = Object.keys(dirtyFields);
        if (!dirtyKeys.length) {
            return;
        }

        setLoading(true);

        const newData = dirtyKeys.reduce((acc, key) => {
            if (key === 'files') {
                return acc;
            }

            acc[key] = data[key];

            return acc;
        }, {});

        try {
            let toUpdate = {
                updated: new Date(),
                ...newData
            };

            const {files, fileUploads} = rawFiles.reduce((result, file) => {
                if (file.file) {
                    result.fileUploads.push(file);
                } else {
                    result.files.push(file);
                }
    
                return result;
            }, {
                files: [],
                fileUploads: []
            });
    
            toUpdate.files = files;
            toUpdate = ensureJSDates(toUpdate);
            
            let id = uid;
            if (isNew) {
                const ref = collection(db, 'incidents');
                const docRef = doc(ref);
                ({id} = docRef);
                
                await setDoc(docRef, toUpdate);

            } else {
                const ref = doc(db, 'incidents', uid);
                await updateDoc(ref, toUpdate);
            }

            for (const file of fileUploads) {
                if (!file.file) {
                    continue;
                }
                
                const path = `incidents/${id}`;
                await uploadFile(path, file);
            }

            reset(data);

            enqueueSnackbar(isNew ? 'Incident saved' : 'Changed saved', {variant: 'success'});

            navigate('/incidents');
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        setLoading(false);
    }, [dirtyFields, db, uid, enqueueSnackbar, reset, navigate, isNew]);

    const handleDelete = useCallback(async() => {
        const onDelete = async() => {
            try {
                setDeleting(true);
    
                const ref = doc(db, 'incidents', uid);
                await deleteDoc(ref);

                navigate('/incidents');
            } catch(e) {
                enqueueSnackbar(e.message, {variant: 'error'});
            }
    
            setDeleting(false);
        };

        const {confirmed} = await confirm({
            description: 'Are you sure you want to delete this incident?',
            confirmationText: 'Delete Incident'
        });

        if (confirmed) {
            onDelete();
        }
    }, [confirm, enqueueSnackbar]);

    useEffect(() => {
        let isSubscribed = true;

        const fetch = async() => {
            if (uid) {
                const incident = await getCollectionDoc(db, 'incidents', uid);
                if (!incident) {
                    navigate(-1);
                    return;
                }
                
                if (isSubscribed) {
                    reset(incident);

                    setLoading(false);
                }
            }
        };

        fetch();

        return () => isSubscribed = false;
    }, [db, reset, uid, navigate]);

    const sortedMembers = sortBy(users, ['station', 'lastName']);

    return (
        <FormProvider {...methods}>
            <Typography variant="h5" gutterBottom>{isNew ? 'Add' : 'Edit'} Incident</Typography>

            <Box sx={{borderBottom: 1, borderColor: 'divider', mb: 2}}>
                <Tabs value={activeTab} onChange={(e, tab) => setActiveTab(tab)}>
                    <Tab label="Details" value="details" />
                    {matchedCategories.length > 0 && <Tab label="Additional Information" value="additional" />}
                    <Tab label="Files" value="files" />
                    <Tab label="Responses" value="responses" />
                    {sortedMembers.length > 0 && <Tab label="Hours" value="hours" />}
                </Tabs>
            </Box>

            <Details sx={{display: activeTab === 'details' ? 'block' : 'none'}} />
            <Additional sx={{display: activeTab === 'additional' ? 'block' : 'none'}} />

            <Box sx={{display: activeTab === 'files' ? 'block' : 'none'}}>
                <FileField name="files" disabled={loading} />
            </Box>

            <Responses sx={{display: activeTab === 'responses' ? 'block' : 'none'}} />

            <Box sx={{display: activeTab === 'hours' ? 'block' : 'none'}}>
                <MemberHours
                    members={sortedMembers}
                    defaultSalary={salary}
                    salaryOverrides={salaryOverrides}
                    handleHourChange={handleHourChange}
                />
            </Box>

            <Divider sx={{mt: 2}} />

            <Box sx={{display: 'flex', justifyContent: 'flex-end', flexDirection: 'row', mt: 2, mb: 2}}>
                {!isNew && (
                    <Button onClick={handleDelete} sx={{mr: 1}} loading={deleting}>
                        Delete Incident
                    </Button>
                )}
                
                <Button
                    type="submit"
                    variant="contained"
                    onClick={handleSubmit(onSubmit)}
                    disabled={!isDirty || loading}
                    loading={loading}
                >
                    Save Incident
                </Button>
            </Box>
        </FormProvider>
    );
}