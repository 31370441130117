import React, {useState, useEffect, useContext} from 'react';
import {Grid2 as Grid, Menu, IconButton, MenuItem, Tooltip, ListItemText, Stack, AvatarGroup, Skeleton, Box, Backdrop, CircularProgress, Button, Divider, Typography, Card, CardMedia, useMediaQuery} from '@mui/material';
import {doc, getDoc, collection, query, limit, orderBy, where, addDoc, onSnapshot} from 'firebase/firestore';
import {useParams, useNavigate} from 'react-router-dom';
import {useFormContext} from 'react-hook-form';
import HideImageIcon from '@mui/icons-material/HideImage';
import {ref, getDownloadURL} from 'firebase/storage';
import {DataGrid} from '@mui/x-data-grid';
import moment from 'moment';
import {get, capitalize, startCase, snakeCase} from 'lodash';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {useSnackbar} from 'notistack';
import {useTheme, darken} from '@mui/material/styles';
import ChecklistIcon from '@mui/icons-material/Checklist';
import EditIcon from '@mui/icons-material/Edit';
import FireTruckIcon from '@mui/icons-material/FireTruck';

import {UserContext} from '-/contexts/User';
import {db, storage} from '-/firebase';

import {processRawDocs, populateUsers, getCollection, hasPermission, ensureMomentDates} from '-/data/utils';

import useDocumentTitle from '-/hooks/useDocumentTitle';

import ArchivedAlert from '-/components/ArchivedAlert';
import UserAvatar from '-/components/UserAvatar';
import TextField from '-/form/TextField';
import SelectField from '-/form/SelectField';

import Prompt from '-/dialogs/Prompt';

const AddCheckPrompt = () => {
    const {watch} = useFormContext();
    const {type} = watch();

    return (
        <Stack spacing={2}>
            <Typography variant="body1">Please select the type of check you would like to add:</Typography>

            <SelectField
                name="type"
                label="Type"
                options={[
                    {label: 'Weekly', value: 'weekly'},
                    {label: 'Full', value: 'full'},
                    {label: 'Maintenance', value: 'maintenance'},
                    {label: 'Other', value: 'other'}
                ]}
                required
            />

            {type === 'other' && (
                <TextField
                    name="otherType"
                    label="Type"
                    required
                />
            )}
        </Stack>
    );
};

const History = ({loading = true, rows = []}) => {
    const {id: uid} = useParams();

    const navigate = useNavigate();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const {currentUser} = useContext(UserContext);
    const dateFormatLong = get(currentUser, 'settings.dateFormatLong') || 'LLL';

    const columns = [
        {
            field: 'type',
            headerName: 'Type',
            width: 130,
            valueFormatter: (value, row) => {
                if (value === 'pretrips') {
                    const {pretripType} = row || {};
                    return pretripType ? capitalize(pretripType) : 'Pretrip';
                }

                return `${startCase(value)} Check`;
            }
        },
        {
            field: 'updatedAt',
            headerName: 'Completed',
            sortable: true,
            flex: 1,
            valueFormatter: value => {
                if (!value) {
                    return '-';
                }

                value = value.toDate ? value.toDate() : value;
                
                return moment(value).format(isSmall ? 'MMM D, HH:mm' : dateFormatLong);
            }
        },
        {
            field: 'users',
            headerName: 'Members',
            renderCell: params => {
                const {row} = params;
                const {users = []} = row || {};

                if (!users || !users.length) {
                    return '-';
                }

                return (
                    <Box sx={{display: 'flex', height: '100%', alignItems: 'center'}}>
                        <AvatarGroup>
                            {users.map(user => {
                                const {uid} = user || {};

                                return (
                                    <UserAvatar key={`avatar-${uid}`} user={user} />
                                );
                            })}
                        </AvatarGroup>
                    </Box>
                );
            },
            flex: 1
        },
        {
            field: 'notes',
            headerName: 'Notes',
            width: isSmall ? 60 : 90,
            sortable: false,
            renderCell: params => {
                const {row} = params;
                const {notes, loggedChecks = []} = row;
                const hasNote = !!notes || loggedChecks.find(check => !!check.note);
                if (!hasNote) {
                    return;
                }

                return (
                    <Box sx={{display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
                        <Tooltip title={notes || 'Has notes'}>
                            <WarningAmberIcon />
                        </Tooltip>
                    </Box>
                );
            }
        }
    ];

    return (
        <>
            <Divider sx={{my: 2}} />

            <Typography variant="h6" gutterBottom>Recent History</Typography>

            <Box sx={{display: 'flex'}}>
                <DataGrid
                    localeText={{
                        noRowsLabel: 'No recent history'
                    }}
                    hideFooter
                    loading={loading}
                    autoHeight
                    rowHeight={60}
                    rows={rows}
                    columns={columns}
                    disableRowSelectionOnClick
                    disableColumnFilter
                    disableColumnSelector
                    disableColumnMenu
                    onRowClick={params => {
                        const {row} = params;
                        const {uid: checkUid, type} = row;

                        navigate(`/apparatus/${uid}/${type}/${checkUid}`);
                    }}
                />
            </Box>
        </>
    );
};

const EditChecks = props => {
    const {checks} = props;
    const {id: uid} = useParams();
    const navigate = useNavigate();
    const {currentUser} = useContext(UserContext);
    const [prompOpen, setPromptOpen] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleOpen = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const canEdit = hasPermission(currentUser, 'apparatus.write');
    const canEditChecks = hasPermission(currentUser, 'editChecks.write');

    if (!canEdit || !canEditChecks) {
        return null;
    }

    const handleAddTruckCheck = async data => {
        const {type, otherType} = data;
        let newType = type;
        if (type === 'other') {
            newType = otherType;
        }

        newType = snakeCase(newType.toLowerCase());

        handlePromptClose();

        navigate(`/apparatus/${uid}/${newType}/edit`);
    };

    const handlePromptClose = () => {
        setPromptOpen(false);
    };

    return (
        <Stack direction="row" spacing={1}>
            <IconButton  onClick={handleOpen} data-testid="edit-menu-button">
                <EditIcon />
            </IconButton>

            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {canEdit && (
                    <MenuItem onClick={() => navigate(`/apparatus/${uid}/edit`)}>
                        <ListItemText>Edit Apparatus</ListItemText>
                    </MenuItem>
                )}

                {canEdit && canEditChecks && checks && Object.keys(checks).length > 0 && <Divider />}

                {canEditChecks && (
                    <>
                        {checks && Object.entries(checks).map(([check, id]) => {
                            return (
                                <MenuItem key={id} onClick={() => navigate(`/apparatus/${uid}/${check}/edit`)}>
                                    <ListItemText>Edit {startCase(check)} Check</ListItemText>
                                </MenuItem>
                            );
                        })}

                        <Divider />

                        <MenuItem onClick={() => setPromptOpen(true)}>
                            <ListItemText>Add New Check</ListItemText>
                        </MenuItem>
                    </>
                )}
            </Menu>

            <Prompt
                open={prompOpen}
                handleClose={handlePromptClose}
                title="Add New Check"
                submitText="Add Check"
                onSubmit={handleAddTruckCheck}
                prompt={<AddCheckPrompt />}
            />
        </Stack>
    );
};

const Check = ({type = 'weekly'}) => {
    const {id: uid} = useParams();
    const [activeCheck, setActiveCheck] = useState(null);

    const navigate = useNavigate();
    const {currentUser} = useContext(UserContext);
    const {isStation, uid: currentUserUid} = currentUser;
    const {enqueueSnackbar} = useSnackbar();
    
    let isSubscribed = true;

    let label = `${startCase(type)} Check`;
    if (type === 'pretrips') {
        label = 'Pretrip';
    }

    const handleFetch = async raw => {
        try {
            let docs = processRawDocs(raw);
            docs = await populateUsers(db, docs);

            if (isSubscribed) {
                const [activeCheck] = docs;
                setActiveCheck(activeCheck);
            }
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }
    };

    useEffect(() => {
        const ref = collection(db, 'apparatus', uid, 'checks');
        const q = query(ref, where('type', '==', type), where('active', '==', true), limit(1));
        const snapshot = onSnapshot(q, handleFetch);
        
        return () => {
            snapshot();
            isSubscribed = false;
        };
    }, [enqueueSnackbar, db, uid]);

    const handleStartCheck = async() => {
        let checkId;

        if (activeCheck) {
            checkId = activeCheck.uid;
        } else {
            try {
                let data = {
                    type,
                    createdAt: new Date(),
                    active: true,
                    users: !isStation && currentUserUid ? [currentUserUid] : []
                };

                if (type !== 'pretrips') {
                    const ref = collection(db, 'checks');
                    const q = query(ref, where('apparatus', '==', uid), where('type', '==', type), orderBy('createdAt', 'desc'), limit(1));
                    const docs = await getCollection(db, q);

                    const [record] = docs || [];
                    if (!record) {
                        enqueueSnackbar('Check not found. Please contact the administrator.', {variant: 'error'});
                        return;
                    }

                    const {sections = [], checks = []} = record;

                    data.loggedChecks = sections.length ? sections : [{checks}];
                }
            
                const checkRef = collection(db, 'apparatus', uid, 'checks');
                const checkRecordRaw = await addDoc(checkRef, data);

                checkId = checkRecordRaw.id;
            } catch(e) {
                enqueueSnackbar(e.message, {variant: 'error'});
            }
        }

        if (checkId) {
            navigate(`/apparatus/${uid}/${type}/${checkId}`);
        }
    };

    return (
        <Button
            variant={activeCheck ? 'contained' : 'outlined'}
            color={activeCheck ? 'warning' : 'normal'}
            onClick={() => handleStartCheck('weekly')}
            startIcon={type === 'pretrips' ? <FireTruckIcon /> : <ChecklistIcon />}
            sx={{width: {xs: '100%', sm: 'auto'}}}
        >
            {activeCheck ? 'Continue' : 'Start'} {label}
        </Button>
    );
};

export default function Apparatus() {
    const {id: uid} = useParams();
    const isNew = !uid;

    const [loading, setLoading] = useState(!isNew);
    const [record, setRecord] = useState(null);
    const [checks, setChecks] = useState([]);
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const {currentUser} = useContext(UserContext);
    const dateFormatLong = get(currentUser, 'settings.dateFormatLong') || 'LLL';

    useEffect(() => {
        let isSubscribed = true;

        const fetch = async() => {
            if (uid) {
                const ref = doc(db, 'apparatus', uid);
                const raw = await getDoc(ref);
                
                if (isSubscribed) {
                    if (!raw.exists()) {
                        navigate('/apparatus');
                    }

                    setRecord(ensureMomentDates({
                        id: uid,
                        uid,
                        ...raw.data()
                    }));

                    setLoading(false);
                }
            }
        };

        fetch();

        return () => isSubscribed = false;
    }, [db, uid, navigate]);

    useDocumentTitle(record ? record.tag : 'Apparatus');

    const {tag, color, image, pumpCapacity, tankCapacity, checks: checkTypes, archived, archivedAt, archivedBy} = record || {};
    const [imageUrl, setImageUrl] = useState(null);

    let isSubscribed = true;

    const handleFetch = async raw => {
        try {
            let docs = processRawDocs(raw);
            docs = await populateUsers(db, docs);

            if (isSubscribed) {
                const inactiveChecks = docs.filter(doc => !doc.active);
                setChecks(inactiveChecks);
            }
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        if (isSubscribed) {
            setLoading(false);
        }
    };

    useEffect(() => {
        const ref = collection(db, 'apparatus', uid, 'checks');
        const q = query(ref, orderBy('createdAt', 'desc'), limit(10));
        const snapshot = onSnapshot(q, handleFetch);
        
        return () => {
            snapshot();
            isSubscribed = false;
        };
    }, [enqueueSnackbar, db, uid]);

    useEffect(() => {
        const {filePath, thumbnailPath} = image || {};

        let isSubscribed = true;

        const fetch = async() => {
            try {
                if (thumbnailPath) {
                    const url = await getDownloadURL(ref(storage, thumbnailPath));
                    if (isSubscribed) {
                        setImageUrl(url);
                    }

                    return;
                }

                if (filePath) {
                    const url = await getDownloadURL(ref(storage, filePath));
                    if (isSubscribed) {
                        setImageUrl(url);
                    }

                    return;
                }
            } catch(e) {
                console.warn(e);
            }
        };

        fetch();

        return () => isSubscribed = false;
    }, [image]);

    if (loading) {
        return (
            <Backdrop invisible open>
                <CircularProgress color="primary" />
            </Backdrop>
        );
    }

    const availableChecks = [
        'pretrips',
        ...Object.keys(checkTypes || {})
    ];

    let borderColor = color;
    if (borderColor) {
        try {
            borderColor = darken(color, 0.2);
        } catch(e) {
            console.warn(e);
        }
    }

    const displayedFields = [];

    if (pumpCapacity) {
        displayedFields.push({label: 'Pump Capacity', value: pumpCapacity});
    }

    if (tankCapacity) {
        displayedFields.push({label: 'Tank Capacity', value: tankCapacity});
    }

    if (archived) {
        displayedFields.push({label: 'Archived', value: archivedAt ? moment(archivedAt).format(dateFormatLong) : 'Yes'});
    }

    return (
        <Box sx={{display: 'flex', flexDirection: 'column'}}>
            <Stack direction="row" alignItems="center" spacing={1}>
                {color && (
                    <Tooltip title="Apparatus Color">
                        <Box
                            sx={{
                                mr: 1,
                                width: 30,
                                borderRadius: 1,
                                aspectRatio: 1,
                                background: color,
                                border: '1px solid',
                                borderColor
                            }}
                            variant="filled"
                        />
                    </Tooltip>
                )}

                <Typography variant="h5" sx={{flex: 1}}>{tag ? tag : <Skeleton width={180} />}</Typography>

                <EditChecks checks={checkTypes} />
            </Stack>

            <Divider sx={{mt: 1, mb: 2}} />

            <ArchivedAlert label="apparatus" {...{archived, archivedAt, archivedBy}} />

            <Grid container spacing={1}>
                <Grid size={{xs: 12, sm: 4}}>
                    <Card sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <CardMedia
                            sx={{aspectRatio: '16/9', width: '100%', borderRadius: 0.5, backgroundColor: 'background.paper'}}
                            image={imageUrl}
                            title={tag}
                        >
                            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', color: 'text.secondary', opacity: 0.5}}>
                                {!image && (
                                    <HideImageIcon sx={{color: 'grey.500', fontSize: 40}} />
                                )}
                            </Box>
                        </CardMedia>
                    </Card>
                </Grid>
                
                <Grid size={{xs: 12, sm: 8}}>
                    <Stack spacing={1}>
                        {displayedFields.length > 0 && (
                            <Card sx={{p: 1}} variant="outlined">
                                <Stack spacing={1}>
                                    <Typography variant="body1" sx={{color: 'text.secondary'}}>Details</Typography>
                                    <Divider />
                                    {displayedFields.map(({label, value}) => (
                                        <Typography key={label} variant="caption">{label}: <strong>{value}</strong></Typography>
                                    ))}
                                </Stack>
                            </Card>
                        )}

                        {!archived && (
                            <Stack
                                direction={{xs: 'column', md: 'row'}}
                                alignItems={{xs: 'stretch', md: 'flex-end'}}
                                justifyContent="flex-end"
                                spacing={1}
                                useFlexGap
                                sx={{flexWrap: 'wrap'}}
                            >
                                {availableChecks.map(type => (
                                    <Check key={type} type={type} />
                                ))}
                            </Stack>
                        )}
                    </Stack>
                </Grid>
            </Grid>

            <History loading={loading} rows={checks} />
        </Box>
    );
}