import React, {useState} from 'react';
import {Paper, Box, Chip, List, ListItem, Divider, DialogTitle, DialogContent, DialogActions, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Button, IconButton, FormControlLabel, Stack, Typography} from '@mui/material';
import {useFormContext, useFieldArray} from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {IncidentTypes} from '@embertracking/common';
import {useSnackbar} from 'notistack';

import TextField from '-/form/TextField';
import SelectField from '-/form/SelectField';
import AutocompleteField from '-/form/AutocompleteField';
import CheckboxField from '-/form/CheckboxField';
import NumberField from '-/form/NumberField';

import ResponsiveDialog from '-/components/ResponsiveDialog';

import {FormRow} from '-/pages/Settings';

const Field = ({loading, path, remove, index}) => {
    const {watch} = useFormContext();

    const type = watch(`${path}.fields[${index}].type`);
    const showOptions = type === 'select';
    const showMultiline = type === 'text';
    const showMultiple = type === 'user';

    return (
        <TableRow key={`field${index}`} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
            <TableCell sx={{px: 1, pr: 0, py: 1, verticalAlign: 'top'}}>
                <TextField
                    sx={{width: '100%'}}
                    name={`${path}.fields[${index}].label`}
                    label={false}
                    placeholder="Label"
                    size="small"
                    required
                    disabled={loading}
                />
            </TableCell>
            <TableCell sx={{px: 1, py: 1, verticalAlign: 'top'}}>
                <SelectField
                    sx={{width: '100%'}}
                    name={`${path}.fields[${index}].type`}
                    label={false}
                    size="small"
                    disabled={loading}
                    options={[
                        {value: 'text', label: 'Text'},
                        {value: 'phone', label: 'Phone'},
                        {value: 'select', label: 'Select'},
                        {value: 'checkbox', label: 'Checkbox'},
                        {value: 'date', label: 'Date'},
                        {value: 'user', label: 'User'},
                        {value: 'location', label: 'Location'}
                    ]}
                />
            </TableCell>
            <TableCell sx={{px: 0, py: 1, verticalAlign: 'top'}}>
                {showOptions && (
                    <AutocompleteField
                        sx={{flex: 1}}
                        size="small"
                        name={`${path}.fields[${index}].options`}
                        label={false}
                        placeholder="Enter options"
                        disabled={loading}
                        required
                        objectValue={false}
                        multiple
                        freeSolo
                    />
                )}
                {showMultiline && (
                    <FormControlLabel
                        control={
                            <CheckboxField
                                name={`${path}.fields[${index}].multiline`}
                                disabled={loading}
                            />
                        }
                        label="Multiline"
                    />
                )}
                {showMultiple && (
                    <FormControlLabel
                        control={
                            <CheckboxField
                                name={`${path}.fields[${index}].multiple`}
                                disabled={loading}
                            />
                        }
                        label="Allow Multiple"
                    />
                )}
            </TableCell>
            <TableCell sx={{pl: 1, pr: 0, py: 1, verticalAlign: 'top'}}>
                <IconButton disabled={loading} onClick={() => remove(index)}><DeleteIcon /></IconButton>
            </TableCell>
        </TableRow>
    );
};

const FieldCategory = ({loading, index}) => {
    const path = `incidentFieldCategories[${index}]`;
    const {fields, append: appendField, remove: removeField} = useFieldArray({
        name: `${path}.fields`
    });

    const typeOptions = Object.keys(IncidentTypes).map(key => {
        const label = IncidentTypes[key];
        return {value: key, label};
    });

    const handleAdd = () => {
        appendField({
            name: '',
            type: 'text',
            options: []
        });
    };

    return (
        <Stack direction="column" spacing={2}>
            <TextField
                fullWidth
                name={`${path}.name`}
                label="Grouping Name"
                disabled={loading}
                helperText="Enter a name here to group related fields together."
            />

            <AutocompleteField
                disabled={loading}
                fullWidth
                name={`${path}.types`}
                label="Incident Types"
                placeholder="Leave blank to show for all incident types"
                options={typeOptions}
                objectValue={false}
                multiple
                helperText="Choose the incident types for which this category will appear. Leave blank to display it for all incident types."
            />

            <TableContainer component={Paper} variant="outlined">
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{px: 1, width: 180}}>Label</TableCell>
                            <TableCell sx={{px: 1, width: 180}}>Field Type</TableCell>
                            <TableCell sx={{px: 1, flex: 1}}></TableCell>
                            <TableCell sx={{px: 1, width: 60}}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {fields.length === 0 && (
                            <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                <TableCell colSpan={4} align="center">
                                    No fields
                                </TableCell>
                            </TableRow>
                        )}
                        {fields.map((field, fieldIndex) => (
                            <Field key={field.id} {...{path, loading, index: fieldIndex, remove: removeField}} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Stack direction="row" justifyContent="flex-end">
                <Button disabled={loading} startIcon={<AddIcon />} onClick={handleAdd}>Add Field</Button>
            </Stack>
        </Stack>
    );
};

const EditCategoryDialog = ({loading, open, onClose, remove, index, onSubmit, onRemove}) => {
    if (index === false) {
        return;
    }
    
    return (
        <ResponsiveDialog open={open} onClose={onClose}>
            <DialogTitle>
                Additional Fields
            </DialogTitle>
            <DialogContent dividers>
                <FieldCategory {...{loading, remove, index}} />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onRemove(index)}>Delete</Button>
                <Box sx={{flex: 1}} />

                <Stack direction="row" justifyContent="flex-end" spacing={1}>
                    <Button onClick={() => onClose(index)}>Cancel</Button>
                    <Button variant="contained" onClick={() => onSubmit(index)}>Close</Button>
                </Stack>
            </DialogActions>
        </ResponsiveDialog>
    );
};

const DisplayCategory = ({index, onEdit}) => {
    if (index === false) {
        return;
    }

    const path = `incidentFieldCategories[${index}]`;
    
    const {watch} = useFormContext();
    const name = watch(`${path}.name`);
    const fields = watch(`${path}.fields`, []);
    const types = watch(`${path}.types`, []);

    return (
        <Stack component={Paper} sx={{p: 1}} spacing={1}>
            <Stack direction="row" justifyContent="space-between">
                <Stack direction="column">
                    {name && (
                        <Typography variant="subtitle2">{name}</Typography>
                    )}
                    {fields.length > 0 && (
                        <List sx={{listStyleType: 'disc', py: 0}}>
                            {fields.map((field, index) => (
                                <ListItem key={`field${index}`} sx={{display: 'list-item', p: 0, listStylePosition: 'inside'}}>{field.label}</ListItem>
                            ))}
                        </List>
                    )}
                </Stack>
                <Button
                    size="small"
                    onClick={() => onEdit(index)}
                    startIcon={<EditIcon />}
                >
                    Edit Group
                </Button>
            </Stack>
            <Divider />
            <Stack direction="row" spacing={0.5} flexWrap="wrap" useFlexGap>
                <Typography variant="caption" sx={{color: 'text.secondary'}}>Incident Type(s):</Typography>
                {types.length ? types.map((type, index) => (
                    <Chip key={index} size="small" label={IncidentTypes[type]} />
                )) : (
                    <Chip size="small" label="All" />
                )}
            </Stack>
        </Stack>
    );
};

export default function Incidents(props) {
    const {loading, ...rest} = props;

    const {enqueueSnackbar} = useSnackbar();
    const [open, setOpen] = useState(false);

    const {getValues} = useFormContext();
    const {fields: categories, append, remove} = useFieldArray({
        name: 'incidentFieldCategories'
    });

    const handleAdd = () => {
        append({
            name: '',
            types: [],
            fields: []
        });

        setOpen(categories.length);
    };

    const handleEdit = index => {
        setOpen(index);
    };

    const handleSubmit = index => {
        const fields = getValues(`incidentFieldCategories[${index}].fields`);
        if (!fields || fields.length === 0) {
            enqueueSnackbar('At least one field is required', {variant: 'error'});

            return;
        }

        setOpen(false);
    };

    const handleClose = () => {
        setOpen(false);

        categories.forEach((category, index) => {
            const {fields = []} = category;

            if (fields.length === 0) {
                remove(index);
            }
        });
    };

    const handleRemove = index => {
        remove(index);
        setOpen(false);
    };

    return (
        <Stack spacing={1} {...rest}>
            <EditCategoryDialog open={open !== false} onClose={handleClose} onSubmit={handleSubmit} onRemove={handleRemove} index={open} />

            <FormRow label="Minimum Hours" helperText="The minumum number of hours that will be paid for responding to an incident">
                <NumberField name="incidentMinimumHours" placeholder="Defaults to 0" fullWidth label={false} disabled={loading} />
            </FormRow>

            <Divider />

            <FormRow label="Additional Fields" helperText="Include extra fields to be displayed for specific incident types">
                <Box sx={{flex: 2}}>
                    <Stack spacing={1}>
                        {categories.map((category, index) => (
                            <DisplayCategory key={category.id} {...{category, index, loading, onEdit: handleEdit}} />
                        ))}
                        <Stack direction="row" justifyContent="flex-start">
                            <Button disabled={loading} variant="outlined" size="small" startIcon={<AddIcon />} onClick={handleAdd}>{categories.length > 0 ? 'Add New Field Group' : 'Add Fields'}</Button>
                        </Stack>
                    </Stack>
                </Box>
            </FormRow>
        </Stack>
    );
};