import React from 'react';
import {Card, Divider, Button, IconButton, Stack} from '@mui/material';
import {useFieldArray} from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import {v4 as uuid} from 'uuid';

import TextField from '-/form/TextField';
import CheckboxField from '-/form/CheckboxField';

import {FormRow} from '-/pages/Settings';

const SubList = props => {
    const {loading, name, ...rest} = props;
    const {fields, append, remove} = useFieldArray({
        name
    });

    const handleAdd = () => {
        append({
            uid: uuid(),
            label: ''
        });
    };

    return (
        <Stack sx={{m: 1}} spacing={1} {...rest}>
            {fields.map((field, index) => {
                return (
                    <Stack key={`${name}-${index}`} direction="row" spacing={1} alignItems="center" sx={{flex: 1, pl: 4}}>
                        <TextField
                            disabled={loading}
                            label="Qualification"
                            size="small"
                            fullWidth
                            variant="filled"
                            name={`${name}[${index}].label`}
                            required
                        />
                        <IconButton disabled={loading} onClick={() => remove(index)}>
                            <DeleteIcon />
                        </IconButton>
                    </Stack>
                );
            })}

            {fields.length > 0 && <Divider />}
                
            <Stack direction="row" spacing={1} sx={{mt: 1}} justifyContent="flex-end">
                <Button variant="outlined" size="small" disabled={loading} onClick={handleAdd} startIcon={<AddIcon />}>
                    Add Qualification
                </Button>
            </Stack>
        </Stack>
    );
};

export default function Qualifications(props) {
    const {loading, ...rest} = props;

    const name = 'qualificationAdditionalCategories';
    const {fields, append, remove} = useFieldArray({
        name
    });

    const handleAdd = () => {
        append({
            uid: uuid(),
            label: ''
        });
    };

    return (
        <Stack spacing={1} {...rest}>
            <FormRow label="Use JIBC" helperText="If checked, allows upload of JIBC transcripts as well as saving JIBC data for the user">
                <CheckboxField disabled={loading} sx={{ml: 0}} label={false} name="usesJIBC" />
            </FormRow>

            <Divider />

            <FormRow label="Use Driver/Operator Checklist" helperText="If checked, displays a checklist (from OFC) in the Qualification Matrix for drivers/operators to complete before using apparatus">
                <CheckboxField disabled={loading} sx={{ml: 0}} label={false} name="usesDriverOperatorChecklist" />
            </FormRow>

            <Divider />

            <FormRow label="Additional Qualifications" helperText="Add other qualifications that are not already listed in the Qualification Matrix">
                <Stack sx={{flex: 1}} spacing={1}>
                    {fields.map((field, index) => {
                        return (
                            <Card key={index}>
                                <Stack direction="row" alignItems="center" spacing={1} sx={{flex: 1, p: 1}}>
                                    <TextField
                                        disabled={loading}
                                        variant="filled"
                                        size="small"
                                        label="Category"
                                        fullWidth
                                        required
                                        name={`${name}[${index}].label`}
                                    />
                                    <IconButton disabled={loading} onClick={() => remove(index)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Stack>
                                <Divider />
                                <SubList loading={loading} name={`${name}[${index}].skills`} />
                            </Card>
                        );
                    })}
                    
                    <Stack direction="row" spacing={1} sx={{mt: 1}} justifyContent="flex-start">
                        <Button variant="outlined" size="small" disabled={loading} onClick={handleAdd} startIcon={<AddIcon />}>
                            Add Category
                        </Button>
                    </Stack>
                </Stack>
            </FormRow>
        </Stack>
    );
};