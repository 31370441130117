import React, {useCallback} from 'react';
import {Box} from '@mui/material';
import {GoogleMap, OverlayView} from '@react-google-maps/api';

import BaseChart from '-/components/stats/BaseChart';
import {colorForIncidentType} from '-/data/utils';

const Map = function(props) {
    const {incidents, scrollToIncident} = props;
    const filtered = incidents.filter(incident => incident.location);

    const onLoad = useCallback(map => {
        const bounds = new window.google.maps.LatLngBounds();
        filtered.forEach(incident => {
            const {location} = incident;
            const {latitude, longitude} = location || {};
            const latlng = new window.google.maps.LatLng(latitude, longitude);
            bounds.extend(latlng);
        });
        
        map.fitBounds(bounds);
    }, [filtered]);

    return (
        <BaseChart title="Incident Map" sx={{mb: 2}}>
            <GoogleMap
                mapContainerStyle={{width: '100%', height: 500}}
                zoom={12}
                onLoad={onLoad}
            >
                {filtered.map((incident, index) => {
                    const {type, location} = incident;
                    const {latitude, longitude} = location || {};
                    const color = colorForIncidentType(type);

                    const position = {
                        lat: latitude,
                        lng: longitude
                    };

                    return (
                        <OverlayView
                            key={index}
                            position={position}
                            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                        >
                            <Box
                                sx={{backgroundColor: color, width: 15, height: 15, borderRadius: 7}}
                                onClick={() => scrollToIncident(incident)}
                            />
                        </OverlayView>
                    );
                })}
            </GoogleMap>
        </BaseChart>
    );
};

export default Map;