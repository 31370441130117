import React, {useState, useEffect} from 'react';
import {CircularProgress, Box} from '@mui/material';
import {doc, getDoc} from 'firebase/firestore';
import {useNavigate, useParams} from 'react-router-dom';

import {db} from '-/firebase';

// import GISMap from '-/components/GISMap';

export default function Map() {
    const {id: uid} = useParams();
    const [loading, setLoading] = useState(true);
    // const [record, setRecord] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        let isSubscribed = true;

        const fetch = async() => {
            if (uid) {
                const ref = doc(db, 'responses', uid);
                const raw = await getDoc(ref);
                
                if (isSubscribed) {
                    if (!raw.exists()) {
                        navigate('/respond');
                    }

                    // setRecord({
                    //     id: uid,
                    //     uid,
                    //     ...raw.data()
                    // });

                    setLoading(false);
                }
            }
        };

        fetch();

        return () => isSubscribed = false;
    }, [db, uid, navigate]);

    if (loading) {
        return (
            <Box sx={{display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box sx={{display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
            
        </Box>
    );
}
