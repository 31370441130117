import {alpha} from '@mui/material/styles';

import {red, gray, orange, green, blue} from '-/style/themePrimitives';

export const surfacesCustomizations = {
    MuiAvatar: {
        styleOverrides: {
            root: {
                fontSize: '1rem'
            }
        }
    },
    MuiAccordion: {
        defaultProps: {
            elevation: 0,
            disableGutters: true
        },
        styleOverrides: {
            root: ({theme}) => ({
                padding: 4,
                overflow: 'clip',
                backgroundColor: (theme.vars || theme).palette.background.default,
                border: '1px solid',
                borderColor: (theme.vars || theme).palette.divider,
                ':before': {
                    backgroundColor: 'transparent'
                },
                '&:first-of-type': {
                    borderTopLeftRadius: (theme.vars || theme).shape.borderRadius,
                    borderTopRightRadius: (theme.vars || theme).shape.borderRadius,
                    '& .MuiAccordionSummary-root': {
                        borderTopLeftRadius: 4,
                        borderTopRightRadius: 4
                    }
                },
                '&:last-of-type': {
                    borderBottomLeftRadius: (theme.vars || theme).shape.borderRadius,
                    borderBottomRightRadius: (theme.vars || theme).shape.borderRadius,
                    '& .MuiAccordionSummary-root': {
                        borderBottomLeftRadius: 4,
                        borderBottomRightRadius: 4
                    }
                },
                '&:not(:last-of-type)': {
                    borderBottom: 'none'
                },
                variants: [
                    {
                        props: {
                            severity: 'info'
                        },
                        style: {
                            backgroundColor: alpha(blue[50], 0.8),
                            border: `1px solid ${alpha(blue[200], 0.5)}`,
                            '& .MuiAccordionSummary-root': {
                                '&:hover': {backgroundColor: alpha(blue[100], 0.9)}
                            }
                        }
                    },
                    {
                        props: {
                            severity: 'info-light'
                        },
                        style: {
                            backgroundColor: alpha(blue[50], 0.4),
                            border: `1px solid ${alpha(blue[200], 0.3)}`,
                            '& .MuiAccordionSummary-root': {
                                '&:hover': {backgroundColor: alpha(blue[100], 0.5)}
                            }
                        }
                    },
                    {
                        props: {
                            severity: 'warning-light'
                        },
                        style: {
                            backgroundColor: alpha(orange[50], 0.4),
                            border: `1px solid ${alpha(orange[200], 0.3)}`,
                            '& .MuiAccordionSummary-root': {
                                '&:hover': {backgroundColor: alpha(orange[100], 0.5)}
                            }
                        }
                    }
                ]
            })
        }
    },
    MuiAccordionSummary: {
        styleOverrides: {
            root: () => ({
                border: 'none',
                '&:hover': {backgroundColor: gray[50]},
                '&:focus-visible': {backgroundColor: 'transparent'}
            })
        }
    },
    MuiAccordionDetails: {
        styleOverrides: {
            root: {mb: 20, border: 'none'}
        }
    },
    MuiPaper: {
        defaultProps: {
            elevation: 0
        }
    },
    MuiCard: {
        styleOverrides: {
            root: ({theme}) => {
                return {
                    // padding: 16,
                    // gap: 16,
                    transition: 'all 100ms ease',
                    backgroundColor: gray[50],
                    borderRadius: (theme.vars || theme).shape.borderRadius,
                    border: `1px solid ${(theme.vars || theme).palette.divider}`,
                    boxShadow: 'none',
                    variants: [
                        {
                            props: {
                                variant: 'outlined'
                            },
                            style: {
                                border: `1px solid ${(theme.vars || theme).palette.divider}`,
                                boxShadow: 'none',
                                background: 'hsl(0, 0%, 100%)'
                            }
                        },
                        {
                            props: {
                                variant: 'outlined',
                                severity: 'error'
                            },
                            style: {
                                backgroundColor: red[50],
                                border: `1px solid ${alpha(red[100], 0.5)}`,
                                '& .MuiAlert-icon': {
                                    color: red[500]
                                },
                                '& .MuiButton-text': {
                                    '&:hover': {
                                        backgroundColor: alpha(red[100], 0.5)
                                    }
                                },
                                '& .MuiCardMedia-root': {
                                    backgroundColor: alpha(red[100], 0.5)
                                }
                            }
                        },
                        {
                            props: {
                                variant: 'outlined',
                                severity: 'warning'
                            },
                            style: {
                                backgroundColor: alpha(orange[50], 0.7),
                                border: `1px solid ${alpha(orange[200], 0.5)}`,
                                '& .MuiAlert-icon': {
                                    color: orange[500]
                                },
                                '& .MuiButton-text': {
                                    '&:hover': {
                                        backgroundColor: alpha(orange[100], 0.5)
                                    }
                                },
                                '& .MuiIconButton-root': {
                                    backgroundColor: alpha(orange[100], 0.5),
                                    '&:hover': {
                                        backgroundColor: alpha(orange[100], 0.9)
                                    }
                                },
                                '& .MuiCardMedia-root': {
                                    backgroundColor: alpha(orange[100], 0.5)
                                }
                            }
                        },
                        {
                            props: {
                                severity: 'success'
                            },
                            style: {
                                backgroundColor: green[100],
                                border: `1px solid ${alpha(green[300], 0.5)}`,
                                '& .MuiAlert-icon': {
                                    color: green[500]
                                },
                                '& .MuiSvgIcon-root': {
                                    fill: green[500]
                                },
                                '& .MuiButton-text': {
                                    '&:hover': {
                                        backgroundColor: alpha(green[300], 0.5)
                                    }
                                },
                                '& .MuiButton-colorInherit': {
                                    backgroundColor: alpha(green[200], 0.5),
                                    '&:hover': {
                                        backgroundColor: alpha(green[300], 0.5)
                                    }
                                }
                            }
                        }
                    ]
                };
            }
        }
    },
    MuiCardContent: {
        styleOverrides: {
            root: {
                // padding: 0,
                '&:last-child': {paddingBottom: 16}
            }
        }
    }
    // MuiCssBaseline: {
    //     styleOverrides: {
    //         body: theme => ({
    //             backgroundColor: (theme.vars || theme).palette.background.paper
    //         })
    //     }
    // }
};
