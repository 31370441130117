import React, {useCallback, useLayoutEffect} from 'react';
import {DataGridPremium, useGridApiRef} from '@mui/x-data-grid-premium';

const StatefulDataGrid = props => {
    const {apiRef: rawApiRef, stateId, rows, ...rest} = props;
    if (!stateId) {
        throw new Error('StatefulDataGrid requires a stateId prop');
    }

    const apiRef = rawApiRef || useGridApiRef();
    const stateKey = `statefulDataGrid-${stateId}`;

    const saveSnapshot = useCallback(() => {
        if (apiRef?.current?.exportState && localStorage) {
            const currentState = apiRef.current.exportState();
            localStorage.setItem(stateKey, JSON.stringify(currentState));
        }
    }, [apiRef]);

    useLayoutEffect(() => {
        const stateFromLocalStorage = localStorage?.getItem(stateKey);
        const state = stateFromLocalStorage ? JSON.parse(stateFromLocalStorage) : {};

        apiRef?.current?.restoreState(state);

        window.addEventListener('beforeunload', saveSnapshot);

        return () => {
            window.removeEventListener('beforeunload', saveSnapshot);
            saveSnapshot();
        };
    }, [saveSnapshot]);

    return (
        <DataGridPremium
            apiRef={apiRef}
            rows={rows}
            {...rest}
        />
    );
};

export default StatefulDataGrid;