import React, {useMemo, useEffect, useState, useCallback, useContext} from 'react';
import {Button, Box, Chip, Typography, Stack, AvatarGroup, useMediaQuery, alpha} from '@mui/material';
import {DataGrid, GridActionsCellItem} from '@mui/x-data-grid';
import moment from 'moment';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import {get, omit, pickBy, castArray} from 'lodash';
import {useParams} from 'react-router-dom';
import {doc, getDoc, collection, collectionGroup, updateDoc, deleteDoc, onSnapshot, addDoc} from 'firebase/firestore';
import {useSnackbar} from 'notistack';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import {red, green} from '@mui/material/colors';
import {useTheme, styled} from '@mui/material/styles';
import Tab, {tabClasses} from '@mui/material/Tab';
import {tabsClasses} from '@mui/material/Tabs';
import UpdateIcon from '@mui/icons-material/Update';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import {TaskStatuses} from '@embertracking/common';

import {UserContext} from '-/contexts/User';
import {db} from '-/firebase';

import {hasPermission, populateDocsWithUsers, processRawDoc, processRawDocs, uploadFile} from '-/data/utils';

import UserAvatar from '-/components/UserAvatar';

import TaskDialog from './TaskDialog';
import TaskCompletionDialog from './TaskCompletionDialog';

const getFirstValidValue = obj => {
    const keys = [
        'fullName',
        'email',
        'name',
        'title',
        'incidentNumber',
        'tag',
        '_id'
    ];

    for (const key of keys) {
        if (obj[key] !== null && obj[key] !== undefined && obj[key] !== '') {
            return obj[key];
        }
    }

    return null;
};

const TaskParentLink = ({data, path}) => {
    if (!data) {
        return;
    }

    const handleClick = () => {
        window.open(`/${path}`, '_blank');
    };

    return (
        <Chip size="small" label={getFirstValidValue(data)} onClick={handleClick} />
    );
};

const TabItem = styled(Tab)(({theme}) => ({
    border: `1px solid ${(theme.vars || theme).palette.divider}`,
    marginRight: 0,
    marginBottom: 0,
    marginTop: 6,
    // fontSize: '0.8rem',
    // minHeight: 30,
    // padding: 0,
    // borderTopLeftRadius: 4,
    // borderTopRightRadius: 4,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderRadius: 0,
    // '&:not(:first-of-type)': {
    //     marginLeft: -1,
    // },
    opacity: 0.8,
    background: '#f7f7f7',
    [`&.${tabClasses.selected}`]: {
        background: '#ffffff',
        opacity: 1,
        fontWeight: 'bold',
        marginTop: 0,
        borderRightWidth: 1,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        '& + *': {
            borderLeftWidth: 0
        }
    },
    '& .MuiTab-icon:hover': {
        background: 'transparent'
    },
    '&:first-of-type': {
        borderTopLeftRadius: 4
    },
    '&:last-of-type': {
        borderTopRightRadius: 4,
        borderRightWidth: 1
    }
}));

const useTasks = () => {
    const params = useParams();
    const isCollectionGroup = params['*'] === 'tasks';

    const {users} = useContext(UserContext);
    const {enqueueSnackbar} = useSnackbar();
    const [loadingTasks, setLoadingTasks] = useState(true);
    const [loadingParent, setLoadingParent] = useState(!isCollectionGroup);
    const [tasks, setTasks] = useState([]);
    const [parent, setParent] = useState(null);

    const parentRefArgs = useMemo(() => {
        const {'*': path = ''} = params;
        let refArgs = [db];

        if (isCollectionGroup) {
            return refArgs;
        }

        const split = path.split('/');
        const isGearItem = !split.slice(-2).includes('gear');
        const isUser = /^users\//.test(path);

        if (isUser) {
            refArgs.push(split.slice(0, -1).join('/'));
        } else if (isGearItem) {
            const itemUid = split.pop();
            refArgs.push(path.replace(itemUid, `items/${itemUid}`));
        } else {
            refArgs.push(path);
        }

        return refArgs;
    }, [db, params]);

    const refArgs = useMemo(() => {
        let refArgs = [...parentRefArgs];
        refArgs.push('tasks');

        return refArgs;
    }, [parentRefArgs]);

    useEffect(() => {
        setLoadingTasks(false);

        const ref = isCollectionGroup ? collectionGroup(...refArgs) : collection(...refArgs);
        const unsubscribe = onSnapshot(ref, async snapshot => {
            let rows = populateDocsWithUsers(processRawDocs(snapshot, {includePath: true}), users);
            rows = rows.map(row => {
                const {path, ...rest} = row;
                if (!path || /^tasks\//.test(path)) {
                    return rest;
                }
                
                const parentPath = path.split('/').slice(0, -2).join('/');

                return {
                    ...rest,
                    path,
                    parentPath
                };
            });

            const parentPaths = [...new Set(rows.map(row => row.parentPath).filter(Boolean))];
            const parentDocs = {};

            await Promise.all(
                parentPaths.map(async path => {
                    try {
                        const docRef = doc(db, path);
                        const docSnap = await getDoc(docRef);
                        parentDocs[path] = processRawDoc(docSnap);
                    } catch (error) {
                        console.error(`Error fetching document at ${path}:`, error);
                        parentDocs[path] = null; // Avoid breaking the app
                    }
                })
            );

            rows = rows.map(row => {
                const {parentPath} = row;
                const parent = parentDocs[parentPath];

                return {
                    ...row,
                    parent
                };
            });

            setTasks(rows);

            setLoadingTasks(false);
        }, e => {
            enqueueSnackbar(e.message, {variant: 'error'});

            console.warn('Error getting tasks', refArgs);

            setLoadingTasks(false);
        });
        
        return () => {
            unsubscribe();
        };
    }, [db, refArgs, enqueueSnackbar, users]);

    useEffect(() => {
        if (isCollectionGroup) {
            return;
        }

        setLoadingParent(true);

        const ref = doc(...parentRefArgs);
        const unsubscribe = onSnapshot(ref, async snapshot => {
            let row = processRawDoc(snapshot);

            setParent(row);

            setLoadingParent(false);
        }, e => {
            enqueueSnackbar(e.message, {variant: 'error'});

            console.warn('Error getting tasks', parentRefArgs);

            setLoadingParent(false);
        });
        
        return () => {
            unsubscribe();
        };
    }, [db, parentRefArgs, enqueueSnackbar]);

    const loading = loadingTasks || loadingParent;

    return {
        tasks,
        parent,
        loading,
        refArgs,
        isRoot: isCollectionGroup
    };
};

const TasksGrid = function(props) {
    const {showAssignedToYou = true} = props;
    const [open, setOpen] = useState(false);
    const [completion, setCompletion] = useState(false);
    const [row, setRow] = useState(null);
    const [activeTab, setActiveTab] = useState(showAssignedToYou ? 'assignedToYou' : 'upcoming');
    const {currentUser} = useContext(UserContext);
    const {uid: userUid} = currentUser;
    const dateFormat = get(currentUser, 'settings.dateFormat') || 'DD/MM/YYYY';
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const theme = useTheme();
    const isAtLeastMedium = useMediaQuery(theme.breakpoints.up('sm'));
    const {tasks, loading, refArgs, isRoot} = useTasks();

    const handleRowClick = useCallback((params, e) => {
        if (get(e, 'target.className').includes('MuiChip')) {
            return;
        }

        const {row} = params;
        setRow(row);

        setOpen(true);
    }, []);

    const handleAdd = useCallback(() => {
        setRow(null);
        setOpen(true);
    }, []);

    const onAdd = useCallback(async data => {
        try {
            const ref = collection(...refArgs);

            const record = {
                ...omit(pickBy(data), ['uid', 'id']),
                createdAt: new Date(),
                user: userUid
            };

            await addDoc(ref, record);
            return record;
        } catch (e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }
    }, [enqueueSnackbar, refArgs, userUid]);

    const handleComplete = useCallback(async row => {
        try {
            const {uid, recurring, assignee, frequency, date: dueDate, dateType, completedBy: completedByRaw, completedNote, files: rawFiles, path} = row;

            if (rawFiles) {
                for (const file of rawFiles) {
                    const defaultPath = [...refArgs, uid].slice(1).join('/');
                    await uploadFile(path || defaultPath, file);
                }
            }

            const completedBy = typeof completedByRaw === 'string' ? completedByRaw : get(completedByRaw, 'uid', completedByRaw);

            const ref = path ? doc(db, path) : doc(...refArgs, uid);

            const {files, fileUploads} = rawFiles.reduce((result, file) => {
                if (file.file) {
                    result.fileUploads.push(file);
                } else {
                    result.files.push(file);
                }

                return result;
            }, {
                files: [],
                fileUploads: []
            });

            await updateDoc(ref, {
                status: 'COMPLETE',
                completedNote,
                completedBy,
                completedAt: new Date(),
                files
            });

            for (const file of fileUploads) {
                if (!file.file) {
                    continue;
                }

                await uploadFile(path || ref.path, file);
            }

            if (recurring && frequency) {
                const {name} = row;
                const type = frequency === 'YEARLY' ? 'year' : 'month';
                const date = moment(dueDate).add(1, type).toDate();

                const record = {
                    name,
                    date,
                    createdAt: new Date(),
                    user: userUid,
                    status: 'OPEN',
                    recurring: true,
                    frequency,
                    dateType,
                    ...assignee && {assignee: assignee.uid || assignee}
                };

                let recurringRef = collection(...refArgs);
                if (path) {
                    recurringRef = collection(db, path.split('/').slice(0, -1).join('/'));
                }

                await addDoc(recurringRef, record);
            }

            enqueueSnackbar('Task marked as complete', {variant: 'success'});

            return true;
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});

            return false;
        }
    }, [refArgs, enqueueSnackbar, userUid]);

    const onUpdate = async data => {
        const {uid, assignee, user, path, files: rawFiles, ...rest} = data;
        const toUpdate = rest;

        if (assignee) {
            toUpdate.assignee = assignee.uid || assignee;
        }

        if (user) {
            toUpdate.user = user.uid || user;
        }

        const ref = path ? doc(db, path) : doc(...refArgs, uid);

        const {files, fileUploads} = rawFiles.reduce((result, file) => {
            if (file.file) {
                result.fileUploads.push(file);
            } else {
                result.files.push(file);
            }

            return result;
        }, {
            files: [],
            fileUploads: []
        });

        toUpdate.files = files;

        await updateDoc(ref, {
            ...toUpdate,
            updatedAt: new Date()
        });

        for (const file of fileUploads) {
            if (!file.file) {
                continue;
            }

            await uploadFile(path || ref.path, file);
        }

        return true;
    };

    const onDelete = data => {
        const {uid, path} = data;

        return new Promise(resolve => {
            const onDelete = async() => {
                const ref = path ? doc(db, path) : doc(...refArgs, uid);
                await deleteDoc(ref);

                resolve(true);
            };

            enqueueSnackbar('Are you sure you want to delete this item?', {
                variant: 'warning',
                action: key => {
                    return (
                        <>
                            <Button onClick={() => {
                                closeSnackbar(key);
                                onDelete();
                            }}>
                                Delete
                            </Button>
                            <Button onClick={() => {
                                resolve(true);
                                closeSnackbar(key);
                            }}>
                                Cancel
                            </Button>
                        </>
                    );
                }
            });
        });
    };

    const defaultColumns = [
        {
            field: 'createdAt',
            headerName: 'Created',
            sortable: true,
            valueFormatter: value => {
                return value ? moment(value).format(dateFormat) : '-';
            }
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            renderCell: params => {
                const {row} = params;
                const {name, parent, parentPath} = row || {};

                if (!isRoot) {
                    return name;
                }
                
                return (
                    <Stack alignItems="flex-start" sx={{my: 1}}>
                        <TaskParentLink data={parent} path={parentPath} />
                        <Typography variant="body1">{name}</Typography>
                    </Stack>
                );
            }
        },
        {
            field: 'assignee',
            headerName: 'Assignee',
            width: 100,
            renderCell: params => {
                const {value} = params || {};
                const users = castArray(value);

                const avatars = users.map((member, index) => {
                    const {id} = member || {};

                    return (
                        <UserAvatar key={`${id}-${index}`} user={member} />
                    );
                });

                return (
                    <Stack alignItems="center" sx={{p: 0.5, flex: 1, height: '100%'}}>
                        <AvatarGroup>
                            {avatars}
                        </AvatarGroup>
                    </Stack>
                );
            }
        },
        {
            field: 'status',
            headerName: 'Status',
            sortable: true,
            width: 100,
            valueFormatter: value => {
                return TaskStatuses[value] || '-';
            }
        },
        {
            field: 'date',
            headerName: 'Due By',
            sortable: true,
            width: 140,
            valueFormatter: (value, row) => {
                const {date, dateType} = row || {};

                if (!date) {
                    return '-';
                }

                if (dateType === 'month') {
                    return moment(date).startOf('month').format('MMMM YYYY');
                }

                if (dateType === 'year') {
                    return `End of ${moment(date).startOf('year').format('YYYY')}`;
                }

                return moment(date).startOf('day').format(dateFormat);
            }
        },
        {
            type: 'actions',
            field: 'actions',
            width: 50,
            getActions: params => {
                const {row} = params;
                const {status, assignee} = row || {};

                if (status === 'COMPLETE' || (!hasPermission(currentUser, 'admin') && !hasPermission(currentUser, 'tasks.write') && assignee !== userUid)) {
                    return [];
                }

                return [
                    <GridActionsCellItem
                        key="task-actions-complete"
                        icon={<CheckIcon />}
                        onClick={() => setCompletion(row)}
                        label="Mark Complete"
                    />
                ];
            }
        }
    ];

    const completedColumns = [
        {
            field: 'name',
            headerName: 'Name',
            flex: 1
        },
        {
            field: 'completedAt',
            headerName: 'Completed',
            sortable: true,
            width: 150,
            valueFormatter: value => {
                return value ? moment(value).fromNow() : '-';
            }
        },
        {
            field: 'completedBy',
            headerName: 'Completed By',
            width: 140,
            renderCell: params => {
                const {value} = params || {};
                const users = castArray(value);

                const avatars = users.map((member, index) => {
                    const {id} = member || {};

                    return (
                        <UserAvatar key={`${id}-${index}`} user={member} />
                    );
                });

                return (
                    <Stack alignItems="center" sx={{p: 0.5, flex: 1, height: '100%'}}>
                        <AvatarGroup>
                            {avatars}
                        </AvatarGroup>
                    </Stack>
                );
            }
        }
    ];

    if (tasks.length === 0 && !hasPermission(currentUser, 'tasks.write')) {
        return null;
    }

    const filteredRows = tasks.filter(row => {
        const {status} = row || {};

        if (activeTab === 'upcoming') {
            return status !== 'COMPLETE';
        } else if (activeTab === 'assignedToYou') {
            const {assignee} = row || {};
            const assignedUid = typeof assignee === 'string' ? assignee : get(assignee, 'uid', assignee);

            return status !== 'COMPLETE' && assignedUid === userUid;
        }

        return status === 'COMPLETE';
    });

    let activeTabText = '';
    switch (activeTab) {
    case 'complete':
    case 'upcoming':
        activeTabText = activeTab;
        break;
    case 'assignedToYou':
        activeTabText = 'assigned to you';
        break;
    }

    const tabs = [
        ...showAssignedToYou ? [{
            icon: <AssignmentIndIcon />,
            label: 'Assigned to You',
            value: 'assignedToYou'
        }] : [],
        {
            icon: <UpdateIcon />,
            label: 'Upcoming',
            value: 'upcoming'
        },
        {
            icon: <CheckIcon />,
            label: 'Complete',
            value: 'complete'
        }
    ];

    const activeTabIndex = tabs.findIndex(tab => tab.value === activeTab);

    const handleTabChange = useCallback((e, index) => {
        const {value: newActiveTab = 'upcoming'} = tabs[index] || {};
        setActiveTab(newActiveTab);
    }, []);

    const columns = activeTab === 'complete' ? completedColumns : defaultColumns;

    return (
        <Box {...props}>
            <TaskCompletionDialog open={!!completion} onClose={() => setCompletion(false)} onSubmit={handleComplete} record={completion} />
            
            <TabContext value={activeTabIndex}>
                <TabList
                    onChange={handleTabChange}
                    sx={{
                        minHeight: 30,
                        ml: 1,
                        [`& .${tabsClasses.indicator}`]: {
                            display: 'none'
                        }
                    }}
                >
                    {tabs.map((tab, index) => {
                        const {icon, label} = tab;
                        return (
                            <TabItem key={index} icon={icon} iconPosition="start" label={label} />
                        );
                    })}
                </TabList>

                <Box sx={{display: 'flex', flexDirection: 'column', minHeight: filteredRows.length > 0 ? 'auto' : 150}}>
                    <DataGrid
                        initialState={{
                            sorting: {
                                sortModel: [
                                    {field: 'date', sort: 'desc'}
                                ]
                            },
                            columns: {
                                columnVisibilityModel: {
                                    actions: hasPermission(currentUser, 'tasks.write'),
                                    createdAt: false,
                                    assignee: isAtLeastMedium,
                                    status: isAtLeastMedium
                                }
                            }
                        }}
                        getRowHeight={() => 'auto'}
                        localeText={{noRowsLabel: `No ${activeTabText} tasks`}}
                        loading={loading}
                        rows={filteredRows}
                        columns={columns.map(column => ({...column, disableColumnMenu: true}))}
                        pageSizeOptions={[]}
                        disableRowSelectionOnClick
                        disableColumnFilter
                        onRowClick={handleRowClick}
                        hideFooter
                        slotProps={{
                            loadingOverlay: {
                                variant: 'linear-progress',
                                noRowsVariant: 'skeleton'
                            }
                        }}
                        getRowClassName={params => {
                            const {row} = params;
                            const {status, date, dateType} = row || {};
                            const classNames = [];

                            let isOverdue = moment(date).isBefore(moment(), 'day');
                            if (dateType === 'month') {
                                isOverdue = moment(date).isBefore(moment(), 'month');
                            } else if (dateType === 'year') {
                                isOverdue = moment(date).isBefore(moment(), 'year');
                            }

                            if (status === 'COMPLETE') {
                                return 'complete';
                            }

                            if (isOverdue) {
                                classNames.push('overdue');
                            }
        
                            return classNames.join(' ');
                        }}
                        sx={{
                            '& .MuiDataGrid-cell:focus-within': {
                                outline: 'none'
                            },
                            
                            '& .overdue': {
                                backgroundColor: `${alpha(red[100], 0.3)}`,
                                borderLeft: `4px solid ${red[500]}`
                            },
                            '& .MuiDataGrid-row:hover.overdue': {
                                backgroundColor: `${alpha(red[100], 0.4)}`
                                // borderLeft: `4px solid ${red[500]}`
                            },
                            '& .complete': {
                                backgroundColor: `${alpha(green[100], 0.3)}`,
                                borderLeft: `4px solid ${green[500]}`
                            },
                            '& .MuiDataGrid-row:hover.complete': {
                                backgroundColor: `${alpha(green[100], 0.4)}`
                                // borderLeft: `4px solid ${red[500]}`
                            }
                        }}
                    />
                </Box>
            </TabContext>

            {hasPermission(currentUser, 'tasks.write') && (
                <Box sx={{display: 'flex', alignItems: 'center', mt: 1}}>
                    <TaskDialog item={row} onSubmit={row ? onUpdate : onAdd} open={open} onDelete={row && onDelete} handleClose={() => setOpen(false)} />
                    <Box sx={{flexGrow: 1}} />
                    <Button startIcon={<AddIcon />} disabled={loading} size="small" variant="outlined" onClick={handleAdd}>Add New Task</Button>
                </Box>
            )}
        </Box>
    );
};

export default TasksGrid;