import React, {useContext} from 'react';
import {Alert} from '@mui/material';

import {UserContext} from '-/contexts/User';

export default function ArchivedAlert({label = 'item', archived, archivedAt, archivedBy: archivedByUid, ...rest}) {
    const {users = []} = useContext(UserContext);

    if (!archived) {
        return null;
    }

    let body = `This ${label} is archived`;

    try {
        if (archivedAt) {
            body = `This ${label} was archived`;
            body += ' ';
            body += archivedAt.fromNow();
        }
    } catch(e) {
        //
    }

    try {
        if (archivedByUid) {
            const user = users.find(user => user.uid === archivedByUid);
            if (user) {
                const {fullName, email} = user;

                body += ` by ${fullName || email}`;
            }
        }
    } catch(e) {
        //
    }

    return (
        <Alert severity="warning" sx={{mb: 2}} {...rest}>
            {body}
        </Alert>
    );
};