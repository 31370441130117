import React, {useState, useEffect, useContext, useCallback} from 'react';
import {useTheme} from '@mui/material/styles';
import {Skeleton, Alert, Button, Box, Card, CardMedia, Typography, Grid2 as Grid, useMediaQuery} from '@mui/material';
import {useNavigate, Link as RouterLink} from 'react-router-dom';
import {useSnackbar} from 'notistack';
import {get, sortBy, chunk, startCase} from 'lodash';
import moment from 'moment';
import {collection, query, orderBy, where, limit} from 'firebase/firestore';
import WarningIcon from '@mui/icons-material/Warning';
import HideImageIcon from '@mui/icons-material/HideImage';
import {ref, getDownloadURL} from 'firebase/storage';

import {UserContext} from '-/contexts/User';
import {SettingsContext} from '-/contexts/Settings';
import {db, storage} from '-/firebase';

import {getCollection, populateKeyFromCollection, hasPermission} from '-/data/utils';

import ArchivedChip from '-/components/ArchivedChip';

const ApparatusCard = props => {
    const {apparatus} = props;
    const navigate = useNavigate();

    const {uid, tag, image, latestCheck, latestActiveCheck, archived = false, archivedAt} = apparatus;
    const {createdAt} = latestCheck || {};
    const isMoreThanAWeek = latestCheck && moment().diff(createdAt, 'days') > 7 && archived !== true;
    const isActive = !!latestActiveCheck;
    let severity = isActive ? 'warning' : isMoreThanAWeek ? 'error' : 'info';
    if (archived) {
        severity = 'warning';
    }

    let type = latestActiveCheck ? latestActiveCheck.type : (latestCheck && latestCheck.type);
    if (type === 'pretrips') {
        type = 'pretrip';
    }

    const handleContinueTruckCheck = useCallback(e => {
        e.stopPropagation();

        if (!latestActiveCheck) {
            return;
        }
        
        navigate(`/apparatus/${uid}/${latestActiveCheck.type}/${latestActiveCheck.uid}`);
    }, [latestActiveCheck]);
    
    const onClick = useCallback(e => {
        const {metaKey} = e;

        const url = `/apparatus/${uid}`;
        if (metaKey) {
            window.open(url);
            return;
        }

        navigate(url);
    }, [navigate, uid]);

    const [imageUrl, setImageUrl] = useState(null);

    useEffect(() => {
        const {filePath, thumbnailPath} = image || {};

        let isSubscribed = true;

        const fetch = async() => {
            try {
                if (thumbnailPath) {
                    const url = await getDownloadURL(ref(storage, thumbnailPath));
                    if (isSubscribed) {
                        setImageUrl(url);
                    }

                    return;
                }

                if (filePath) {
                    const url = await getDownloadURL(ref(storage, filePath));
                    if (isSubscribed) {
                        setImageUrl(url);
                    }

                    return;
                }
            } catch(e) {
                console.warn(e);
            }
        };

        fetch();

        return () => isSubscribed = false;
    }, [image]);

    return (
        <Card variant="outlined" severity={severity} sx={{p: 1, cursor: 'pointer', position: 'relative'}} onClick={onClick}>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
                <Typography variant="h4" component="div" sx={{flex: 1, lineHeight: 1, pb: 0.5}}>
                    {tag}
                </Typography>
                {isMoreThanAWeek && (
                    <WarningIcon sx={{ml: 1}} color={isActive ? 'warning' : 'error'} />
                )}
            </Box>
            
            {latestCheck && (
                <Typography variant="overline" component="div" sx={{lineHeight: 1}}>
                    <Box component="span" sx={theme => ({color: (theme.vars || theme).palette.text.secondary})}>Last Check:</Box> <strong>{startCase(type)} {isActive ? 'ACTIVE' : createdAt.fromNow()}</strong>
                </Typography>
            )}

            <Card sx={{border: 0, overflow: 'hidden', mt: 1}}>
                <CardMedia
                    sx={{
                        aspectRatio: '16/9',
                        borderRadius: 0.5,
                        backgroundColor: 'background.paper',
                        cursor: 'pointer',
                        ...archived && {
                            filter: 'blur(3px) brightness(1.2)',
                            opacity: 0.6
                        }
                    }}
                    image={imageUrl}
                    title={tag}
                >
                    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', color: 'text.secondary', opacity: 0.5}}>
                        {!image && (
                            <HideImageIcon sx={{color: 'grey.500', fontSize: 40}} />
                        )}
                    </Box>
                </CardMedia>
            </Card>

            <ArchivedChip
                {...{archived, archivedAt}}
                sx={{position: 'absolute', bottom: 16, right: 16, left: 16, zIndex: 1}}
            />

            {latestActiveCheck && (
                <Box sx={{display: 'flex', mt: 1}}>
                    <Button sx={{flex: 1}} size="small" variant="contained" color={severity} onClick={handleContinueTruckCheck}>
                        Continue {startCase(type)}{type !== 'pretrip' && ' Check'}
                    </Button>
                </Box>
            )}
        </Card>
    );
};

const ApparatusSkeleton = () => (
    <Grid size={{xs: 12, sm: 6, lg: 4, xl: 4}}>
        <Skeleton variant="rectangular" height={240}>
            
        </Skeleton>
        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
            <Skeleton width="20%" height={64} />
            <Skeleton width="40%" height={40} />
        </Box>
    </Grid>
);

const Apparatuses = props => {
    const {includeArchived = false} = props;

    const [loading, setLoading] = useState(true);
    const [docs, setDocs] = useState([]);
    const {enqueueSnackbar} = useSnackbar();
    const {currentUser} = useContext(UserContext);
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const {displayCarsToStationLogin = true} = useContext(SettingsContext);

    const {station, isStation = false} = currentUser;
    const stationUid = typeof station === 'string' ? station : get(station, 'uid');

    useEffect(() => {
        let isSubscribed = true;

        async function fetch() {
            try {
                let ref = collection(db, 'apparatus');
                if (!includeArchived) {
                    ref = query(ref, where('archived', '!=', true));
                }

                let docs = await getCollection(db, ref);
                docs = await populateKeyFromCollection(db, docs, 'station', 'stations');

                if ((!hasPermission(currentUser, 'stations.write') && station) || isStation) {
                    docs = docs.filter(apparatus => {
                        return get(apparatus, 'station.uid') === stationUid;
                    });

                    if (!displayCarsToStationLogin) {
                        docs = docs.filter(apparatus => {
                            return get(apparatus, 'type') !== 'CAR';
                        });
                    }
                }

                docs = await Promise.all(docs.map(async apparatus => {
                    const {id} = apparatus;
                    
                    const ref = collection(db, 'apparatus', id, 'checks');
                    const q = query(ref, orderBy('createdAt', 'desc'), limit(1));
                    const [latestCheck] = await getCollection(db, q);

                    const activeQ = query(ref, where('active', '==', true), orderBy('createdAt', 'desc'), limit(1));
                    const [latestActiveCheck] = await getCollection(db, activeQ);

                    return {
                        ...apparatus,
                        latestCheck,
                        latestActiveCheck
                    };
                }));

                docs = sortBy(docs, ['station.name', 'tag']);

                if (isSubscribed) {
                    setDocs(docs);
                }
            } catch(e) {
                enqueueSnackbar(e.message, {variant: 'error'});
            }

            if (isSubscribed) {
                setLoading(false);
            }
        }

        fetch();
        
        return () => isSubscribed = false;
    }, [enqueueSnackbar, db, station, includeArchived]);

    const chunked = chunk(docs || [], isSmall ? 1 : 2);

    return (
        <>
            <Grid container spacing={1}>
                {loading ? (
                    <>
                        <ApparatusSkeleton />
                        <ApparatusSkeleton />
                        <ApparatusSkeleton />
                        <ApparatusSkeleton />
                    </>
                ) : chunked.map(docs => {
                    return docs.map(doc => (
                        <Grid size={{xs: 12, sm: 6, lg: 4, xl: 4}} key={doc.uid}>
                            <ApparatusCard apparatus={doc} key={doc.uid} />
                        </Grid>
                    ));
                })}

                {!loading && docs.length === 0 && (
                    <Alert severity="warning" sx={{width: '100%'}}>
                        Add an apparatus to get started
                    </Alert>
                )}
            </Grid>

            {hasPermission(currentUser, 'apparatus.write') && (
                <Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 2}}>
                    <Button component={RouterLink} variant="contained" to="/apparatus/add">Add Apparatus</Button>
                </Box>
            )}
        </>
    );
};

export default Apparatuses;