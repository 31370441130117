import React, {useCallback} from 'react';
import {Typography, Chip, Breadcrumbs, Link, Stack} from '@mui/material';
import {capitalize} from 'lodash';
import {parseDiff, Diff, Hunk} from 'react-diff-view';
import {collection} from 'firebase/firestore';
import {useGridApiRef} from '@mui/x-data-grid-pro';
import 'react-diff-view/style/index.css';

import {db} from '-/firebase.js';

import {populateKeyFromCollection} from '-/data/utils';

import FirestoreDataGrid from '-/components/FirestoreDataGrid';

const CONTANT_GIT_DIFF_HEADER = `diff --git a/config.yml b/config.yml
index 1111111..2222222 100644
--- a/config.yml
+++ b/config.yml\n`;

const DiffDetailContent = ({row}) => {
    const {diff: diffText} = row;
    if (!diffText) {
        return null;
    }

    
    const [diff] = parseDiff(CONTANT_GIT_DIFF_HEADER + diffText, {nearbySequences: 'zip'});
    const {oldRevision, newRevision, type, hunks} = diff;

    return (
        <div>
            <Diff key={oldRevision + '-' + newRevision} viewType="split" diffType={type} hunks={hunks}>
                {hunks => hunks.map(hunk => <Hunk key={hunk.content} hunk={hunk} />)}
            </Diff>
        </div>
    );
};

const HistoryGrid = function(props) {
    const {showOnlyDescription = false} = props;
    const apiRef = useGridApiRef();
    const getDetailPanelContent = useCallback(({row}) => <DiffDetailContent row={row} />, []);
    const getDetailPanelHeight = useCallback(() => 'auto', []);

    const columns = [
        {
            field: 'createdAt',
            headerName: 'Date',
            minWidth: 150,
            type: 'date',
            valueFormatter: value => value.fromNow()
        },
        {
            field: 'user',
            headerName: 'Changed By',
            minWidth: 170,
            valueGetter: (user, row) => {
                const {authType} = row;
                if (row === 'system') {
                    return 'System';
                }

                const {fullName, email} = user || {};
                return fullName || email || authType;
            }
        },
        {
            field: 'collections',
            headerName: 'Entity',
            minWidth: 140,
            valueGetter: value => {
                return (value || []).join('/');
            }
        },
        {
            field: 'document',
            headerName: 'Document',
            flex: 1,
            minWidth: 140
        },
        {
            field: 'description',
            headerName: 'Change',
            minWidth: 140,
            flex: 1,
            renderCell: params => {
                const {row} = params;
                const {uid, link, type, collections = [], description, changeDescription} = row;

                const title = (
                    <Stack direction="row" spacing={0.5} alignItems="center">
                        <Typography color="text.secondary">{capitalize(type)}</Typography>
                        <Typography sx={{fontWeight: 'bold'}}>{description || uid}</Typography>
                    </Stack>
                );

                return (
                    <Stack direction="column" sx={{my: 1, flex: 1}} spacing={0.5}>
                        {!showOnlyDescription && (
                            <Stack sx={{flex: 1}} direction="row" spacing={0.5} justifyContent="space-between">
                                {link ? (
                                    <Link href={link} underline="none" target="_blank">
                                        {title}
                                    </Link>
                                ) : title}
                                {collections.length > 0 && (
                                    <Chip
                                        size="small"
                                        label={(
                                            <Breadcrumbs>
                                                {collections.map(collection => (
                                                    <Typography key={collection} sx={{color: 'text.primary', fontSize: '0.7rem'}}>{collection}</Typography>
                                                ))}
                                            </Breadcrumbs>
                                        )}
                                    />
                                )}
                            </Stack>
                        )}
                        {changeDescription && (
                            <Typography variant="caption" color="text.secondary">{changeDescription}</Typography>
                        )}
                    </Stack>
                );
            }
        },
        {
            field: 'type',
            headerName: 'type',
            minWidth: 120
        }
    ];

    const handleRowClick = useCallback((params, e) => {
        if (e.target.closest('a[href]')) {
            return;
        }

        apiRef.current.toggleDetailPanel(params.id);
    }, [apiRef]);

    const q = collection(db, 'history');

    const handleProcessDocs = async docs => {
        return populateKeyFromCollection(db, docs, 'user', 'users');
    };

    return (
        <FirestoreDataGrid
            apiRef={apiRef}
            query={q}
            columns={columns}
            processDocs={handleProcessDocs}
            localeText={{noRowsLabel: 'No history'}}
            getDetailPanelHeight={getDetailPanelHeight}
            getDetailPanelContent={getDetailPanelContent}
            getRowHeight={showOnlyDescription ? undefined : () => 'auto'}
            onRowClick={handleRowClick}
            initialState={{
                columns: {
                    columnVisibilityModel: {
                        collections: false,
                        document: false,
                        type: false
                    }
                },
                sorting: {
                    sortModel: [{field: 'createdAt', sort: 'desc'}]
                }
            }}
        />
    );
};

export default HistoryGrid;