import React, {useEffect, useState, useCallback} from 'react';
import {Grid2 as Grid, Box, Card, Button, CardContent, Stack, Divider, Typography, Tabs, Tab} from '@mui/material';
import {useForm, FormProvider} from 'react-hook-form';
import {doc, updateDoc, deleteField} from 'firebase/firestore';
import {omit} from 'lodash';
import {useSnackbar} from 'notistack';

import {db} from '-/firebase';

import {getCollectionDoc, uploadImage, ensureJSDates, ensureMomentDates} from '-/data/utils';

import ImageUploadField from '-/form/ImageUploadField';
import TextField from '-/form/TextField';
import PasswordField from '-/form/PasswordField';
import CheckboxField from '-/form/CheckboxField';

import Incidents from '-/pages/settings/Incidents';
import Qualifications from '-/pages/settings/Qualifications';

export const FormRow = ({label, helperText, children}) => {
    return (
        <Stack direction={{xs: 'column', md: 'row'}} spacing={{xs: 1, md: 2}} sx={{alignItems: {xs: 'flex-start', md: 'center'}}}>
            <Stack sx={{flex: 1, textAlign: {xs: 'left', md: 'right'}}} alignItems={{xs: 'stretch', md: 'flex-end'}}>
                <Typography variant="body1" sx={{flex: 1}}>
                    {label}
                </Typography>
                {helperText && <Typography variant="caption" sx={{fontSize: '0.7rem', lineHeight: 1.5}} color="text.secondary">{helperText}</Typography>}
            </Stack>
            
            <Box sx={{flex: {xs: 1, md: 2}, width: '100%'}}>
                {children}
            </Box>
        </Stack>
    );
};

export default function() {
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState('general');
    const {enqueueSnackbar} = useSnackbar();

    const methods = useForm({
        defaultValues: {
            siteName: '',
            limitToDomain: false,
            domain: '',
            displayCarsToStationLogin: true,
            allowStationLogin: false,
            allowRegistrationWithoutUser: false,
            usesJIBC: false,
            usesIAR: false,
            sendPretripEmail: false,
            usesDriverOperatorChecklist: false,
            additionalFields: {},

            secret: {
                iar: {
                    agency: '',
                    username: '',
                    password: ''
                }
            }
        },
        mode: 'onChange'
    });

    const {reset, handleSubmit, watch} = methods;
    const limitToDomain = watch('limitToDomain');
    const usesIAR = watch('usesIAR');
    const sendPretripEmail = watch('sendPretripEmail');
    const allowStationLogin = watch('allowStationLogin');

    useEffect(() => {
        const fetch = async () => {
            try {
                const general = await getCollectionDoc(db, 'settings', 'general');
                const secret = await getCollectionDoc(db, 'settings', 'secret');
                
                reset(ensureMomentDates({
                    ...general,
                    secret
                }));
            } catch(e) {
                enqueueSnackbar(e, {variant: 'error'});
            }

            setLoading(false);
        };

        fetch();
    }, []);

    const onSubmit = useCallback(async data => {
        setLoading(true);

        const {imageFile, secret: secretData, ...rest} = data;

        try {
            const generalRef = doc(db, 'settings', 'general');
            const secretRef = doc(db, 'settings', 'secret');

            const data = omit({
                ...rest
            }, 'image');

            if (imageFile) {
                await uploadImage('settings/general', imageFile);
            } else if (imageFile === null) {
                data.image = deleteField();
            }

            console.log(JSON.stringify(data, null, 4));

            await updateDoc(generalRef, ensureJSDates(data));

            if (secretData) {
                await updateDoc(secretRef, ensureJSDates(secretData));
            }

            reset({}, {
                keepValues: true
            });

            enqueueSnackbar('Settings saved', {variant: 'success'});
        } catch(e) {
            enqueueSnackbar(e, {variant: 'error'});
        }

        setLoading(false);
    }, []);

    return (
        <FormProvider {...methods}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{mb: 2}}>
                <Typography variant="h5" gutterBottom>Settings</Typography>

                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    loading={loading}
                    disabled={loading}
                    onClick={handleSubmit(onSubmit)}
                >
                    Save Settings
                </Button>
            </Stack>

            <Box sx={{borderBottom: 1, borderColor: 'divider', mb: 2}}>
                <Tabs value={activeTab} onChange={(e, tab) => setActiveTab(tab)}>
                    <Tab label="General" value="general" />
                    <Tab label="Login" value="login" />
                    <Tab label="Incidents" value="incidents" />
                    <Tab label="Qualifications" value="qualifications" />
                    <Tab label="Checks" value="checks" />
                    <Tab label="Integrations" value="integrations" />
                </Tabs>
            </Box>

            <Stack spacing={1} sx={{display: activeTab === 'general' ? 'flex' : 'none'}}>
                <FormRow label="Logo">
                    <ImageUploadField disabled={loading} sx={{width: 200}} name="image" label="Logo" />
                </FormRow>

                <FormRow label="Department Name">
                    <TextField disabled={loading} name="siteName" fullWidth label={false} />
                </FormRow>
            </Stack>

            <Stack spacing={1} sx={{display: activeTab === 'login' ? 'flex' : 'none'}}>
                <FormRow label="Limit to Domain" helperText="If checked, only users with email addresses from the domain will be able to register">
                    <CheckboxField disabled={loading} label={false} name="limitToDomain" />
                </FormRow>

                {limitToDomain && (
                    <Stack direction="row">
                        <Box sx={{flex: 1}} />
                        <Card sx={{flex: 2, ml: 2}}>
                            <CardContent>
                                <TextField
                                    disabled={loading}
                                    name="domain"
                                    rules={{
                                        required: 'Domain is required if limiting to domain',
                                        pattern: {
                                            value: /^[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: 'Invalid domain'
                                        }
                                    }}
                                />
                            </CardContent>
                        </Card>
                    </Stack>
                )}

                <Divider />

                <FormRow label="Allow Station Login" helperText="Allows users to login as a station, with restricted permissions">
                    <CheckboxField disabled={loading} label={false} name="allowStationLogin" />
                </FormRow>

                {allowStationLogin && (
                    <>
                        <FormRow label="Station Login Password" helperText="The password required to login as a station">
                            <PasswordField rules={{required: 'A station password is required'}} disabled={loading} name="secret.stationLoginPassword" fullWidth label={false} />
                        </FormRow>

                        <FormRow label="Display Cars to Station Users" helperText="Displays apparatus marked as Cars to station login users">
                            <CheckboxField disabled={loading} label={false} name="displayCarsToStationLogin" />
                        </FormRow>
                    </>
                )}

                <Divider />

                <FormRow label="Allow Registration without User" helperText="Allows users to register, even when a user has not been previously added">
                    <CheckboxField disabled={loading} label={false} name="allowRegistrationWithoutUser" />
                </FormRow>
            </Stack>

            <Incidents sx={{display: activeTab === 'incidents' ? 'flex' : 'none'}} />

            <Qualifications sx={{display: activeTab === 'qualifications' ? 'flex' : 'none'}} />
            
            <Stack spacing={1} sx={{display: activeTab === 'checks' ? 'flex' : 'none'}}>
                <FormRow label="Send Pretrip/Posttrip PDF to Email" helperText="Sends a PDF of the completed pretrip/posttrip to the specified email(s)">
                    <CheckboxField disabled={loading} label={false} name="sendPretripEmail" />
                </FormRow>

                {sendPretripEmail && (
                    <Stack direction="row">
                        <Box sx={{flex: 1}} />
                        <Card sx={{flex: 2, ml: 2}}>
                            <CardContent>
                                <TextField
                                    disabled={loading}
                                    label="Pretrip/Posttrip Email(s)"
                                    name="sendPretripEmailTo"
                                    placeholder="Comma separated list of emails"
                                    fullWidth
                                    rules={{
                                        required: 'An email is required if sending pretrip/posttrip PDFs',
                                        pattern: {
                                            value: /^(\s*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\s*,)*\s*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\s*$/,
                                            message: 'Invalid email'
                                        }
                                    }}
                                />
                            </CardContent>
                        </Card>
                    </Stack>
                )}
            </Stack>

            <Stack spacing={1} sx={{display: activeTab === 'integrations' ? 'flex' : 'none'}}>
                <FormRow label="Use IamResponding" helperText="Allows importing of incidents and training from IamResponding">
                    <CheckboxField disabled={loading} label={false} name="usesIAR" />
                </FormRow>

                {usesIAR && (
                    <Stack direction="row">
                        <Box sx={{flex: 1}} />
                        <Card sx={{flex: 2, ml: 2}}>
                            <CardContent>
                                <Grid container spacing={1}>
                                    <Grid size={{xs: 12, sm: 6, md: 4}}>
                                        <TextField disabled={loading} name="secret.iar.agency" label="IaR Agency" fullWidth />
                                    </Grid>
                                    <Grid size={{xs: 12, sm: 6, md: 4}}>
                                        <TextField disabled={loading} name="secret.iar.username" label="IaR Username" fullWidth />
                                    </Grid>
                                    <Grid size={{xs: 12, sm: 6, md: 4}}>
                                        <PasswordField disabled={loading} name="secret.iar.password" label="IaR Password" fullWidth />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Stack>
                )}
            </Stack>
        </FormProvider>
    );
};