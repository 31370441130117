import React from 'react';
import {Dialog, Slide, useMediaQuery} from '@mui/material';
import {useTheme} from '@mui/material/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ResponsiveDialog({children, ...props}) {
    const {maxWidth = 'md', ...rest} = props;
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down(maxWidth));

    return (
        <Dialog
            fullScreen={isSmall}
            maxWidth={maxWidth}
            fullWidth
            TransitionComponent={Transition}
            {...rest}
        >
            {children}
        </Dialog>
    );
};