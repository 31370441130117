import React from 'react';
import {Box, Typography, Stack} from '@mui/material';
import 'react-diff-view/style/index.css';

import HistoryGrid from '-/pages/history/Grid';

const History = function() {
    return (
        <Box sx={{flex: 1, display: 'flex', flexDirection: 'column'}}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{mb: 2}}>
                <Typography variant="h5" gutterBottom>History</Typography>
            </Stack>

            <Box sx={{flex: 1, mt: 2, position: 'relative', minHeight: 300}}>
                <Box sx={{inset: 0, position: 'absolute'}}>
                    <HistoryGrid />
                </Box>
            </Box>
        </Box>
    );
};

export default History;