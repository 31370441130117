import React, {useContext} from 'react';
import {Typography, Paper, Stack, Box} from '@mui/material';
import {useFormContext} from 'react-hook-form';
import {camelCase} from 'lodash';

import {SettingsContext} from '-/contexts/Settings';

import TextField from '-/form/TextField.js';
import UserAutocompleteField from '-/form/UserAutocompleteField.js';
import AutocompleteField from '-/form/AutocompleteField.js';
import CheckboxField from '-/form/CheckboxField.js';
import DatePickerField from '-/form/DatePickerField.js';
import GoogleAutocompleteField from '-/form/GoogleAutocompleteField';
import PhoneField from '-/form/PhoneField';

export default function Additional(props) {
    const {loading, ...rest} = props;

    const {watch} = useFormContext();
    const type = watch('type');
    
    const {incidentFieldCategories = []} = useContext(SettingsContext);
    const matchedCategories = incidentFieldCategories.filter(category => !category.types.length || category.types.includes(type));

    return (
        <Stack spacing={2} {...rest}>
            {matchedCategories.map((category, index) => {
                const {name, fields = []} = category;
                const componentProps = {
                    ...name && {
                        component: Paper,
                        variant: 'outlined',
                        sx: {
                            p: 1,
                            pt: 2
                        }
                    }
                };

                return (
                    <Box key={`category${index}`} {...componentProps}>
                        {name && <Typography variant="h6" sx={{mb: 2}}>{name}</Typography>}

                        <Stack spacing={2}>
                            {fields.map(field => {
                                const {type, label, options, multiple = false, multiline = false} = field;
                                const key = camelCase(label);

                                const baseProps = {
                                    fullWidth: true,
                                    disabled: loading,
                                    label,
                                    name: `additionalFields.${key}`
                                };

                                if (type === 'select') {
                                    return (
                                        <AutocompleteField
                                            key={key}
                                            {...baseProps}
                                            options={options}
                                            multiple
                                            freeSolo
                                        />
                                    );
                                } else if (type === 'date') {
                                    return (
                                        <DatePickerField
                                            key={key}
                                            {...baseProps}
                                        />
                                    );
                                } else if (type === 'user') {
                                    return (
                                        <UserAutocompleteField
                                            key={key}
                                            {...baseProps}
                                            multiple={multiple}
                                        />
                                    );
                                } else if (type === 'location') {
                                    return (
                                        <GoogleAutocompleteField
                                            key={key}
                                            {...baseProps}
                                        />
                                    );
                                } else if (type === 'phone') {
                                    return (
                                        <PhoneField
                                            key={key}
                                            {...baseProps}
                                        />
                                    );
                                } else if (type === 'checkbox') {
                                    return (
                                        <CheckboxField
                                            key={key}
                                            {...baseProps}
                                        />
                                    );
                                }

                                return (
                                    <TextField
                                        key={key}
                                        {...baseProps}
                                        {...multiline && {
                                            multiline: true,
                                            minRows: 3
                                        }}
                                    />
                                );
                            })}
                        </Stack>
                    </Box>
                );
            })}
        </Stack>
    );
};