import React, {forwardRef} from 'react';
import {DataGridPremium, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter, GridToolbarColumnsButton} from '@mui/x-data-grid-premium';
import {Box, Stack, useTheme, useMediaQuery} from '@mui/material';

import StatefulDataGrid from './StatefulDataGrid';

function Toolbar(props) {
    const {disableColumnFilter = false, showQuickFilter = true} = props;

    return (
        <GridToolbarContainer>
            <Stack direction="row" spacing={1} sx={{flex: 1}}>
                {!disableColumnFilter && <GridToolbarColumnsButton />}
                <GridToolbarExport />
            </Stack>

            {showQuickFilter && <GridToolbarQuickFilter />}
        </GridToolbarContainer>
    );
}

const SearchableDataGrid = forwardRef((props, ref) => {
    const {stateId, columns = [], rows = [], loading, disableToolbar, hasConatiner = true, slotProps, ...rest} = props;
    const {disableColumnFilter} = rest;
    const Component = stateId ? StatefulDataGrid : DataGridPremium;
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const {toolbar: toolbarSlotProps, ...restSlotProps} = slotProps || {};
    const toolbar = {
        disableColumnFilter,
        ...toolbarSlotProps
    };

    const content = (
        <Component
            ref={ref}
            stateId={stateId}
            disableDensitySelector
            slots={{toolbar: !disableToolbar && Toolbar}}
            slotProps={{
                toolbar,
                ...restSlotProps
            }}
            rows={rows}
            loading={loading}
            columns={columns.map(column => {
                return {
                    disableColumnMenu: isSmall,
                    ...column
                };
            })}
            {...rest}
        />
    );

    if (!hasConatiner) {
        return content;
    }

    return (
        <Box sx={{width: '100%'}}>
            <Box style={{display: 'flex', flexDirection: 'column', minHeight: !rows || rows.length === 0 || loading ? 250 : 'auto'}}>
                {content}
            </Box>
        </Box>
    );
});

export default SearchableDataGrid;