import React, {useContext} from 'react';

import {StationsContext} from '-/contexts/Stations';

import SelectField from './SelectField.js';

const StationsField = function(props) {
    const {stations} = useContext(StationsContext);
    const {disabled: disabledRaw, ...rest} = props;
    const disabled = disabledRaw || stations.length === 0;

    return (
        <SelectField
            label="Station"
            name="station"
            options={stations.map(station => {
                return {
                    value: station.id,
                    label: station.name
                };
            })}
            {...rest}
            disabled={disabled}
        />
    );
};

export default StationsField;