import React, {useEffect, useState, useCallback} from 'react';
import {DataGridPro} from '@mui/x-data-grid-pro';
import {getDocs, query, limit, orderBy, startAfter, getCountFromServer} from 'firebase/firestore';
import {get} from 'lodash';

import {processRawDocs} from '-/data/utils';

const PAGE_SIZE = 25;

const FirestoreDataGrid = props => {
    const {query: q, processDocs, ...rest} = props;
    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [pageSnapshots, setPageSnapshots] = useState([]); // Stores last doc of each visited page

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: PAGE_SIZE
    });

    const [sortModel, setSortModel] = useState(get(rest, 'initialState.sorting.sortModel', []));

    const fetchCount = useCallback(async () => {
        const snapshot = await getCountFromServer(q);
        setRowCount(snapshot.data().count);
    }, [q]);

    const fetchData = useCallback(async (page = paginationModel.page, pageSize = paginationModel.pageSize, sortQuery = sortModel) => {
        setLoading(true);

        let filteredQuery = query(q);

        // Apply sorting
        if (sortQuery.length) {
            sortQuery.forEach(({field, sort}) => {
                filteredQuery = query(filteredQuery, orderBy(field, sort));
            });
        }

        // Determine where to start
        if (page > 0 && pageSnapshots[page - 1]) {
            filteredQuery = query(filteredQuery, startAfter(pageSnapshots[page - 1]), limit(pageSize));
        } else {
            filteredQuery = query(filteredQuery, limit(pageSize));
        }

        const snapshot = await getDocs(filteredQuery);
        let docs = processRawDocs(snapshot);

        if (processDocs) {
            docs = await processDocs(docs);
        }

        setRows(docs);
        setLoading(false);

        // Store last document of each page
        setPageSnapshots(prev => {
            const newSnapshots = [...prev];
            newSnapshots[page] = snapshot.docs[snapshot.docs.length - 1] || null;
            return newSnapshots;
        });
    }, [q, pageSnapshots, paginationModel, sortModel]);

    const handleSortModelChange = useCallback(newSortModel => {
        setSortModel(newSortModel);
        setPageSnapshots([]); // Reset page tracking when sorting changes
        fetchData(0, paginationModel.pageSize, newSortModel); // Reset to first page
    }, [fetchData, paginationModel.pageSize]);

    const handlePaginationModelChange = useCallback(newPaginationModel => {
        setPaginationModel(newPaginationModel);
        fetchData(newPaginationModel.page, newPaginationModel.pageSize);
    }, [fetchData]);

    useEffect(() => {
        fetchCount();
        fetchData();
    }, []);

    return (
        <DataGridPro
            rows={rows}
            rowCount={rowCount}
            loading={loading}
            disableSelectionOnClick
            pagination
            paginationModel={paginationModel}
            pageSizeOptions={[PAGE_SIZE]}
            paginationMode="server"
            onPaginationModelChange={handlePaginationModelChange}
            sortingMode="server"
            onSortModelChange={handleSortModelChange}
            {...rest}
        />
    );
};

export default FirestoreDataGrid;