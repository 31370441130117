import React, {useEffect} from 'react';
import {Typography, Stack, Box, Grid2 as Grid} from '@mui/material';
import {useFormContext} from 'react-hook-form';
import {IncidentTypes} from '@embertracking/common';
import {uniq, isEqual} from 'lodash';

import TextField from '-/form/TextField.js';
import DatePickerField from '-/form/DatePickerField.js';
import AutocompleteField from '-/form/AutocompleteField.js';
import TimeField from '-/form/TimeField.js';
import GoogleAutocompleteField from '-/form/GoogleAutocompleteField';
import UserAutocompleteField from '-/form/UserAutocompleteField';

import Map from '-/pages/incidents/Map';

const LocationMap = props => {
    const {loading} = props;
    const {watch} = useFormContext();
    const type = watch('type');
    const location = watch('location');

    if (!location) {
        return null;
    }

    return (
        <Stack sx={{mb: 2}}>
            <Map sx={{flex: 1}} incident={{type, location}} editable={!loading} mapContainerStyle={{height: 300}} />

            <Typography sx={{mt: 1, color: 'text.secondary'}} variant="caption">Drag the marker on the map to change exact location</Typography>
        </Stack>
    );
};

export default function Details(props) {
    const {loading, ...rest} = props;

    const {watch, setValue} = useFormContext();
    const location = watch('location');

    const typeOptions = Object.keys(IncidentTypes).map(key => {
        const label = IncidentTypes[key];
        return {value: key, label};
    });

    const officerInCharge = watch('officerInCharge');
    const users = watch('users') || [];
    const standby = watch('standby') || [];
    const cancelled = watch('cancelled') || [];

    useEffect(() => {
        if (!officerInCharge || !officerInCharge.length) {
            return;
        }

        const newUsers = uniq([
            ...users,
            ...officerInCharge
        ]);

        if (!isEqual(newUsers, users)) {
            setValue('users', newUsers);
        }

        if (standby.some(uid => officerInCharge.includes(uid))) {
            setValue('standby', standby.filter(uid => !officerInCharge.includes(uid)));
        }

        if (cancelled.some(uid => officerInCharge.includes(uid))) {
            setValue('cancelled', cancelled.filter(uid => !officerInCharge.includes(uid)));
        }
    }, [officerInCharge, users, standby, cancelled]);

    return (
        <Box {...rest}>
            <Grid container spacing={2}>
                {location && (
                    <Grid size={{xs: 12, md: 4}}>
                        <LocationMap {...{loading}} />
                    </Grid>
                )}

                <Grid size={{md: location ? 8 : 12}}>
                    <Grid container rowSpacing={2} columnSpacing={1}>
                        <Grid size={6}>
                            <TextField
                                fullWidth
                                label="Incident #"
                                name="incidentNumber"
                                required
                                disabled={loading}
                            />
                        </Grid>

                        <Grid size={6}>
                            <DatePickerField
                                fullWidth
                                label="Date"
                                name="date"
                                required
                                disabled={loading}
                            />
                        </Grid>
                        <Grid size={4}>
                            <TimeField
                                fullWidth
                                label="Dispatch Time"
                                name="dispatchTime"
                                disabled={loading}
                            />
                        </Grid>
                        <Grid size={4}>
                            <TimeField
                                fullWidth
                                label="Arrival Time"
                                name="arrivalTime"
                                disabled={loading}
                            />
                        </Grid>
                        <Grid size={4}>
                            <TimeField
                                fullWidth
                                label="Scene Cleared"
                                name="sceneCleared"
                                disabled={loading}
                            />
                        </Grid>

                        <Grid size={12}>
                            <GoogleAutocompleteField
                                fullWidth
                                label="Location"
                                name="location"
                                disabled={loading}
                            />
                        </Grid>

                        <Grid size={8}>
                            <AutocompleteField
                                label="Type"
                                name="type"
                                fullWidth
                                required
                                objectValue={false}
                                multiple={false}
                                disabled={loading}
                                options={typeOptions}
                            />
                        </Grid>
                        <Grid size={4}>
                            <TextField
                                fullWidth
                                label="Incident Type Code"
                                name="incidentTypeCode"
                                disabled={loading}
                            />
                        </Grid>
                        <Grid size={12}>
                            <UserAutocompleteField
                                fullWidth
                                label="Officer In Charge"
                                name="officerInCharge"
                                disabled={loading}
                            />
                        </Grid>
                        <Grid size={12}>
                            <TextField
                                fullWidth
                                label="Summary"
                                name="notes"
                                multiline
                                rows={4}
                                disabled={loading}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};