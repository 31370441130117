import React, {useContext} from 'react';
import {Chip} from '@mui/material';

import {UserContext} from '-/contexts/User';

export default function ArchivedAlert({archived, archivedAt, archivedBy: archivedByUid, ...rest}) {
    const {users = []} = useContext(UserContext);

    if (!archived) {
        return null;
    }

    let body = 'Archived';

    try {
        if (archivedAt) {
            body += ' ';
            body += archivedAt.fromNow();
        }
    } catch(e) {
        //
    }

    try {
        if (archivedByUid) {
            const user = users.find(user => user.uid === archivedByUid);
            if (user) {
                const {fullName, email} = user;

                body += ` by ${fullName || email}`;
            }
        }
    } catch(e) {
        //
    }

    return (
        <Chip color="warning" variant="outlined" label={body} {...rest} />
    );
};

