import {initializeApp} from 'firebase/app';
import {getAnalytics} from 'firebase/analytics';
import {getAuth, connectAuthEmulator} from 'firebase/auth';
import {getStorage, connectStorageEmulator} from 'firebase/storage';
import {getFunctions, connectFunctionsEmulator} from 'firebase/functions';
import {initializeFirestore, connectFirestoreEmulator} from 'firebase/firestore';
import {getVertexAI} from 'firebase/vertexai';
import StackdriverErrorReporter from 'stackdriver-errors-js';

const isDev = process.env.NODE_ENV === 'development';
const usingApiKey = process.env.REACT_APP_API_KEY;

import firebaseConfig from '-/firebase-config.json';

if (isDev && usingApiKey) {
    firebaseConfig.apiKey = process.env.REACT_APP_API_KEY;
}

export const projectId = firebaseConfig.projectId;
export const app = initializeApp(firebaseConfig);
export const db = initializeFirestore(app, {ignoreUndefinedProperties: true});
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const functions = getFunctions(app, 'northamerica-northeast1');
export const storage = getStorage(app);
export const vertexAI = getVertexAI(app);

export let errorHandler;

if (isDev) {
    if (!usingApiKey && projectId === 'embertracking-demo') {
        const localhost = '127.0.0.1';

        connectAuthEmulator(auth, `http://${localhost}:9099`);
        connectFunctionsEmulator(functions, localhost, 5001);
        connectFirestoreEmulator(db, localhost, 8082);
        connectStorageEmulator(storage, localhost, 9199);
    }
} else {
    errorHandler = new StackdriverErrorReporter();
    errorHandler.start({
        key: 'AIzaSyCdJe_S2PdJhJsLNuSDVm6FFNdoNrNcBpc',
        projectId
    });
}

export default app;