import React, {useState, useEffect, useCallback, useContext} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {useFormContext} from 'react-hook-form';
import {uniq, get} from 'lodash';
import {DateTimeRangePicker} from '@mui/x-date-pickers-pro/DateTimeRangePicker';
import {renderDigitalClockTimeView} from '@mui/x-date-pickers/timeViewRenderers';
import {useSnackbar} from 'notistack';
import {httpsCallable} from 'firebase/functions';
import moment from 'moment';
import SyncIcon from '@mui/icons-material/Sync';

import {db, functions} from '-/firebase';
import {UserContext} from '-/contexts/User';

import {populateUsers} from '-/data/utils';

import ImportResponses from '-/pages/responses/Import';

const ImportDialog = props => {
    const {date, startTime, endTime, usersKey = 'users', ...rest} = props;

    const [importing, setImporting] = useState(false);
    const [responses, setResponses] = useState([]);
    const [open, setOpen] = useState(false);
    const [importDialogOpen, setImportDialogOpen] = useState(false);
    const [dateRange, setDateRange] = useState([
        startTime ? moment(startTime) : moment(date).startOf('day'),
        endTime ? moment(endTime) : moment(date).endOf('day')
    ]);
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const importResponsesFromIAR = httpsCallable(functions, 'importResponsesFromIAR');
    const {currentUser} = useContext(UserContext);
    const dateFormat = get(currentUser, 'settings.dateFormat') || 'DD/MM/YYYY';
    const {setValue, watch} = useFormContext();
    const users = watch(usersKey) || [];
    const cancelled = watch('cancelled') || [];
    const standby = watch('standby') || [];

    useEffect(() => {
        if (startTime) {
            setDateRange([
                startTime ? moment(startTime) : moment(),
                endTime ? moment(endTime) : moment()
            ]);
        }
    }, [startTime, endTime]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleImportClose = () => {
        setImportDialogOpen(false);
    };

    const handleImportResponses = responses => {
        const {users: newUsers, cancelled: newCancelled, standby: newStandby} = responses.reduce((result, item) => {
            const {user, response} = item;
            const {uid} = user || {};
            if (!uid) {
                return result;
            }

            if (/cancelled/i.test(response)) {
                result.cancelled.push(uid);
            } else if (/standby/i.test(response)) {
                result.standby.push(uid);
            } else {
                result.users.push(uid);
            }

            return result;
        }, {
            users: [],
            cancelled: [],
            standby: []
        });

        setValue(usersKey, uniq([...users, ...newUsers]), {shouldDirty: true});
        setValue('cancelled', uniq([...cancelled, ...newCancelled]), {shouldDirty: true});
        setValue('standby', uniq([...standby, ...newStandby]), {shouldDirty: true});
        
        setImportDialogOpen(false);
    };

    const handleImport = useCallback(async() => {
        setImporting(true);

        try {
            const [startDate, endDate] = dateRange;
            const {data} = await importResponsesFromIAR({
                startDate: startDate.toDate(),
                endDate: endDate.toDate()
            });

            let {responses = []} = data;
            responses = await populateUsers(db, responses);

            setResponses(responses);

            setOpen(false);
            setImportDialogOpen(true);
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        setImporting(false);
    }, [importResponsesFromIAR, enqueueSnackbar, navigate]);

    return (
        <>
            <Button startIcon={<SyncIcon />} loading={importing} size="small" color="danger" variant="contained" onClick={() => setOpen(true)} {...rest}>
                Import Responses from IaR
            </Button>

            <ImportResponses responses={responses} open={importDialogOpen} onImport={handleImportResponses} onClose={handleImportClose} />

            <Dialog
                open={open}
                onClose={importing ? null : handleClose}
                fullWidth
                maxWidth="sm"
                disableEscapeKeyDown={importing}
            >
                <DialogTitle>Import Responses from IamResponding</DialogTitle>
                <DialogContent sx={{display: 'flex'}}>
                    <DateTimeRangePicker
                        sx={{mt: 2, flex: 1}}
                        format={`${dateFormat} HH:mm`}
                        label="Date Range"
                        ampm={false}
                        disabled={importing}
                        value={dateRange}
                        onChange={range => setDateRange(range)}
                        timeSteps={{minutes: 30}}
                        viewRenderers={{hours: renderDigitalClockTimeView}}
                    />
                </DialogContent>
                <DialogActions>
                    <Button disabled={importing} onClick={handleClose}>Cancel</Button>
                    <Button loading={importing} variant="contained" type="submit" onClick={handleImport}>Start Import</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ImportDialog;