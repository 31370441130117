import React, {useState, useCallback} from 'react';
import {Box, Alert, Button, TableContainer, Table, TableHead, TableRow, TableCell, Paper, TableBody} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {useTheme} from '@mui/material/styles';
import {FireTruck as FireTruckIcon} from '@mui/icons-material';
import {isNil, sortBy} from 'lodash';
import {useSnackbar} from 'notistack';
import {doc, updateDoc} from 'firebase/firestore';

import {db} from '-/firebase.js';

import MemberHours from '-/components/members/Hours';

import Map from './Map';

const Detail = function({row}) {
    const {uid, dispatch, users = [], apparatus = [], durations, issues = []} = row;
    const {salary, salaryOverrides: rawSalaryOverrides = {}} = durations || {};
    const {notes = ['N/A']} = dispatch || {};
    const theme = useTheme();
    const [saving, setSaving] = useState(false);
    const [hasSalaryOverrideChanges, setHasSalaryOverrideChanges] = useState(false);
    const [salaryOverrides, setSalaryOverrides] = useState(rawSalaryOverrides || {});
    const {enqueueSnackbar} = useSnackbar();

    const sortedMembers = sortBy(users, ['station', 'lastName']);

    const handleHourChange = useCallback((e, member) => {
        setHasSalaryOverrideChanges(true);

        setSalaryOverrides(prev => {
            const existingValue = prev[member.id];
            const rawValue = e.target.value;
            let newValue = rawValue === '' ? undefined : parseFloat(rawValue);

            if (isNil(newValue)) {
                const newData = {...prev};
                delete newData[member.id];
                return newData;
            }

            if (isNil(existingValue)) {
                newValue += parseFloat(salary);
            }
            
            return {
                ...prev,
                [member.id]: newValue
            };
        });
    }, [salary]);
    
    const handleSaveSalaryOverrides = useCallback(async () => {
        if (!hasSalaryOverrideChanges) {
            return;
        }

        try {
            setSaving(true);

            const ref = doc(db, 'incidents', uid);
            await updateDoc(ref, {
                'durations.salaryOverrides': salaryOverrides
            });

            enqueueSnackbar('Saved incident', {variant: 'success'});

            setHasSalaryOverrideChanges(false);
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        setSaving(false);
    }, [db, hasSalaryOverrideChanges, salaryOverrides, uid, enqueueSnackbar]);
    
    const handleResetSalaryOverrides = useCallback(() => {
        setHasSalaryOverrideChanges(false);
        
        setSalaryOverrides(rawSalaryOverrides);
    }, [rawSalaryOverrides]);

    return (
        <Box sx={{p: 2, backgroundColor: theme.palette.grey[50], display: 'flex', flexDirection: 'column'}}>
            {issues.map((issue, index) => {
                const {description, data} = issue;
                return (
                    <Alert key={`issue-${index}`} sx={{mb: 2}} severity="warning">
                        <strong>{description}</strong>
                        {JSON.stringify(data)}
                    </Alert>
                );
            })}

            <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start'}}>
                <Box sx={{flex: 1, mr: 1}}>
                    <Map incident={row} />

                    <TableContainer elevation={0} component={Paper} sx={{mr: 1, mt: 1, flex: 2}}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Dispatch Notes</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {notes.map((row, index) => (
                                    <TableRow
                                        key={`detail-note-${index}`}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        <TableCell scope="row">
                                            {row}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>

                <Box sx={{flex: 1}}>
                    <TableContainer component={Paper} sx={{mb: 1}}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                        <FireTruckIcon sx={{fontSize: 20}} />
                                        <Box sx={{ml: 1}}>Apparatus</Box>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {apparatus.length ? apparatus.map((row, index) => {
                                    const {tag} = row || {};

                                    return (
                                        <TableRow
                                            key={`detail-member-${index}`}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell scope="row">
                                                {tag}
                                            </TableCell>
                                        </TableRow>
                                    );
                                }) : (
                                    <TableRow>
                                        <TableCell sx={{textAlign: 'center'}}>
                                            No apparatus assigned
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <MemberHours
                        members={sortedMembers}
                        defaultSalary={salary}
                        salaryOverrides={salaryOverrides}
                        handleHourChange={handleHourChange}
                    />
                    
                    {hasSalaryOverrideChanges && (
                        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', mt: 1, mb: 1}}>
                            <Button disabled={saving} variant="outlined" size="small" sx={{mr: 1}} onClick={handleResetSalaryOverrides}>Reset Hour Changes</Button>
                            <LoadingButton loading={saving} variant="contained" size="small" onClick={handleSaveSalaryOverrides}>Save Hour Changes</LoadingButton>
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default Detail;