import {paperClasses} from '@mui/material/Paper';
import {alpha, darken} from '@mui/material/styles';
import {menuItemClasses} from '@mui/material/MenuItem';
import {listItemIconClasses} from '@mui/material/ListItemIcon';
import {iconButtonClasses} from '@mui/material/IconButton';
import {checkboxClasses} from '@mui/material/Checkbox';
import {listClasses} from '@mui/material/List';
import {gridClasses} from '@mui/x-data-grid';
import {tablePaginationClasses} from '@mui/material/TablePagination';

import {gray, orange} from '-/style/themePrimitives';

export const GridCellPercentColors = {
    0: '#F44336',  // Red
    5: '#F85833',
    10: '#FD6D30',
    15: '#FF812D',
    20: '#FF962A',
    25: '#FFAA28',
    30: '#FFBE25',
    35: '#FFD322',
    40: '#FFE720',
    45: '#FFEB1F',
    50: '#FFEE2D',
    55: '#FFEE43',
    60: '#FFEE58',  // Yellow
    65: '#D5E24E',
    70: '#ACD744',
    75: '#84CB3A',
    80: '#5BC030',
    85: '#33B526',
    90: '#2EA523',
    95: '#2A9520',
    100: '#2E7D32'  // Green
};

function generatePercentCellClassNames(theme) {
    const colorMap = {};

    for (let percent = 0; percent <= 100; percent += 5) {
        colorMap['& .MuiDataGrid-row .percent-cell .MuiDivider-root'] = {
            borderColor: alpha('#000', 0.1)
        };

        colorMap[`& .MuiDataGrid-row .percent-cell-${percent}`] = {
            backgroundColor: GridCellPercentColors[percent],
            color: theme.palette.getContrastText(GridCellPercentColors[percent])
        };

        colorMap[`& .MuiDataGrid-row.odd .percent-cell-${percent}`] = {
            backgroundColor: darken(GridCellPercentColors[percent], 0.04),
            color: theme.palette.getContrastText(GridCellPercentColors[percent])
        };

        colorMap[`& .MuiDataGrid-row.Mui-selected .percent-cell-${percent}`] = {
            backgroundColor: darken(GridCellPercentColors[percent], 0.07),
            color: theme.palette.getContrastText(GridCellPercentColors[percent])
        };

        colorMap[`& .MuiDataGrid-row.odd.Mui-selected .percent-cell-${percent}`] = {
            backgroundColor: darken(GridCellPercentColors[percent], 0.06),
            color: theme.palette.getContrastText(GridCellPercentColors[percent])
        };

        colorMap[`& .MuiDataGrid-row:hover .percent-cell-${percent}`] = {
            backgroundColor: darken(GridCellPercentColors[percent], 0.07),
            color: theme.palette.getContrastText(GridCellPercentColors[percent])
        };

        colorMap[`& .MuiDataGrid-row:hover .percent-cell-${percent}`] = {
            backgroundColor: darken(GridCellPercentColors[percent], 0.06),
            color: theme.palette.getContrastText(GridCellPercentColors[percent])
        };
        colorMap[`& .MuiDataGrid-row .Mui-selected.percent-cell-${percent}`] = {
            backgroundColor: darken(GridCellPercentColors[percent], 0.08),
            color: theme.palette.getContrastText(GridCellPercentColors[percent])
        };

        colorMap[`& .MuiDataGrid-row .Mui-selected.percent-cell-${percent}`] = {
            backgroundColor: darken(GridCellPercentColors[percent], 0.07),
            color: theme.palette.getContrastText(GridCellPercentColors[percent])
        };
    }

    return colorMap;
}

export const dataGridCustomizations = {
    MuiDataGrid: {
        styleOverrides: {
            root: ({theme}) => ({
                ...generatePercentCellClassNames(theme),
                '--DataGrid-overlayHeight': '300px',
                '& .MuiDataGrid-columnSeparator': {
                    color: 'transparent'
                },
                '& .MuiDataGrid-cell:focus': {
                    outline: 'none'
                },
                '& .MuiDataGrid-cell:focus-within': {
                    outline: 'none'
                },
                '& .MuiDataGrid-cell--textLeft': {
                    display: 'flex',
                    alignItems: 'center'
                },
                '& .MuiDataGrid-footerCell': {
                    fontWeight: 'bold'
                },
                '& .MuiDataGrid-cell': {
                    borderRight: '1px solid',
                    borderRightColor: alpha(gray[300], 0.1)
                },
                '& .MuiDataGrid-row--firstVisible .MuiDataGrid-cell': {
                    borderTop: 0
                },
                '& .MuiDataGrid-row--lastVisible .MuiDataGrid-cell': {
                    borderBottom: 0
                },
                '& .MuiDataGrid-cell--pinnedLeft, & .MuiDataGrid-withBorderColor': {
                    borderRight: '1px solid',
                    borderColor: (theme.vars || theme).palette.divider
                },
                '& .MuiDataGrid-footerContainer.MuiDataGrid-withBorderColor': {
                    borderTop: '1px solid',
                    borderColor: (theme.vars || theme).palette.divider
                },
                overflow: 'clip',
                borderColor: (theme.vars || theme).palette.divider,
                backgroundColor: (theme.vars || theme).palette.background.default,
                '& .MuiDataGrid-columnHeader--filledGroup .MuiDataGrid-columnHeaderTitleContainer': {
                    borderBottomWidth: 0
                },
                '& .MuiDataGrid-columnHeaderTitleContainer': {
                    borderRightWidth: 0,
                    borderBottomWidth: 0
                },
                ['& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader']: {
                    borderBottom: '1px solid',
                    backgroundColor: (theme.vars || theme).palette.background.paper,
                    borderBottomColor: `${(theme.vars || theme).palette.divider} !important`
                },
                [`& .${gridClasses.footerContainer}`]: {
                    backgroundColor: (theme.vars || theme).palette.background.paper
                },
                [`& .${checkboxClasses.root}`]: {
                    padding: theme.spacing(0.5),
                    '& > svg': {
                        fontSize: '1rem'
                    }
                },
                [`& .${tablePaginationClasses.root}`]: {
                    marginRight: theme.spacing(1),
                    '& .MuiIconButton-root': {
                        maxHeight: 32,
                        maxWidth: 32,
                        '& > svg': {
                            fontSize: '1rem'
                        }
                    }
                },
                '& .total-row': {
                    backgroundColor: (theme.vars || theme).palette.background.paper,
                    ':hover': {
                        backgroundColor: (theme.vars || theme).palette.background.paper
                    },
                    '& .MuiDataGrid-cell': {
                        fontWeight: 'bold'
                    }
                },
                '& .MuiDataGrid-row.archived': {
                    backgroundColor: alpha(orange[50], 0.7),
                    '&:hover': {
                        backgroundColor: alpha(orange[100], 0.7)
                    }
                }
            }),
            toolbarContainer: ({theme}) => ({
                borderBottom: '1px solid',
                borderBottomColor: (theme.vars || theme).palette.divider,
                '& .MuiButton-root': {
                    marginBottom: 'calc(0.5 * var(--template-spacing))'
                }
            }),
            cell: ({theme}) => ({
                borderTopColor: (theme.vars || theme).palette.divider
            }),
            menu: ({theme}) => ({
                borderRadius: theme.shape.borderRadius,
                backgroundImage: 'none',
                [`& .${paperClasses.root}`]: {
                    border: `1px solid ${(theme.vars || theme).palette.divider}`
                },

                [`& .${menuItemClasses.root}`]: {
                    margin: '0 4px'
                },
                [`& .${listItemIconClasses.root}`]: {
                    marginRight: 0
                },
                [`& .${listClasses.root}`]: {
                    paddingLeft: 0,
                    paddingRight: 0
                }
            }),
            row: ({theme}) => ({
                // '&:last-of-type': {borderBottom: `1px solid ${(theme.vars || theme).palette.divider}`},
                '&:hover, &:hover .MuiDataGrid-cell--pinnedLeft': {
                    backgroundColor: (theme.vars || theme).palette.action.hover
                },
                '&.Mui-selected': {
                    background: (theme.vars || theme).palette.action.selected,
                    '&:hover': {
                        backgroundColor: (theme.vars || theme).palette.action.hover
                    }
                }
            }),
            iconButtonContainer: ({theme}) => ({
                [`& .${iconButtonClasses.root}`]: {
                    border: 'none',
                    backgroundColor: 'transparent',
                    '&:hover': {
                        backgroundColor: alpha(theme.palette.action.selected, 0.3)
                    },
                    '&:active': {
                        backgroundColor: gray[200]
                    }
                }
            }),
            menuIconButton: () => ({
                border: 'none',
                backgroundColor: 'transparent',
                '&:hover': {
                    backgroundColor: gray[100]
                },
                '&:active': {
                    backgroundColor: gray[200]
                }
            }),
            filterForm: ({theme}) => ({
                gap: theme.spacing(1),
                alignItems: 'flex-end'
            }),
            columnsManagementHeader: ({theme}) => ({
                paddingRight: theme.spacing(3),
                paddingLeft: theme.spacing(3)
            }),
            columnHeaderTitleContainer: {
                flexGrow: 1,
                justifyContent: 'space-between'
            },
            columnHeaderDraggableContainer: {paddingRight: 2}
        }
    }
};
