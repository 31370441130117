import React, {useState, useEffect} from 'react';
import {TextField, Autocomplete, Chip, Skeleton} from '@mui/material';
import {useController} from 'react-hook-form';
import {get, capitalize, sortBy, isString} from 'lodash';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import {useSnackbar} from 'notistack';
import {collection, query, where} from 'firebase/firestore';

import {db} from '-/firebase.js';

import {getCollection} from '-/data/utils';

export default function ControlledAutocompleteField(props) {
    const {name, label, rules: rawRules, required, helperText, multiple = true, ...rest} = props;
    const fieldLabel = label !== false ? label || capitalize(name) : null;
    const rules = {...rawRules};
    if (required && !rules.required) {
        rules.required = fieldLabel ? `${fieldLabel} is required` : 'This field is required';
    }

    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const {enqueueSnackbar} = useSnackbar();
    
    useEffect(() => {
        let isSubscribed = true;

        async function fetch() {
            try {
                const ref = collection(db, 'apparatus');
                const q = query(ref, where('archived', '!=', true));
                const docs = await getCollection(db, q);

                if (isSubscribed) {
                    setOptions(sortBy(docs, 'tag'));
                }
            } catch(e) {
                enqueueSnackbar(e.message, {variant: 'error'});
            }

            if (isSubscribed) {
                setLoading(false);
            }
        }

        fetch();
        
        return () => isSubscribed = false;
    }, [db]);

    const {field, fieldState: {error}} = useController({name, rules});
    const {value, onChange, ...restField} = field;

    return (
        <Autocomplete
            loading={loading}
            {...rest}
            {...restField}
            multiple={multiple}
            value={value || []}
            options={options}
            onChange={(e, newValue) => {
                if (!multiple) {
                    if (!newValue) {
                        onChange(null);
                        return;
                    }

                    onChange(isString(newValue) ? newValue : newValue.uid);
                    setOpen(false);

                    return;
                }

                onChange(newValue.map(v => {
                    if (isString(v)) {
                        return v;
                    }

                    return v.uid;
                }));
            }}
            getOptionLabel={option => {
                if (isString(option)) {
                    option = options.find(o => o.uid === option);
                }

                return get(option, 'tag', '-');
            }}
            isOptionEqualToValue={(option, value) => {
                if (value) {
                    if (isString(value)) {
                        return option.uid === value;
                    }

                    return option.uid === value.uid;
                }

                return false;
            }}
            onInputChange={(e, newInputValue) => {
                if (e && newInputValue) {
                    setOpen(true);
                }
            }}
            // onFocus={() => {
            //     setOpen(true);
            // }}
            filterSelectedOptions
            renderInput={params => (
                <TextField
                    name={name}
                    {...params}
                    required={required}
                    error={!!error}
                    helperText={error ? error.message : helperText}
                    onClick={() => {
                        setOpen(!open);
                    }}
                    label={label}
                />
            )}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                    const {key, ...tagProps} = getTagProps({index});
                    const apparatus = typeof option === 'string' ? options.find(o => o.uid === option) : option;
                    const {tag: label} = apparatus || {};

                    return (
                        <Chip label={label ? (label || 'Unknown') : <Skeleton width={100} />} key={key} {...tagProps} />
                    );
                })
            }
            renderOption={(props, option, {inputValue}) => {
                const {key, ...optionProps} = props;
                const {tag: label} = option;
                const matches = match(label, inputValue, {insideWords: true});
                const parts = parse(label, matches);
        
                return (
                    <li key={key} {...optionProps}>
                        <div>
                            {parts.map((part, index) => (
                                <span key={index} style={{fontWeight: part.highlight ? 700 : 400}}>
                                    {part.text}
                                </span>
                            ))}
                        </div>
                    </li>
                );
            }}
            open={open}
            onClose={e => {
                const {type} = e;

                if (type === 'blur') {
                    setOpen(false);
                }
            }}
        />
    );
};
