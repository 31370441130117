import React from 'react';
import {Button, DialogTitle, DialogContent, DialogActions} from '@mui/material';
import {useForm, FormProvider} from 'react-hook-form';

import ResponsiveDialog from '-/components/ResponsiveDialog';

export default function PromptDialog({open, handleClose, onSubmit, title, prompt, submitText = 'Submit'}) {
    const methods = useForm({
        defaultValues: {}
    });
    const {handleSubmit, formState} = methods;
    const {isValid} = formState;

    return (
        <FormProvider {...methods}>
            <ResponsiveDialog open={open} onClose={handleClose}>
                {title && <DialogTitle>{title}</DialogTitle>}
                <DialogContent dividers>
                    {prompt}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button disabled={!isValid} variant="contained" onClick={handleSubmit(onSubmit)}>
                        {submitText}
                    </Button>
                </DialogActions>
            </ResponsiveDialog>
        </FormProvider>
    );
};