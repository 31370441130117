import React, {useState, useEffect} from 'react';
import {
    Box,
    Stack,
    Button,
    Typography,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    TextField,
    Card,
    CardContent,
    Alert,
    AlertTitle,
    Divider,
    alpha
} from '@mui/material';
import {CheckCircle, Cancel} from '@mui/icons-material';
import {useForm, FormProvider} from 'react-hook-form';
import ReportIcon from '@mui/icons-material/Report';
import {useSnackbar} from 'notistack';
import {httpsCallable} from 'firebase/functions';
import SendIcon from '@mui/icons-material/Send';
// import parse from 'autosuggest-highlight/parse';
// import match from 'autosuggest-highlight/match';

import {functions} from '-/firebase.js';

import {red, gray, green} from '-/style/themePrimitives';

import SelectField from '-/form/SelectField';
import EMAData from '-/data/ema.json';

// const usefulTerms = {
//     // 'contraindication': 'Anything (including a symptom or medical condition) that is a reason for a person to not receive a particular treatment or procedure because it may be harmful. For example, oral glucose is contraindicated for unconscious patients due to the risk of aspiration.',
//     // 'assessment': 'The systematic process of evaluating a patient’s condition to identify injuries, illnesses, or life-threatening issues. In EMS, it’s the foundation of patient care, guiding all subsequent actions (e.g., primary survey, secondary survey).',
//     // 'triage': 'The process of prioritizing patients based on the severity of their condition in a multiple-casualty incident, ensuring those with life-threatening injuries receive immediate care (e.g., using the START system).',
//     // 'hypoxia': 'A condition where the body or a part of it lacks sufficient oxygen, often seen in respiratory emergencies or shock, leading to symptoms like reduced consciousness or cyanosis.',
//     // 'cyanosis': 'A bluish discoloration of the skin, lips, or nails due to low oxygen levels in the blood, indicating inadequate oxygenation or circulation, common in respiratory or circulatory emergencies.',
//     // 'personal protective equipment (PPE)': 'Wearable gear like gloves, gowns, and masks used to protect responders from infectious agents or hazards, a critical step before patient contact in EMS.',
//     // 'airway': 'The passage through which air enters and leaves the lungs, a primary focus in emergencies to ensure it remains open (e.g., using head-tilt/chin-lift or jaw thrust).',
//     // 'ventilation': 'The process of moving air into and out of the lungs, either naturally or assisted (e.g., with a bag-valve-mask) to maintain oxygenation in patients with inadequate breathing.',
//     // 'shock': 'A life-threatening condition where the body’s cells don’t receive enough oxygen and nutrients due to poor circulation, often marked by rapid, weak pulse and pale, cool skin.',
//     // 'hemorrhage': 'Excessive bleeding, either external (e.g., from a wound) or internal (e.g., within the abdomen), requiring immediate control to prevent shock or death.',
//     // 'splinting': 'Immobilizing a fractured or injured limb with a rigid device to reduce pain, prevent further damage, and stabilize it for transport.',
//     // 'aspiration': 'The inhalation of foreign material (e.g., vomit, food) into the lungs, a risk in unconscious patients that can obstruct airways or cause infection.',
//     // 'hypothermia': 'A dangerous drop in body temperature below 35°C (95°F) due to cold exposure, leading to shivering, confusion, and, in severe cases, slowed breathing and pulse.',
//     // 'tourniquet': 'A device applied tightly around a limb proximal to a severe bleed to stop arterial bleeding when direct pressure fails, used in life-threatening hemorrhage.'
    
//     // 'heat stroke': 'A severe heat-related illness where body temperature exceeds 40°C (104°F), with stopped sweating and altered LOC, requiring immediate cooling and oxygen.',
//     // 'mechanism of injury': 'The way an injury occurs (e.g., fall, gunshot), used during scene assessment to predict potential injuries and guide treatment, such as suspecting spinal damage from a high fall.',
//     // 'flail chest': 'A condition where multiple rib fractures cause a section of the chest wall to move independently, impairing breathing, often seen as shallow respirations or paradoxical movement.',
//     // 'level of consciousness (LOC)': 'A measure of a patient’s alertness and responsiveness (e.g., using AVPU: Alert, Verbal, Pain, Unresponsive), critical for assessing brain function in emergencies.',
//     // 'prolapsed cord': 'A childbirth complication where the umbilical cord slips into the birth canal ahead of the baby, risking fetal oxygen deprivation if compressed, requiring urgent intervention.',
//     // 'narcotic overdose': 'A medical emergency from excessive opioid use, characterized by lowered respiratory rate, pinpoint pupils, and reduced LOC, often requiring ventilatory support.',
//     // 'Glasgow Coma Scale': 'A tool to assess a patient’s neurological status based on eye, verbal, and motor responses, scored from 3 (unresponsive) to 15 (fully alert), used in head injuries.',
//     // 'SAMPLE': 'A mnemonic for patient history-taking in EMS: Signs/Symptoms, Allergies, Medications, Past medical history, Last oral intake, Events leading to injury, part of the secondary survey.',
//     // 'OPQRST': 'A mnemonic for assessing pain: Onset, Provocation, Quality, Radiation, Severity, Time, used to detail a patient’s chief complaint in the secondary survey.',
//     // 'AVPU': 'A quick scale to assess level of consciousness: Alert (fully awake), Verbal (responds to voice), Pain (responds to pain), Unresponsive (no response), used in the primary survey.',
//     // 'rapid body survey (RBS)': 'A quick physical check during the primary survey to identify life-threatening injuries (e.g., severe bleeding), not a full examination, focused on immediate threats.',
// };

// const highlightMatches = text => {
//     const wordsToHighlight = Object.keys(usefulTerms);
//     let parts = [];

//     // Iterate through each word to find matches
//     wordsToHighlight.forEach(word => {
//         const matches = match(text, word, {caseSensitive: false});
//         if (matches.length > 0) {
//             const parsed = parse(text, matches);
//             parts = parts.length ? parts : parsed;
//         }
//     });

//     if (parts.length === 0) {
//         return [{text, highlight: false}];
//     }

//     return parts;
// };

// const HighlightedText = ({text}) => {
//     const parts = highlightMatches(text);

//     return (
//         <Typography component="span">
//             {parts.map((part, index) => (
//                 <Tooltip key={index} title={usefulTerms[part.text] || part.text} placement="top">
//                     <span
//                         style={{
//                             backgroundColor: part.highlight ? '#ffeb3b' : 'transparent',
//                             fontWeight: part.highlight ? 'bold' : 'normal'
//                         }}
//                     >
//                         {part.text}
//                     </span>
//                 </Tooltip>
//             ))}
//         </Typography>
//     );
// };

export const Question = ({question: data, title, showChapter = false, progress, finishText = 'Finish', onSubmit, onFinish, ...rest}) => {
    const {chapter, question, additionalInfo, options, correctAnswer, explanation} = data || getRandomQuestion();

    const [userAnswer, setUserAnswer] = useState(null);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [report, setReport] = useState('');
    const [showingReportField, setShowingReportField] = useState(false);
    const [reporting, setReporting] = useState(false);
    const {enqueueSnackbar} = useSnackbar();
    const reportQuizQuestion = httpsCallable(functions, 'reportQuizQuestion');

    const isAnswered = userAnswer !== null;
    const isCorrect = isAnswered && parseInt(userAnswer) === correctAnswer;

    const handleSetUserAnswer = answer => {
        if (isSubmitted) {
            return;
        }
        
        setUserAnswer(answer);
    };

    const handleSubmit = () => {
        setIsSubmitted(true);

        if (onSubmit) {
            onSubmit(userAnswer);
        }
    };

    const handleFinish = () => {
        if (onFinish) {
            onFinish();
        }

        setUserAnswer(null);
        setIsSubmitted(false);
    };

    const handleReport = async() => {
        setReporting(true);

        try {
            await reportQuizQuestion({question: data, report});
        } catch(e) {
            console.warn(e);
        }

        enqueueSnackbar('Report submitted', {variant: 'success'});

        setReporting(false);
        setShowingReportField(false);
        setReport('');
    };

    const {sx, ...other} = rest;

    return (
        <Card sx={{mb: 1, ...sx}} variant="outlined" {...other}>
            <CardContent>
                {title && (
                    <>
                        <Typography variant="h6" sx={{mb: 1, fontWeight: 'normal', color: 'text.secondary'}}>
                            {title}
                        </Typography>

                        <Divider sx={{mb: 1}} />
                    </>
                )}

                {(showChapter && chapter) || progress ? (
                    <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2}}>
                        {showChapter && chapter && (
                            <Typography sx={{color: 'text.secondary', fontSize: 14}}>
                                Chapter {chapter.chapterNumber}: {chapter.chapterTitle}
                            </Typography>
                        )}

                        {progress}
                    </Box>
                ) : null}

                <Typography variant="h6" sx={{mb: 1}}>
                    {question}
                </Typography>

                {additionalInfo && additionalInfo.map((additionalInfo, index) => (
                    <Typography key={index} variant="body2" sx={{ml: 2, mb: 1}}>
                        {additionalInfo}
                    </Typography>
                ))}

                <FormControl component="fieldset" sx={{width: '100%'}}>
                    <RadioGroup
                        value={userAnswer}
                        onChange={e => handleSetUserAnswer(e.target.value)}
                    >
                        {options.map((option, index) => {
                            const isSelected = userAnswer === index.toString();
                            const isCorrectAnswer = index === correctAnswer;
                            let icon = <Radio />;
                            let color = 'inherit';
                            let backgroundColor = isSelected ? gray[100] : 'transparent';

                            if (isSubmitted) {
                                if (isCorrectAnswer) {
                                    color = green[500];
                                    backgroundColor = alpha(green[100], 0.6);
                                    icon = <CheckCircle sx={{color, m: '9px'}} />;
                                } else if (isSelected && !isCorrect) {
                                    color = red[400];
                                    backgroundColor = alpha(red[50], 0.5);
                                    icon = <Cancel sx={{color, m: '9px'}} />;
                                }
                            }

                            return (
                                <FormControlLabel
                                    key={index}
                                    value={index.toString()}
                                    control={icon}
                                    label={option}
                                    sx={{
                                        '& .MuiFormControlLabel-label': {
                                            fontSize: '1rem',
                                            color: isSubmitted && (isCorrectAnswer || isSelected) ? color : 'inherit',
                                            fontWeight: isSubmitted && isCorrectAnswer ? 'bold' : 'normal'
                                        },
                                        backgroundColor,
                                        borderRadius: 1,
                                        p: 0.5,
                                        my: 0.5
                                    }}
                                />
                            );
                        })}
                    </RadioGroup>
                </FormControl>

                {isSubmitted && (
                    <Alert
                        severity={isAnswered ? (isCorrect ? 'success' : 'error') : 'info'}
                        sx={{mt: 2}}
                    >
                        <AlertTitle>
                            {isAnswered ? (isCorrect ? 'Correct!' : 'Incorrect') : 'Answer'}
                        </AlertTitle>
                        <Stack direction="column" spacing={2} alignItems="stretch">
                            <Typography>
                                {explanation ? explanation : (
                                    <>
                                        The correct answer is <strong>{options[correctAnswer]}</strong>
                                    </>
                                )}
                            </Typography>
                            <Stack direction="row" spacing={1} sx={{flex: 1}} alignItems="stretch" justifyContent="flex-end">
                                {showingReportField && (
                                    <TextField
                                        label="Reason for report"
                                        value={report}
                                        onChange={e => setReport(e.target.value)}
                                        sx={{flex: 1}}
                                        disabled={reporting}
                                    />
                                )}
                                <Button
                                    variant="contained"
                                    loading={reporting}
                                    startIcon={showingReportField ? <SendIcon /> : <ReportIcon />}
                                    onClick={() => showingReportField ? handleReport() : setShowingReportField(true)}
                                    sx={{ml: '8px !important'}}
                                >
                                    {showingReportField ? 'Submit Report' : 'Report'}
                                </Button>
                                {showingReportField && (
                                    <Button
                                        variant="outlined"
                                        onClick={() => setShowingReportField(false)}
                                        sx={{ml: '8px !important'}}
                                        disabled={reporting}
                                    >
                                        Cancel
                                    </Button>
                                )}
                            </Stack>
                        </Stack>
                    </Alert>
                )}

                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    sx={{width: '100%', mt: 2}}
                    onClick={isSubmitted ? handleFinish : handleSubmit}
                >
                    {isSubmitted ? finishText : 'Submit Answer'}
                </Button>
            </CardContent>
        </Card>
    );
};

export const getRandomQuestion = (chapterFilter = 'all', opts) => {
    const {askedQuestions, setAskedQuestions} = opts || {};
    const {chapters} = EMAData;

    let questionPool = [];
    if (chapterFilter === 'all') {
        questionPool = chapters.flatMap(chapter => {
            return chapter.questions.map(question => ({
                ...question,
                chapter,
                uniqueId: `${chapter.chapterNumber}-${question.question}` // Unique identifier
            }));
        });
    } else {
        const chapter = chapters.find(ch => ch.chapterNumber === parseInt(chapterFilter));
        questionPool = (chapter ? chapter.questions : []).map(question => ({
            ...question,
            chapter,
            uniqueId: `${chapter.chapterNumber}-${question.question}`
        }));
    }

    let availableQuestions = questionPool;
    if (askedQuestions) {
        availableQuestions = questionPool.filter(question => !askedQuestions.has(question.uniqueId));
    }

    if (availableQuestions.length === 0) {
        if (setAskedQuestions) {
            setAskedQuestions(new Set());
        }

        return questionPool[Math.floor(Math.random() * questionPool.length)] || null;
    }

    const randomIndex = Math.floor(Math.random() * availableQuestions.length);
    return availableQuestions[randomIndex];
};

const QuizPage = () => {
    const {chapters} = EMAData;

    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const [totalAnswers, setTotalAnswers] = useState(0);
    const [correctAnswers, setCorrectAnswers] = useState(0);
    const [askedQuestions, setAskedQuestions] = useState(new Set());

    const methods = useForm({
        defaultValues: {
            chapter: 'all'
        },
        mode: 'onChange'
    });

    const {watch} = methods;
    const selectedChapter = watch('chapter');

    const opts = {
        askedQuestions,
        setAskedQuestions
    };

    const handleSubmit = userAnswer => {
        if (userAnswer !== null) {
            setTotalAnswers(prev => prev + 1);
            if (parseInt(userAnswer) === selectedQuestion.correctAnswer) {
                setCorrectAnswers(prev => prev + 1);
            }
        }
    };

    const handleFinish = () => {
        const newQuestion = getRandomQuestion(selectedChapter, opts);
        if (newQuestion) {
            setAskedQuestions(prev => new Set(prev).add(newQuestion.uniqueId));
            setSelectedQuestion(newQuestion);
        }
    };

    useEffect(() => {
        const newQuestion = getRandomQuestion(selectedChapter, opts);
        if (newQuestion) {
            setAskedQuestions(prev => new Set(prev).add(newQuestion.uniqueId));
            setSelectedQuestion(newQuestion);
        } else {
            setSelectedQuestion(null);
        }
    }, [selectedChapter]);

    if (!selectedQuestion) {
        return (
            <Typography>No questions available for the selected chapter.</Typography>
        );
    }

    const progress = totalAnswers > 0 && (
        <Typography sx={{fontSize: 14, color: gray[600]}}>
            Answered: <strong>{totalAnswers}</strong> | Correct: <strong>{correctAnswers}</strong>
            {totalAnswers > 0 && (
                <> | <strong>{Math.round((correctAnswers / totalAnswers) * 100)}%</strong></>
            )}
        </Typography>
    );

    return (
        <FormProvider {...methods}>
            <Stack sx={{mb: 1}} direction={{xs: 'column', sm: 'row'}} spacing={2} justifyContent="space-between" alignItems={{xs: 'stretch', sm: 'center'}}>
                <Typography variant="h5" sx={{flex: 1}}>
                    First Responder Quiz
                </Typography>

                <SelectField
                    name="chapter"
                    label="Select Chapter"
                    options={[
                        {value: 'all', label: 'All Chapters'},
                        ...chapters.map(chapter => ({
                            value: chapter.chapterNumber.toString(),
                            label: `Chapter ${chapter.chapterNumber}: ${chapter.chapterTitle}`
                        }))
                    ]}
                    sx={{minWidth: 300, flex: {xs: '1 1 auto', sm: '0 0 auto'}}}
                    onChange={e => {
                        methods.setValue('chapter', e.target.value);
                    }}
                />
            </Stack>

            <Question
                question={selectedQuestion}
                showChapter
                progress={progress}
                finishText="Next Question"
                onSubmit={handleSubmit}
                onFinish={handleFinish}
            />
        </FormProvider>
    );
};

export default QuizPage;