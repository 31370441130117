import React from 'react';
import {Typography} from '@mui/material';

import TasksGrid from '-/pages/tasks/TasksGrid';

export default function() {
    return (
        <>
            <Typography variant="h5" gutterBottom>Tasks</Typography>

            <TasksGrid />
        </>
    );
};