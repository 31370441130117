import React, {useContext, useCallback} from 'react';
import moment from 'moment';

import {gridClasses} from '@mui/x-data-grid-pro';
import {Box, Typography, AvatarGroup, useMediaQuery, alpha} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {green, red} from '@mui/material/colors';
import {get, isFinite} from 'lodash';
import {useNavigate} from 'react-router-dom';
import {TrainingTypes, TrainingDisciplines} from '@embertracking/common';

import {UserContext} from '-/contexts/User';

import UserAvatar from '-/components/UserAvatar';
import SearchableDataGrid from '-/components/SearchableDataGrid';

import {hasPermission} from '-/data/utils';
import {getStandardForKey} from '-/data/standards';

import SmallChip from '-/components/SmallChip';

const Grid = props => {
    const {training, loading, columns = [], editMode,  ...rest} = props;
    const navigate = useNavigate();
    
    const {currentUser, users} = useContext(UserContext);
    const dateFormat = get(currentUser, 'settings.dateFormat') || 'DD/MM/YYYY';
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    
    const handleRowClick = useCallback((params, e) => {
        if (!hasPermission(currentUser, 'training.write')) {
            return;
        }
        
        const {row} = params;
        const {metaKey} = e;

        const url = `/training/${row.id}`;
        if (metaKey) {
            window.open(url);
            return;
        }

        navigate(url);
    }, [navigate]);
    
    const defaultColumns = [
        {
            field: 'date',
            headerName: 'Date',
            sortable: true,
            minWidth: 110,
            maxWidth: 110,
            valueGetter: rawValue => {
                let value = rawValue;
                if (rawValue && rawValue.toDate) {
                    value = rawValue.toDate();
                }

                return value;
            },
            valueFormatter: value => {
                return value ? moment(value).format(dateFormat) : '-';
            },
            renderCell: ({formattedValue}) => {
                return (
                    <Box sx={{display: 'flex', p: 1, flexDirection: 'column'}}>
                        {formattedValue}
                    </Box>
                );
            }
        },
        {
            field: 'raw.type',
            headerName: 'Details',
            sortable: false,
            flex: 1,
            minWidth: 350,
            valueGetter: (value, row) => {
                const {title, raw = {}} = row || {};
                const {type, description} = raw || {};

                return `${title || type} ${description}`;
            },
            renderCell: params => {
                const {row} = params || {};
                const {title, description, raw = {}, types = [], jprs = [], issues = []} = row || {};
                const {type, rawDescription} = raw || {};

                let chips = [
                    // ...types.map((trainingType, index) => {
                    //     const {id, name = '-'} = trainingType || {};

                    //     return (
                    //         <SmallChip key={`${id}-${index}`} label={name} />
                    //     );
                    // }),
                    // ...jprs.map((jprKey, index) => {
                    //     const {shortTitle} = getStandardForKey(jprKey);

                    //     return (
                    //         <SmallChip variant="outlined" key={`${jprKey}-${index}`} label={shortTitle || jprKey} />
                    //     );
                    // })
                ];

                const issueChips = issues.map((issue, index) => {
                    const {description} = issue || {};

                    return (
                        <SmallChip variant="warning" key={`${description}-${index}`} label={description} />
                    );
                });

                const label = title || type;
                const summary = description || rawDescription;

                return (
                    <Box sx={{display: 'flex', p: 0.5, flexDirection: 'column'}}>
                        {label && <Typography variant="subtitle2">{label}</Typography>}
                        {summary && <Typography variant="body2" color="textSecondary" sx={{fontSize: 10}}>{summary}</Typography>}
                    
                        {chips.length > 0 && (
                            <Box sx={{mt: 0.5, display: 'flex', flexWrap: 'wrap'}}>
                                {chips}
                            </Box>
                        )}

                        {issueChips.length > 0 && (
                            <Box sx={{mt: 0.5, display: 'flex', flexWrap: 'wrap'}}>
                                {issueChips}
                            </Box>
                        )}
                    </Box>
                );
            }
        },
        {
            field: 'type',
            headerName: 'Type',
            width: 150,
            sortable: true,
            valueGetter: value => {
                return value && TrainingTypes[value];
            },
            renderCell: ({value}) => {
                return (
                    <Box sx={{display: 'flex', p: 1, flexDirection: 'column'}}>
                        {value}
                    </Box>
                );
            }
        },
        {
            field: 'discipline',
            headerName: 'Discipline',
            width: 150,
            sortable: true,
            valueGetter: value => {
                return value && TrainingDisciplines[value];
            },
            renderCell: ({value}) => {
                return (
                    <Box sx={{display: 'flex', p: 1, flexDirection: 'column'}}>
                        {value}
                    </Box>
                );
            }
        },
        
        // {
        //     field: 'jprs',
        //     headerName: 'JPRs',
        //     editable: !!editMode,
        //     width: 200,
        //     sortable: false,
        //     renderCell: params => {
        //         const {value = []} = params || {};
        //         const chips = value.map((jprKey, index) => {
        //             const {shortTitle} = getStandardForKey(jprKey);

        //             return (
        //                 <SmallChip key={`${jprKey}-${index}`} label={shortTitle || jprKey} />
        //             );
        //         });

        //         return (
        //             <Box sx={{display: 'flex', pt: 1, flexWrap: 'wrap'}}>
        //                 {chips}
        //             </Box>
        //         );
        //     }
        // },
        // {
        //     field: 'types',
        //     headerName: 'Types',
        //     editable: !!editMode,
        //     sortable: false,
        //     width: 120,
        //     renderCell: params => {
        //         const {value = []} = params || {};

        //         const chips = value.map((trainingType, index) => {
        //             const {id, name = '-'} = trainingType || {};

        //             return (
        //                 <SmallChip key={`${id}-${index}`} sx={{mr: 1, mb: 1}} label={name} />
        //             );
        //         });

        //         return (
        //             <Box sx={{display: 'flex', pt: 1, flexWrap: 'wrap'}}>
        //                 {chips}
        //             </Box>
        //         );
        //     }
        // },
        {
            field: 'instructors',
            headerName: 'Instructors',
            editable: !!editMode,
            sortable: false,
            minWidth: 150,
            renderCell: params => {
                const {value = []} = params || {};

                const avatars = value.map((userId, index) => {
                    const user = typeof userId === 'string' ? users.find(user => user.uid === userId) : (userId || {});
                    const {id} = user || {};

                    return (
                        <UserAvatar key={`${id}-${index}`} user={user} />
                    );
                });

                return (
                    <Box sx={{display: 'flex', alignItems: 'flex-start', height: '100%'}}>
                        <AvatarGroup spacing="small" max={4}>
                            {avatars}
                        </AvatarGroup>
                    </Box>
                );
            }
        },
        {
            field: 'hours',
            headerName: 'Duration',
            valueGetter: rawValue => {
                if (!rawValue) {
                    return;
                }

                return parseFloat(rawValue);
            },
            renderCell: ({value}) => {
                if (!value) {
                    return '';
                }
                
                const hours = Math.floor(value);
                const minutes = Math.round((value - hours) * 60);
                if (!isFinite(hours) || !isFinite(minutes)) {
                    return '';
                }

                const formattedValue = new Intl.DurationFormat('en', {style: 'narrow'}).format({hours, minutes});

                return (
                    <Box sx={{display: 'flex', p: 1, flexDirection: 'column'}}>
                        {formattedValue || value}
                    </Box>
                );
            }
        },
        {
            field: 'members',
            headerName: 'Attendance',
            width: 110,
            valueGetter:rawValue => {
                return (rawValue || []).length;
            },
            renderCell: ({value}) => {
                return (
                    <Box sx={{display: 'flex', p: 1, flexDirection: 'column'}}>
                        {value}
                    </Box>
                );
            }
        }
    ];

    return (
        <SearchableDataGrid
            loading={loading}
            initialState={{
                sorting: {
                    sortModel: [
                        {field: 'date', sort: 'desc'}
                    ]
                },
                columns: {
                    columnVisibilityModel: {
                        type: false,
                        types: !isSmall,
                        instructors: !isSmall,
                        hours: !isSmall,
                        members: !isSmall
                    }
                }
            }}
            getRowHeight={() => 'auto'}
            onRowClick={handleRowClick}
            rows={training}
            columns={[...columns, ...defaultColumns].map(column => ({...column, disableColumnMenu: true}))}
            slotProps={{
                loadingOverlay: {
                    variant: 'linear-progress',
                    noRowsVariant: 'skeleton'
                }
            }}
            pageSizeOptions={[]}
            disableRowSelectionOnClick
            getRowClassName={params => {
                const {row} = params;
                const {absent = false, syncedToVS = false} = row || {};
                const classNames = [];

                if (syncedToVS) {
                    classNames.push('vs-synced');
                }

                if (absent === true) {
                    classNames.push('absent');
                }

                return classNames.join(' ');
            }}
            sx={{
                [`& .${gridClasses.cell}`]: {
                    py: 1
                },
                [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
                    outline: 'none'
                },
                [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
                    outline: 'none'
                },
                '& .vs-synced': {
                    backgroundColor: `${alpha(green[500], 0.2)}`,
                    borderLeft: `4px solid ${green[500]}`
                },
                '& .MuiDataGrid-row:hover.vs-synced': {
                    backgroundColor: `${alpha(green[500], 0.3)}`,
                    borderLeft: `4px solid ${green[500]}`
                },
                '& .absent': {
                    backgroundColor: `${alpha(red[100], 0.1)}`,
                    '&:hover': {
                        backgroundColor: `${alpha(red[100], 0.15)}`
                    },
                    '& .MuiDataGrid-cell > *': {
                        opacity: 0.2
                    }
                }
            }}
            {...rest}
        />
    );
};

export default Grid;