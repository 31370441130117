import React, {useState, useContext, useEffect, useCallback, useMemo} from 'react';
import {Button, Stack, Box, Typography, Grid2 as Grid, Divider, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import {useForm, FormProvider} from 'react-hook-form';
import {get, pickBy} from 'lodash';
import {LoadingButton} from '@mui/lab';
import {TaskStatuses, TaskFrequencies} from '@embertracking/common';

import {UserContext} from '-/contexts/User';

import TextField from '-/form/TextField';
import DatePickerField from '-/form/DatePickerField';
import UserAutocompleteField from '-/form/UserAutocompleteField';
import SelectField from '-/form/SelectField';
import CheckboxField from '-/form/CheckboxField';
import ToggleButtonField from '-/form/ToggleButtonField';
import FileField from '-/form/FileField';

import ResponsiveDialog from '-/components/ResponsiveDialog';

import {hasPermission, ensureJSDates} from '-/data/utils';

export default function TaskDialog({onSubmit: onUpdate, onDelete, item, open, handleClose}) {
    const defaultValues = useMemo(() => {
        return {
            name: '',
            notes: '',
            assignee: null,
            date: null,
            dateType: 'month',
            recurring: false,
            frequency: null,
            status: 'OPEN',
            ...pickBy(item)
        };
    }, [item]);

    const [loading, setLoading] = useState(false);
    const methods = useForm({
        defaultValues,
        mode: 'onChange'
    });
    
    const {reset, handleSubmit, watch} = methods;
    const recurring = watch('recurring');
    const status = watch('status');
    const dateType = watch('dateType');
    const {currentUser} = useContext(UserContext);
    const dateFormat = get(currentUser, 'settings.dateFormat') || 'DD/MM/YYYY';

    const isComplete = status === 'COMPLETE';

    useEffect(() => {
        reset({
            name: '',
            notes: '',
            assignee: null,
            date: null,
            dateType: 'month',
            recurring: false,
            frequency: null,
            status: 'OPEN',
            ...pickBy(item)
        });
    }, [item]);

    const handleDelete = async () => {
        setLoading();

        const result = await onDelete(item);
        if (result) {
            handleClose();
            reset(defaultValues);
        }

        setLoading(false);
    };

    const onClose = () => {
        handleClose();
        reset(defaultValues);
    };

    const onSubmit = useCallback(async data => {
        setLoading(true);

        const result = await onUpdate(ensureJSDates(data));
        if (result) {
            handleClose();
        }

        reset(defaultValues);

        setLoading(false);
    }, [handleClose, defaultValues, onUpdate]);

    const dueDateViews = dateType === 'year' ? ['year'] : dateType === 'month' ? ['month', 'year'] : ['year', 'month', 'day'];
    const dueDateFormat = dateType === 'year' ? 'YYYY' : dateType === 'month' ? 'MM/YYYY' : dateFormat;

    const allowedTaskFrequencies = [
        ...['day'].includes(dateType) ? ['WEEKLY'] : [],
        ...['day', 'month'].includes(dateType) ? ['MONTHLY'] : [],
        'YEARLY'
    ];
    const recurringFrequencyOptions = allowedTaskFrequencies.map(value => ({label: TaskFrequencies[value], value}));

    return (
        <FormProvider {...methods}>
            <ResponsiveDialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    noValidate: true,
                    onSubmit: handleSubmit(onSubmit)
                }}
            >
                <DialogTitle>{item ? 'Update Task' : 'Add New Task'}</DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid size={{xs: 12, sm: 6}}>
                            <TextField
                                required
                                name="name"
                                label="Name"
                                placeholder={'Organize SCBA fit test'}
                                fullWidth
                                disabled={loading || isComplete}
                            />
                        </Grid>
                        <Grid size={{xs: 12, sm: 6}}>
                            <UserAutocompleteField
                                name="assignee"
                                label="Assignee"
                                multiple={false}
                                fullWidth
                                disabled={loading || isComplete}
                            />
                        </Grid>
                    </Grid>
                    <TextField
                        sx={{mt: 2}}
                        name="notes"
                        label="Notes"
                        multiline
                        placeholder={'Normally organzed using Brogan.'}
                        fullWidth
                        disabled={loading || isComplete}
                    />
                    {(item && hasPermission(currentUser, 'tasks.write')) && (
                        <SelectField
                            sx={{mt: 2}}
                            name="status"
                            label="Status"
                            options={Object.keys(TaskStatuses).map(value => ({label: TaskStatuses[value], value}))}
                            fullWidth
                            disabled={loading}
                        />
                    )}

                    <Typography variant="h6" sx={{mt: 2}}>Date</Typography>
                    <Divider sx={{mb: 2}} />

                    <Box sx={{display: 'flex', alignItems: 'stretch', mt: 2}}>
                        <ToggleButtonField
                            name="dateType"
                            label="Date Type"
                            exclusive
                            sx={{mr: 1}}
                            options={[
                                {label: 'Day', value: 'day'},
                                {label: 'Month', value: 'month'},
                                {label: 'Year', value: 'year'}
                            ]}
                            disabled={loading || isComplete}
                        />

                        <DatePickerField
                            sx={{flex: 1}}
                            name="date"
                            label="Due Date"
                            fullWidth
                            format={dueDateFormat}
                            views={dueDateViews}
                            disabled={loading || isComplete}
                            required={recurring}
                        />
                    </Box>

                    {!isComplete && (
                        <Stack direction="row" alignItems="center" sx={{mt: 2}}>
                            <CheckboxField
                                name="recurring"
                                label="Recurring"
                                disabled={loading || isComplete}
                            />
                            {recurring && (
                                <SelectField
                                    sx={{ml: 2}}
                                    name="frequency"
                                    label="Frequency"
                                    options={recurringFrequencyOptions}
                                    fullWidth
                                    required
                                    disabled={loading || isComplete}
                                />
                            )}
                        </Stack>
                    )}

                    <Typography variant="h6" sx={{mt: 2}}>Files</Typography>
                    <Divider sx={{mb: 2}} />

                    <FileField name="files" disabled={loading} />
                </DialogContent>
                <DialogActions>
                    {(item && onDelete) && (
                        <>
                            <Button onClick={handleDelete} disabled={loading}>Delete Task</Button>
                            <Box sx={{flex: 1}} />
                        </>
                    )}
                    <Button onClick={onClose} disabled={loading}>Cancel</Button>
                    <LoadingButton variant="contained" type="submit" loading={loading} disabled={loading}>{item ? 'Update' : 'Add'} Task</LoadingButton>
                </DialogActions>
            </ResponsiveDialog>
        </FormProvider>
    );
};