import React, {useState, useContext} from 'react';
import {Box, Tooltip, Stack, Typography, TextField, Autocomplete} from '@mui/material';
import {get, sortBy} from 'lodash';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import {doc, updateDoc} from 'firebase/firestore';

import {db} from '-/firebase';

import {UserContext} from '-/contexts/User';

import modules from '../data/skills.json';

const errorTypes = [
    {color: '#ff0000', type: 'critical', deduction: 26, key: 'C', label: 'Critical Error, this is a deduction of 26% - One Critical error the student will be unsuccessful'},
    {color: '#ffff00', type: 'major', deduction: 13, key: 'M', label: 'Major Error, this is a deduction of 13% - Two Major errors the student will be unsuccessful'},
    {color: '#03ff00', type: 'general', deduction: 6, key: 'G', label: 'General Error, this is a deduction of 6% - Five General errors the student will be unsuccessful'}
];

const Unit = ({unit, users = []}) => {
    if (!unit) {
        return null;
    }

    const sortedUsers = sortBy(users, 'lastName');

    const {module, title, nfpa, jibcKey, tasks = []} = unit;
    const {title: moduleTitle, level} = module || {};

    const allSkillKeys = tasks.reduce((acc, task, taskIndex) => {
        const {skills = []} = task;
        const taskSkillKeys = skills.map((skill, index) => `skills.${level}.jibcSkills.${jibcKey}.${taskIndex}.${index}`);

        return [...acc, ...taskSkillKeys];
    }, []);

    const allSkillKeyErrorTypes = tasks.reduce((acc, task, taskIndex) => {
        const {skills = []} = task;
        
        skills.forEach((skill, index) => {
            const {errorType} = skill;
            const key = `skills.${level}.jibcSkills.${jibcKey}.${taskIndex}.${index}`;
            
            acc[key] = errorType;
        });

        return acc;
    }, {});

    const userColumnBackgroundColor = sortedUsers.reduce((acc, user) => {
        const deduction = allSkillKeys.reduce((acc, key) => {
            const value = get(user, key);

            if (value === false) {
                const errorType = allSkillKeyErrorTypes[key];
                if (errorType === 'critical') {
                    return acc + 26;
                } else if (errorType === 'major') {
                    return acc + 13;
                } else if (errorType === 'general') {
                    return acc + 6;
                }
            }

            return acc;
        }, 0);

        if (deduction >= 26) {
            return {
                ...acc,
                [user.uid]: '#ff0000'
            };
        }

        return acc;
    }, {});

    const handleUserSkillReset = async uid => {
        const user = users.find(user => user.uid === uid);
        if (!user) {
            return;
        }

        const values = allSkillKeys.map(key => {
            return get(user, key);
        });

        const allTrue = values.every(value => value === true);
        const value = allTrue ? null : true;

        const update = allSkillKeys.reduce((acc, key) => {
            return {
                ...acc,
                [key]: value
            };
        }, {});

        await updateDoc(doc(db, 'users', uid), update);
    };
    
    const handleUserSkillChange = async(uid, key, value) => {
        let newValue = !value;
        if (value === false) {
            newValue = null;
        }
        
        await updateDoc(doc(db, 'users', uid), {
            [key]: newValue
        });
    };

    return (
        <Stack direction="column" sx={{border: '1px solid #000', overflow: 'hidden', borderRadius: 0.5, '& > *': {borderBottom: '1px solid #000'}}}>
            <Stack direction="row" sx={{p: 0.5, backgroundColor: '#b4c6e7'}}>
                <Typography sx={{flex: 1, fontSize: '0.8rem'}}>{nfpa && `NFPA ${nfpa}`}</Typography>
                <Typography sx={{flex: 1, textAlign: 'right', fontSize: '0.8rem'}}>Skills Evaluation Item {jibcKey}</Typography>
            </Stack>
            <Typography sx={{p: 0.5, textAlign: 'center', fontSize: '0.8rem', backgroundColor: '#dadada'}}>{moduleTitle}</Typography>
            {/* <Box sx={{p: 0.5, backgroundColor: '#dadada'}}>
                <Stack direction="column" spacing={0.5}>
                    <Typography sx={{textAlign: 'center', fontSize: '0.85em', fontWeight: 'bold'}}>Instructions for Evaluator:</Typography>
                    <Typography sx={{fontSize: '0.7rem'}}>The skills are weighted as Critical, Major and General. If you receive a:</Typography>
                    <Stack direction="column" spacing={0.5} sx={{pl: 4, pr: 1}}>
                        {errorTypes.map(errorType => {
                            const {color, key, label} = errorType;

                            return (
                                <Stack direction="row" key={`errorType${key}-errorType${key}`}>
                                    <Box sx={{width: 10, height: 15, backgroundColor: color, mr: 1}}></Box>
                                    <Typography sx={{fontSize: '0.7rem'}}>{label}</Typography>
                                </Stack>
                            );
                        })}
                    </Stack>

                    {scenario && <Typography sx={{fontSize: '0.7rem'}}>{scenario}</Typography>}

                    <Typography sx={{textAlign: 'center', fontSize: '0.85em', fontWeight: 'bold'}}>Evaluator’s Instructions to Candidate:</Typography>
                    <Stack direction="column" spacing={0}>
                        {instructions.map((instruction, index) => (
                            <Typography key={`unit${key}-instruction${index}`} sx={{fontSize: '0.7rem'}}>{instruction}</Typography>
                        ))}
                        <Typography sx={{fontSize: '0.7rem'}}>This is NOT a timed event, but the candidate must successfully complete the following requirements in a prompt and accurate manner and successfully <strong>complete with a total score of 75%</strong>.</Typography>
                    </Stack>

                    <Typography sx={{textAlign: 'center', fontSize: '0.85em', fontWeight: 'bold'}}>Task:</Typography>
                    <Typography sx={{fontSize: '0.7rem'}}>Each student to have the ability to:</Typography>
                    <ol>
                        {abilities.map((ability, index) => (
                            <Typography component="li" key={`unit${key}-ability${index}`} sx={{fontSize: '0.7rem'}}>{ability}</Typography>
                        ))}
                    </ol>
                </Stack>
            </Box> */}
            <Stack direction="row" sx={{backgroundColor: '#c0c0c0'}}>
                <Typography sx={{flex: 1, p: 0.5, textAlign: 'center', fontWeight: 'bold'}}>{title}</Typography>

                {sortedUsers.map(user => {
                    const {uid, firstName, lastName} = user;
                    const initials = `${firstName[0]}${lastName[0]}`;

                    return (
                        <Typography
                            onClick={() => handleUserSkillReset(uid)}
                            component="div"
                            key={`userheader-${uid}`}
                            sx={{width: 40, borderLeft: '1px solid #000', p: 0.5, textAlign: 'center', fontWeight: 'bold'}}
                        >
                            <Tooltip title={`${firstName} ${lastName}`} placement="top">
                                {initials}
                            </Tooltip>
                        </Typography>
                    );
                })}
            </Stack>
            {tasks.map((task, taskIndex) => {
                const {title, skills = []} = task;

                return (
                    <Stack key={`unit${taskIndex}-task${taskIndex}`} sx={{'&:last-child': {borderBottom: 0}}} direction="column">
                        {title && <Typography sx={{p: 0.5, textAlign: 'center', fontSize: '0.8rem', backgroundColor: '#dadada'}}>{title}</Typography>}
                        {skills.map((skill, index) => {
                            const {errorType, text} = skill;
                            const userSkillKey = `skills.${level}.jibcSkills.${jibcKey}.${taskIndex}.${index}`;
                            const color = errorTypes.find(type => type.type === errorType)?.color;
                            
                            return (
                                <Stack key={`unit${taskIndex}-task${index}-skill${index}`} direction="row" spacing={0.5} sx={{minHeight: 35, alignItems: 'stretch', backgroundColor: '#fff', borderTop: '1px solid #000', '&:first-child': {borderTop: 0}}}>
                                    <Box sx={{width: 5, backgroundColor: color, borderRight: '1px solid #000'}} />
                                    <Box sx={{width: 25, p: 0.5, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: '1px solid #000'}}>
                                        <Typography sx={{textAlign: 'center', fontSize: '0.7rem'}}>{errorType[0]?.toUpperCase()}</Typography>
                                    </Box>

                                    <Box sx={{flex: 1, p: 0.5, display: 'flex', alignItems: 'center'}}>
                                        <Typography sx={{fontSize: '0.8rem'}}>{text}</Typography>
                                    </Box>

                                    {sortedUsers.map(user => {
                                        const {uid} = user;
                                        const value = get(user, userSkillKey);

                                        return (
                                            <Box
                                                key={`skilluser${index}-${uid}`}
                                                sx={{marginLeft: '0 !important', width: 40, p: 0.5, borderLeft: '1px solid #000', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: userColumnBackgroundColor[uid]}}
                                                onClick={() => handleUserSkillChange(uid, userSkillKey, value)}
                                            >
                                                {value === true && (
                                                    <CheckIcon fontSize="small" />
                                                )}
                                                {value === false && (
                                                    <CloseIcon fontSize="small" />
                                                )}
                                            </Box>
                                        );
                                    })}
                                </Stack>
                            );
                        })}
                    </Stack>
                );
            })}
        </Stack>
    );
};

const SkillSheet = () => {
    const options = modules.reduce((acc, module) => {
        const {units = []} = module;

        return [...acc, ...units.map(unit => ({...unit, module}))];
    }, []);

    const [value, setValue] = useState(options[0]);
    const [inputValue, setInputValue] = useState('');

    const {users} = useContext(UserContext);
    const recruits = users.filter(user => {
        const {role, archived, lastName} = user;
    
        return (role === 'RECRUIT' && archived !== true) || lastName === 'World';
    });
    
    return (
        <>
            <Typography variant="h5" gutterBottom>JIBC Skills</Typography>

            <Autocomplete
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                sx={{my: 2}}
                options={options}
                groupBy={option => option.module?.title}
                getOptionLabel={option => {
                    const {title} = option;
                    return title;
                }}
                isOptionEqualToValue={(option, value) => option.jibcKey === value.jibcKey}
                renderInput={params => <TextField {...params} label="Skill Sheet" />}
                fullWidth
            />

            <Unit unit={value} users={recruits} />
        </>
    );
};

export default SkillSheet;