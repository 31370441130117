import React, {useState, useEffect, useCallback} from 'react';
import {Stack, Typography, useMediaQuery} from '@mui/material';
import {useSnackbar} from 'notistack';
import {collection, query, where} from 'firebase/firestore';
import moment from 'moment';
import {useForm, FormProvider} from 'react-hook-form';
import {useSearchParams} from 'react-router-dom';
import {useTheme} from '@mui/material/styles';

import {db} from '-/firebase';

import {getCollection} from '-/data/utils';

import Filter from '-/components/Filter';
import SelectField from '-/form/SelectField';

import Grid from './payroll/Grid';
import IncidentAttendance from './payroll/IncidentAttendance';
import TrainingAttendance from './payroll/TrainingAttendance';

const Payroll = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const {enqueueSnackbar} = useSnackbar();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const startDate = searchParams.get('startDate');
    const endDate = searchParams.get('endDate');

    let defaultDateRange = [moment().startOf('year'), moment().endOf('year')];
    if (startDate && endDate) {
        defaultDateRange = [moment(startDate), moment(endDate)];
    }

    const methods = useForm({
        defaultValues: {
            dateRange: defaultDateRange,
            report: 'totals'
        },
        mode: 'onChange'
    });

    const {watch} = methods;
    const dateRange = watch('dateRange');
    const report = watch('report');

    useEffect(() => {
        if (dateRange) {
            const [startDate, endDate] = dateRange;

            // if startDate and endDate are the default values, we dont need to set search params
            if (startDate.isSame(defaultDateRange[0]) && endDate.isSame(defaultDateRange[1])) {
                return;
            }

            setSearchParams({
                startDate: moment(startDate).format('YYYY-MM-DD'),
                endDate: moment(endDate).format('YYYY-MM-DD')
            });
        }
    }, [dateRange]);

    const fetch = useCallback(async() => {
        setLoading(true);

        try {
            let [startDate, endDate] = dateRange || [];
            startDate = moment(startDate).startOf('day').toDate();
            endDate = moment(endDate).endOf('day').toDate();

            const incidentsRef = collection(db, 'incidents');
            const incidentsQuery = query(incidentsRef, where('date', '>=', startDate), where('date', '<=', endDate));
            const incidents = await getCollection(db, incidentsQuery);

            const trainingRef = collection(db, 'training');
            const trainingQuery = query(trainingRef, where('date', '>=', startDate), where('date', '<=', endDate));
            const training = await getCollection(db, trainingQuery);

            setData({
                incidents,
                training
            });
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        setLoading(false);
    }, [dateRange]);

    useEffect(() => {
        fetch();
    }, [searchParams]);

    let Component = Grid;
    if (report === 'incidentAttendance') {
        Component = IncidentAttendance;
    } else if (report === 'trainingAttendance') {
        Component = TrainingAttendance;
    }

    const {incidents, training} = data || {};

    return (
        <FormProvider {...methods}>
            <Stack alignItems={!isSmall && 'center'} spacing={1} direction={isSmall ? 'column' : 'row'} sx={{mb: 2}} justifyContent="space-between">
                <Typography variant="h5">Payroll</Typography>
            
                <Filter sx={{flex: {xs: 1, sm: '0 1 auto'}, justifyContent: {xs: 'space-between', sm: 'flex-end'}}} loading={loading}>
                    <SelectField
                        sx={{flex: {xs: 1, sm: '0 1 auto'}, minWidth: 180}}
                        name="report"
                        label="Type"
                        options={[
                            {value: 'totals', label: 'Total Hours'},
                            {value: 'incidentAttendance', label: 'Incident Attendance'},
                            {value: 'trainingAttendance', label: 'Training Attendance'}
                        ]}
                    />
                </Filter>
            </Stack>
            
            <Component loading={loading} incidents={incidents} training={training} />
        </FormProvider>
    );
};

export default Payroll;