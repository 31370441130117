import React, {useContext, useState, useEffect, useCallback, useMemo} from 'react';
import {Alert, Box, Stack, Button, AlertTitle, Checkbox, FormControlLabel, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import {useForm, FormProvider} from 'react-hook-form';
import {LoadingButton} from '@mui/lab';

import {UserContext} from '-/contexts/User';

import TextField from '-/form/TextField';
import UserAutocompleteField from '-/form/UserAutocompleteField';
import FileField from '-/form/FileField';

import ResponsiveDialog from '-/components/ResponsiveDialog';

export default function TaskCompletionDialog({onSubmit: onUpdate, record, open, onClose}) {
    const [loading, setLoading] = useState(false);
    const {currentUser} = useContext(UserContext);
    const lastSelectedUser = localStorage.getItem('lastSelectedUser');

    const defaultValues = useMemo(() => {
        const {assignee, completedBy} = record || {};

        const {uid, role} = currentUser || {};
        let users = [];

        if (lastSelectedUser) {
            users.push(lastSelectedUser);
        } else if (role !== 'STATION') {
            users.push(uid);
        }

        return {
            ...record,
            files: [],
            completedNote: '',
            completedBy: completedBy || assignee || uid,
            skipRecurring: false
        };
    }, [record, currentUser, lastSelectedUser]);

    const methods = useForm({
        defaultValues,
        mode: 'onChange'
    });
    const {reset, handleSubmit, watch, setValue} = methods;
    const users = watch('users', []);
    const recurring = watch('recurring');
    const skipRecurring = watch('skipRecurring');

    useEffect(() => {
        reset(defaultValues);
    }, [reset, defaultValues, record]);

    useEffect(() => {
        const [user] = users || [];
        if (user) {
            const {uid} = typeof user === 'string' ? {uid: user} : user;
            const {uid: currentUserUid} = currentUser || {};

            if (currentUserUid !== uid) {
                localStorage.setItem('lastSelectedUser', uid);
            }
        }
    }, [users, currentUser]);

    const onSubmit = useCallback(async data => {
        setLoading(true);

        const {recurring, skipRecurring = false, ...rest} = data;

        const result = await onUpdate({
            ...rest,
            ...!skipRecurring && recurring ? {recurring} : {}
        });
        if (result) {
            onClose();
            reset({});
        }

        setLoading(false);
    }, [onClose, reset, onUpdate]);

    return (
        <FormProvider {...methods}>
            <ResponsiveDialog
                open={open}
                onClose={onClose}
                PaperProps={{
                    component: 'form',
                    noValidate: true,
                    onSubmit: handleSubmit(onSubmit)
                }}
            >
                <DialogTitle>Complete Task</DialogTitle>
                <DialogContent dividers>
                    <TextField
                        name="completedNote"
                        label="Note"
                        fullWidth
                        disabled={!onUpdate || loading}
                    />
                    <UserAutocompleteField
                        sx={{mt: 2}}
                        name="completedBy"
                        label="Completed By"
                        multiple={false}
                        required
                        fullWidth
                        disabled={!onUpdate || loading}
                    />
                    {recurring && (
                        <Alert sx={{mt: 1}} severity="normal">
                            <Stack direction="row" alignItems="center">
                                <Box sx={{flex: 1}}>
                                    <AlertTitle>Recurring Task</AlertTitle>
                                    This task is recurring. The next task will be created after this one is completed.
                                </Box>
                                <FormControlLabel
                                    control={(
                                        <Checkbox
                                            checked={skipRecurring}
                                            onChange={e => setValue('skipRecurring', e.target.checked)}
                                        />
                                    )}
                                    label="Skip"
                                />
                            </Stack>
                        </Alert>
                    )}
                    <FileField
                        sx={{mt: 1}}
                        name="files"
                        disabled={!onUpdate || loading}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} disabled={loading}>Cancel</Button>
                    <LoadingButton variant="contained" loading={loading} type="submit" disabled={loading}>Complete Task</LoadingButton>
                </DialogActions>
            </ResponsiveDialog>
        </FormProvider>
    );
};