import React, {useState, useCallback, useContext} from 'react';
import {Stack, Button, Typography, Box, Container} from '@mui/material';
import {signInWithEmailAndPassword, sendPasswordResetEmail, signInWithCustomToken, validatePassword} from 'firebase/auth';
import {useForm, FormProvider} from 'react-hook-form';
import {query, collection, where} from 'firebase/firestore';
import {useSnackbar} from 'notistack';
import {httpsCallable} from 'firebase/functions';

import {db, auth, functions} from '-/firebase';

import {joinWithAnd} from '-/utils';
import {getCollection} from '-/data/utils';

import {SettingsContext} from '-/contexts/Settings';
import {StationsContext} from '-/contexts/Stations';

import TextField from '-/form/TextField.js';
import PasswordField from '-/form/PasswordField.js';

import FirebaseImage from '-/components/FirebaseImage';

import useDocumentTitle from '-/hooks/useDocumentTitle';

import firebaseConfig from '-/firebase-config.json';

export default function Login() {
    const [loading, setLoading] = useState(false);
    const [loggingIn, setLoggingIn] = useState(false);
    const [loggingInAsStation, setLoggingInAsStation] = useState(false);
    const [registering, setRegistering] = useState(false);
    const [error, setError] = useState(null);
    const {enqueueSnackbar} = useSnackbar();
    const loginAsStation = httpsCallable(functions, 'loginAsStation');
    const registerUser = httpsCallable(functions, 'registerUser');
    const {image, allowStationLogin, allowRegistrationWithoutUser, limitToDomain, domain} = useContext(SettingsContext);
    const {stations} = useContext(StationsContext);

    useDocumentTitle('Login');

    const isDemo = firebaseConfig.projectId === 'embertracking-demo';

    const methods = useForm({
        defaultValues: {
            email: isDemo ? 'demo@embertracking.com' : '',
            password: isDemo ? 'password' : '',
            confirmPassword: ''
        }
    });
    const {handleSubmit, watch, setValue} = methods;

    const email = watch('email');
    const password = watch('password');

    let pattern;
    if (limitToDomain) {
        pattern = {
            value: new RegExp(`^[A-Z0-9._%+-]+@${domain}$`, 'i'),
            message: `You must use an @${domain} email address`
        };
    } else {
        pattern = {
            value: /\S+@\S+\.\S+/,
            message: 'Invalid email address'
        };
    }

    const onResetPassword = useCallback(async () => {
        setLoading(true);

        try {
            await sendPasswordResetEmail(auth, email);

            enqueueSnackbar(`Reset password email sent to ${email}. Please check your email.`);
        } catch (e) {
            setLoading(false);

            setError(e.message);
        }

        setLoading(false);
    }, [auth, email, enqueueSnackbar]);

    const onCancel = useCallback(() => {
        setRegistering(false);
        setLoggingIn(false);
        setLoggingInAsStation(false);
    }, []);

    const onStationLogin = async (station, password) => {
        setLoading(true);
        setError(null);

        try {
            const {uid} = station;
            const {data} = await loginAsStation({station: uid, password});
            const {success, token} = data || {};

            if (!success) {
                throw new Error('Error logging in as station');
            }

            await signInWithCustomToken(auth, token);
        } catch(e) {
            setLoading(false);

            enqueueSnackbar(e.message || 'Error logging in as station. Please try again or contact the administrator', {variant: 'error'});
        }
    };

    const handleStationLogin = async station => {
        setValue('email', '', {shouldDirty: true});
        setValue('password', isDemo ? 'password' : '', {shouldDirty: true});

        setLoggingInAsStation(station);
    };

    const onSubmit = useCallback(async data => {
        setLoading(true);
        setError(null);

        const {password} = data;
        let {email = ''} = data;
        email = email.toLowerCase().trim();

        if (loggingInAsStation) {
            onStationLogin(loggingInAsStation, password);
            return;
        }

        try {
            const ref = collection(db, 'users');
            const q = query(ref, where('email', '==', email));
            const [existingUser] = await getCollection(db, q);

            if (registering) {
                await registerUser({email, password});

                setTimeout(async() => {
                    await signInWithEmailAndPassword(auth, email, password);
                }, 1000);
            } else if (loggingIn) {
                await signInWithEmailAndPassword(auth, email, password);
            } else {
                if (!existingUser && !allowRegistrationWithoutUser) {
                    throw new Error('No user found with that email address. Please contact the system administrator.');
                }

                const {registered = false} = existingUser || {};
                if (registered) {
                    setLoggingIn(true);
                } else {
                    setRegistering(true);
                }

                setLoading(false);
            }
        } catch (e) {
            setLoading(false);

            const {code} = e || {};
            if (code === 'auth/wrong-password') {
                setError('Incorrect password');
            } else if (code === 'auth/user-not-found') {
                setError('No user found with that email address');
            } else {
                setError(e.message);
            }
        }
    }, [auth, registering, loggingIn, loggingInAsStation, db]);

    const handlePasswordValidation = useCallback(isConfirm => {
        if (!loggingIn) {
            return true;
        }

        return async value => {
            if (isConfirm) {
                const password = watch('password');
                if (value !== password) {
                    return 'Passwords do not match';
                }
            } else {
                if (!value) {
                    return 'Password is required';
                }

                if (value.length < 4) {
                    return 'Password must be at least 4 characters';
                }

                try {
                    const status = await validatePassword(auth, value);
                    if (!status.isValid) {
                        const {passwordPolicy} = status;
                        const {customStrengthOptions} = passwordPolicy || {};
                        const {minPasswordLength, maxPasswordLength} = customStrengthOptions || {};

                        const issues = [];

                        if (status.containsLowercaseLetter === false) {
                            issues.push('at least 1 lowercase letter');
                        }

                        if (status.containsNonAlphanumericCharacter === false) {
                            issues.push('at least 1 special character');
                        }

                        if (status.containsNumericCharacter === false) {
                            issues.push('at least 1 number');
                        }

                        if (status.containsUppercaseLetter === false) {
                            issues.push('at least 1 uppercase letter');
                        }

                        if (status.meetsMaxPasswordLength === false) {
                            issues.push(`no more than ${maxPasswordLength} characters`);
                        }

                        if (status.meetsMinPasswordLength === false) {
                            issues.push(`no more than ${minPasswordLength} characters`);
                        }

                        return `Password must include ${joinWithAnd(issues)}`;
                    }
                } catch(e) {
                    //
                }
            }

            return true;
        };
    }, [password, loggingIn]);

    return (
        <FormProvider {...methods}>
            <Container component="main" maxWidth="s" sx={{maxWidth: 400}}>
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'stretch'
                    }}
                >
                    <Box sx={{mt: 1, display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                        {image ? (
                            <FirebaseImage image={image} sx={{width: '60%'}} />
                        ) : (
                            <img src="/logo.png" alt="Ember Tracking" style={{width: '60%'}} />
                        )}
                    </Box>
                    <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{mt: 1}}>
                        {!loggingInAsStation && (
                            <TextField
                                sx={{mt: 2}}
                                fullWidth
                                label="Email"
                                name="email"
                                autoComplete="email"
                                disabled={loading || loggingIn || registering}
                                rules={{required: 'Please enter an email address', pattern}}
                                {...{
                                    ...(error && {error: true, helperText: error})
                                }}
                            />
                        )}

                        {loggingInAsStation && (
                            <Typography variant="h5" sx={{mt: 2, mb: 2, textAlign: 'center'}}>Logging in as <strong>Station {loggingInAsStation.name}</strong></Typography>
                        )}

                        {allowStationLogin && !registering && !loggingInAsStation && !loggingIn && (
                            <>
                                {!loggingInAsStation && <Typography component="div" variant="caption" sx={{mt: 2, mb: 2, textAlign: 'center'}}>OR LOGIN AS</Typography>}

                                <Stack direction="column" spacing={1}>
                                    {stations.map(station => {
                                        const {uid, name} = station;
                                        const isLoggingIn = loggingInAsStation && loggingInAsStation.uid === uid;

                                        if (loggingInAsStation && !isLoggingIn) {
                                            return null;
                                        }

                                        return (
                                            <Button onClick={() => handleStationLogin(station)} key={uid} variant={loggingIn ? 'contained' : 'outlined'} disabled={loading} sx={{textTransform: 'none'}}>
                                                {name}
                                            </Button>
                                        );
                                    })}
                                </Stack>
                            </>
                        )}

                        <PasswordField
                            sx={{mt: 2, display: (loggingIn || loggingInAsStation || registering) ? 'block' : 'none'}}
                            fullWidth
                            label="Password"
                            name="password"
                            autoComplete="current-password"
                            disabled={loading}
                            rules={{
                                required: (loggingIn || loggingInAsStation || registering) && 'Password is required',
                                validate: handlePasswordValidation()
                            }}
                        />

                        {registering && (
                            <>
                                <PasswordField
                                    sx={{mt: 2}}
                                    rules={{
                                        required: true,
                                        validate: handlePasswordValidation(true)
                                    }}
                                    fullWidth
                                    label="Confirm Password"
                                    name="confirmPassword"
                                    autoComplete="new-password"
                                    disabled={loading}
                                />
                            </>
                        )}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{mt: 2}}
                            onClick={handleSubmit(onSubmit)}
                            disabled={loading}
                            loading={loading}
                        >
                            {registering ? 'Register' : (loggingIn || loggingInAsStation) ? 'Sign In' : 'Next'}
                        </Button>
                        {loggingIn && (
                            <Button
                                fullWidth
                                variant="outlined"
                                sx={{mt: 1}}
                                onClick={onResetPassword}
                                disabled={loading}
                            >
                                Reset Password
                            </Button>
                        )}
                        {(loggingIn || registering || loggingInAsStation) && (
                            <>
                                <Button
                                    fullWidth
                                    sx={{mt: 1}}
                                    onClick={onCancel}
                                    disabled={loading}
                                >
                                    Cancel
                                </Button>
                            </>
                        )}
                    </Box>
                </Box>
            </Container>
        </FormProvider>
    );
};