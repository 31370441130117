import React, {useState, useEffect, useCallback, useMemo} from 'react';
import {Stack, Box, Button, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import {useForm, FormProvider} from 'react-hook-form';
import moment from 'moment';

import TextField from '-/form/TextField';
import DatePickerField from '-/form/DatePickerField';
import CollectionAutocompleteField from '-/form/CollectionAutocompleteField';
import ImagesUploadField from '-/form/ImagesUploadField';
import UserAutocompleteField from '-/form/UserAutocompleteField';
import CheckboxField from '-/form/CheckboxField';

import ResponsiveDialog from '-/components/ResponsiveDialog';

import {useItems} from '-/pages/gear/ItemsGrid';

export default function ItemDialog({onSubmit: onUpdate, onDelete, onArchive, parent, item, open, handleClose}) {
    const [loading, setLoading] = useState(false);
    const methods = useForm({
        defaultValues: useMemo(() => {
            const {additionalFields = []} = parent || {};

            return item || {
                archived: false,
                ...additionalFields.reduce((result, field) => {
                    const {id} = field;

                    return {
                        ...result,
                        [id]: ''
                    };
                }, {})
            };
        }, [item, parent]),
        mode: 'onChange'
    });
    const {reset, handleSubmit, watch} = methods;
    const uid = watch('uid');
    const archived = watch('archived');

    const {additionalFields = []} = parent || {};
    const {items} = useItems();

    useEffect(() => {
        reset(item);
    }, [reset, item]);

    const handleDelete = async () => {
        setLoading();

        const result = await onDelete(item);
        if (result) {
            handleClose();
            reset({});
        }

        setLoading(false);
    };

    const handleArchive = async () => {
        setLoading();

        await onArchive(item);

        setLoading(false);
    };

    const onSubmit = useCallback(async data => {
        setLoading(true);

        const result = await onUpdate(data);
        
        if (result) {
            handleClose();
            reset({});
        }

        setLoading(false);
    }, [handleClose, onUpdate, reset]);

    return (
        <FormProvider {...methods}>
            <ResponsiveDialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    noValidate: true,
                    onSubmit: handleSubmit(onSubmit)
                }}
            >
                <DialogTitle>{item ? 'Update Item' : 'Add New Item'}</DialogTitle>
                <DialogContent dividers>
                    <Stack spacing={2}>
                        <ImagesUploadField multiple name="image" disabled={loading || archived} />

                        {additionalFields.map((field, index) => {
                            const {id, type, unique = false} = field;
                            let {label} = field;
                            const key = `additional-field-${index}`;

                            if (!label) {
                                if (type === 'apparatus') {
                                    label = 'Apparatus';
                                } else if (type === 'date') {
                                    label = 'Date';
                                } else if (type === 'user') {
                                    label = 'Member';
                                }
                            }

                            const base = {
                                name: id,
                                label,
                                fullWidth: true,
                                disabled: loading || archived
                            };

                            if (unique === true) {
                                base.rules = {
                                    validate: value => {
                                        const hasMatch = items.find(({uid: itemUid, [id]: itemValue}) => {
                                            if (!itemValue || !value) return false;
                                          
                                            // Check if values are dates
                                            const isDate = moment.isMoment(itemValue) || moment.isMoment(value) ||
                                                           (itemValue instanceof Date) || (value instanceof Date);
                                            
                                            if (isDate) {
                                                const itemDate = moment(itemValue).startOf('day');
                                                const inputDate = moment(value).startOf('day');
                                                return itemDate.isSame(inputDate) && itemUid !== uid;
                                            }
                                          
                                            // For non-date values, compare case-insensitively and trim strings
                                            const normalizedItemValue = typeof itemValue === 'string' ? itemValue.trim().toLowerCase() : itemValue;
                                            const normalizedValue = typeof value === 'string' ? value.trim().toLowerCase() : value;
                                            
                                            return normalizedItemValue === normalizedValue && itemUid !== uid;
                                        });

                                        if (hasMatch) {
                                            return `There is already an item with this ${label.toLowerCase()}`;
                                        }

                                        return true;
                                    }
                                };
                            }

                            if (type === 'date') {
                                return (
                                    <DatePickerField
                                        key={key}
                                        {...base}
                                    />
                                );
                            } else if (type === 'apparatus') {
                                return (
                                    <CollectionAutocompleteField
                                        key={key}
                                        {...base}
                                        collection="apparatus"
                                        filterArchived
                                        displayProperty="tag"
                                        multiple={false}
                                    />
                                );
                            } else if (type === 'user') {
                                return (
                                    <UserAutocompleteField
                                        key={key}
                                        {...base}
                                        multiple={false}
                                    />
                                );
                            } else if (type === 'boolean')  {
                                return (
                                    <CheckboxField
                                        key={key}
                                        {...base}
                                    />
                                );
                            }

                            return (
                                <TextField
                                    key={key}
                                    {...base}
                                />
                            );
                        })}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    {(item && (onDelete || onArchive)) && (
                        <>
                            {onDelete && <Button onClick={handleDelete} disabled={loading}>Delete</Button>}
                            {onArchive && <Button onClick={handleArchive} disabled={loading}>{archived ? 'Unarchive' : 'Archive'}</Button>}
                            <Box sx={{flex: 1}} />
                        </>
                    )}
                    <Button onClick={handleClose} disabled={loading}>Cancel</Button>
                    <Button loading={loading} variant="contained" type="submit" disabled={loading}>{item ? 'Update' : 'Add'} Item</Button>
                </DialogActions>
            </ResponsiveDialog>
        </FormProvider>
    );
};