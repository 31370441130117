import React from 'react';
import {collection, query, where, doc, getDocs, onSnapshot, getDocsFromCache, getDoc, documentId} from 'firebase/firestore';
import {get, chunk, uniq, castArray, isString} from 'lodash';
import {red, grey, yellow, green, brown, orange, blue, purple, pink} from '@mui/material/colors';
import moment from 'moment';
import axios from 'axios';
import {ShortRanks, FirefighterRanks, Ranks} from '@embertracking/common';

import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import CheckIcon from '@mui/icons-material/Check';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';

import {app, auth} from '-/firebase';

const projectId = get(app, '_options.projectId');

let cachedUsers = {};

export const colorForIncidentType = type => {
    if (!type) {
        return grey[200];
    }

    if (type === 'FMR') {
        return red[500];
    } else if (type === 'STRUCTURE_FIRE') {
        return yellow[500];
    } else if (type === 'RECHECK') {
        return yellow[100];
    } else if (type === 'WILDLAND_FIRE') {
        return green[500];
    } else if (type === 'BURNING_COMPLAINT') {
        return brown[500];
    } else if (type === 'MVI') {
        return blue[500];
    } else if (type === 'LINE_DOWN') {
        return orange[200];
    } else if (type === 'ALARM') {
        return yellow[300];
    } else if (type === 'MUTUAL_AID') {
        return green[200];
    } else if (type === 'ASSIST') {
        return purple[200];
    } else if (type === 'SERVICE_CALL') {
        return purple[500];
    } else if (type === 'HAZMAT') {
        return pink[200];
    }

    return grey[200];
};

const fetchDocumentsWithIds = async(db, ref, ids, returnObject) => {
    const data = returnObject ? {} : [];

    if (!ids || !ids.length) {
        return data;
    }

    // Separate string IDs from object entries
    const stringIds = [];
    const objectEntries = [];

    ids.forEach(id => {
        if (typeof id === 'string') {
            stringIds.push(id);
        } else if (typeof id === 'object') {
            objectEntries.push(id);
        }
    });

    // Add object entries directly to the result
    if (returnObject) {
        objectEntries.forEach(obj => {
            data[obj.id] = obj;
        });
    } else {
        data.push(...objectEntries);
    }

    // Fetch documents only for string IDs
    if (stringIds.length) {
        const chunks = chunk(stringIds, 10);

        for (const chunk of chunks) {
            const q = query(ref, where(documentId(), 'in', chunk));
            const raw = await getDocs(q);

            if (raw) {
                raw.forEach(item => {
                    const doc = processRawDoc(item);
                    const {id} = doc;

                    if (returnObject) {
                        data[id] = doc;
                    } else {
                        data.push(doc);
                    }
                });
            }
        }
    }

    return data;
};

let userKeys = ['user', 'users', 'members', 'instructors', 'assignee', 'completedBy', 'officerInCharge'];
export const populateDocsWithUsers = (incoming, users) => {
    const userMap = Array.isArray(users) ? users.reduce((acc, user) => {
        acc[user.uid] = user;
        return acc;
    }, {}) : users;

    const undefinedUsers = Object.keys(userMap).filter(userId => !userMap[userId]);
    if (undefinedUsers.length) {
        console.warn('populateDocsWithUsers: undefined users', undefinedUsers);
    }

    const docs = castArray(incoming).filter(Boolean);
    const result = docs.map(doc => {
        for (const key of userKeys) {
            const existing = doc[key];
            if (!existing) {
                continue;
            }

            if (Array.isArray(existing)) {
                doc[key] = existing.map(userId => userMap[userId] || {
                    id: userId,
                    uid: userId
                });
            } else {
                doc[key] = userMap[existing] || {
                    id: existing,
                    uid: existing
                };
            }
        }

        return doc;
    });

    if (Array.isArray(incoming)) {
        return result.filter(Boolean);
    }

    return result[0];
};

export const populateUsers = async(db, incoming) => {
    let userIds;
    const docs = castArray(incoming).filter(Boolean);
    for (const doc of docs) {
        const users = userKeys.map(key => doc[key]).filter(Boolean).flat().map(user => {
            if (typeof user === 'object') {
                return user.uid;
            }

            return user;
        });

        userIds = userIds ? [...userIds, ...users] : users;
    }

    const users = await populateCollectionIds(db, 'users', userIds);
    return populateDocsWithUsers(Array.isArray(incoming) ? docs : docs[0], users);
};

export const populateKeyFromCollection = async(db, incoming, key, collectionName) => {
    const isArray = Array.isArray(incoming);
    const docs = castArray(incoming);
    const ids = docs.map(doc => doc[key]).filter(Boolean).flat();

    const ref = isString(collectionName) ? collectionName : collection(db, collectionName);
    const data = await populateCollectionIds(db, ref, ids);

    const result = docs.map(doc => {
        const id = doc[key];
        if (Array.isArray(id)) {
            doc[key] = id.map(uid => data[uid]);
        } else {
            doc[key] = data[id];
        }

        return doc;
    });

    return isArray ? result : result[0];
};

export const populateCollectionIds = async(db, ref, ids) => {
    if (typeof ref === 'string') {
        ref = collection(db, ref);
    }

    const toFetch = uniq(ids).filter(id => !cachedUsers[id]);
    if (toFetch.length) {
        const fetched = await fetchDocumentsWithIds(db, ref, toFetch, true);

        cachedUsers = {
            ...cachedUsers,
            ...fetched
        };
    }

    return uniq(ids).reduce((result, userId) => {
        result[userId] = cachedUsers[userId];
        return result;
    }, {});
};

const getUserRankLabel = user => {
    if (!user) {
        return;
    }

    const {role} = user || {};
    if (!FirefighterRanks.includes(role)) {
        return;
    }

    return ShortRanks[role];
};

export const processUserName = (user, opts) => {
    const {users} = opts || {};

    const {firstName, lastName} = user;
    const fullName = [firstName || '', lastName || ''].filter(Boolean).join(' ');
    const rank = getUserRankLabel(user);
    let shortName, rankedShortName;

    if (users.length) {
        const uniqueLastName = users.filter(doc => doc.lastName === lastName).length === 1;
        shortName = uniqueLastName ? lastName : [firstName && firstName[0], lastName].filter(Boolean).join('. ');

        if (rank) {
            rankedShortName = [rank, lastName].filter(Boolean).join(' ');
        }
    }

    return {
        ...user,
        fullName,
        rankedFullName: [rank, fullName].filter(Boolean).join(' '),
        ...shortName && {
            shortName,
            rankedShortName
        }
    };
};

export const ensureMomentDates = obj => {
    if (obj instanceof Date) {
        return moment(obj);
    }

    if (typeof obj === 'object' && obj !== null) {
        Object.keys(obj).forEach(key => {
            const value = obj[key];
            if (value == null) {
                return;
            }

            if (value instanceof Date) {
                obj[key] = moment(value);
            } else if (value.toDate) {
                obj[key] = moment(value.toDate());
            } else if (Array.isArray(value)) {
                obj[key] = value.map(ensureMomentDates);
            } else if (typeof value === 'object') {
                obj[key] = ensureMomentDates(value);
            }
        });
    }

    return obj;
};

export const ensureJSDates = obj => {
    if (moment.isMoment(obj)) {
        return obj.toDate();
    }

    if (typeof obj === 'object' && obj !== null) {
        Object.keys(obj).forEach(key => {
            const value = obj[key];
            if (value === null || value === undefined) {
                return value;
            }

            if (moment.isMoment(value)) {
                obj[key] = value.toDate();
            } else if (value.toDate) {
                obj[key] = value.toDate();
            } else if (Array.isArray(value)) {
                obj[key] = value.map(ensureJSDates);
            } else if (typeof value === 'object' && value !== null) {
                obj[key] = ensureJSDates(value);
            }
        });
    }

    return obj;
};

export const processRawDoc = raw => {
    if (!raw || !raw.exists()) {
        return null;
    }

    const {id} = raw;
    const data = ensureMomentDates(raw.data());

    return {
        ...data,
        id,
        uid: id
    };
};

export const processRawDocs = (raw, opts = {}) => {
    const {includePath = false} = opts;
    let docs = [];

    raw.forEach(doc => {
        docs.push({
            ...processRawDoc(doc),
            ...includePath && {
                path: doc.ref.path
            }
        });
    });

    return docs.filter(Boolean);
};

export const getSnapshot = (db, collectionName, fn) => {
    return onSnapshot(collection(db, collectionName), snapshot => {
        const docs = processRawDocs(snapshot);
        fn(docs);
    });
};

export const getCollection = async(db, collectionName, opts) => {
    const {useCache = false} = opts || {};
    const q = isString(collectionName) ? query(collection(db, collectionName)) : collectionName;

    let raw = await getDocsFromCache(q);
    if (!useCache || raw.empty) {
        raw = await getDocs(q);
    }
    
    return processRawDocs(raw);
};

export const getCollectionDoc = async(db, collectionName, uid) => {
    if (!collectionName || !uid) {
        return;
    }

    const ref = doc(db, collectionName, uid);
    const raw = await getDoc(ref);
    return processRawDoc(raw);
};

export const hasPermission = (user, permission) => {
    if (!user || typeof user === 'string') {
        console.error('#hasPermission: No user provided');
        return false;
    }

    const {permissions = {}} = user;
    if (permissions.admin) {
        return true;
    }

    return permissions[permission];
};

export const DriverOperatorSkills = {
    1: 'Cleaning and Waxing',
    2: 'Perform a routine walk-around maintenance inspection',
    3: 'Perform engine compartment inspection and routine preventive maintenance',
    4: 'Perform weekly inspections for apparatus equipped with a fire pump',
    5: 'Perform a hard intake hose service test',
    6: 'Test Apparatus Road and Parking Brakes',
    7: 'Drive a Fire Service Apparatus',
    8: 'Perform Various Driving Exercises',
    9: 'Perform Various Road Test in a Fire Service Apparatus',
    10: 'Hose Intakes, Hydrant Outlets, Multiple Connections',
    11: 'Dual Pumping and Tandem Pumping',
    12: 'Engage and Disengage a PTO, Engage and Disengage a Pump',
    13: 'Perform Pump Operations from a Water Tank, Transition from water tank to an external pressurized water supply, Operate from a pressurized water source',
    14: 'Draft from a Static Water Supply',
    15: 'Relay',
    16: 'Foam',
    17: 'Fire Apparatus Testing',
    18: 'Mobile Water Supply, Water Shuttle Operation, Portable Water Tank Dump Site, Establish, Operate and Shut Down Mobile Portable Tank'
};

export const Skills = {
    exterior: {
        label: 'Exterior',
        skills: {
            fr3: {
                label: 'FR'
            },
            knowledge: 'Knowledge',
            skills: 'Skills',
            hazmatAwareness: {
                label: 'HAZMAT Awareness',
                width: 170
            },
            ics100: 'ICS 100',
            trafficControl: {
                label: 'Traffic Control',
                width: 130
            },
            sppWff1: 'WSPP-WFF 1',
            apparatusDriverOperator: {
                label: 'Driver/Operator',
                width: 130
            },
            airBrakes: 'Air Brakes',
            class3: 'Class 3'
        }
    },
    interior: {
        label: 'Interior',
        skills: {
            knowledge: 'Knowledge',
            skills: 'Skills',
            liveFire1: 'Live Fire 1'
        }
    },
    fullService: {
        label: 'Full Service',
        shortLabel: 'FS',
        skills: {
            knowledge: 'Knowledge',
            skills: 'Skills',
            communityService: 'Community Service',
            liveFire2: 'Live Fire 2',
            hazmatOps: 'HAZMAT Ops'
        }
    },
    teamLeader: {
        label: 'Team Leader',
        shortLabel: 'TL',
        skills: {
            esm1: 'ESM 1',
            ics200: 'ICS 200',
            engineBoss: 'Engine Boss'
        }
    },
    fireOfficer1: {
        label: 'Fire Officer 1',
        shortLabel: 'FO 1',
        skills: {
            frontlineLeadership1: {
                label: 'Frontline Leadership1',
                width: 190
            },
            fireServiceAdmin1: {
                label: 'Fire Service Admin 1',
                width: 190
            },
            companyInspections: {
                label: 'Company Inspections',
                width: 190
            },
            instructor1: 'Instructor 1'
        }
    },
    fireOfficer2: {
        label: 'Fire Officer 2',
        shortLabel: 'FO 2',
        skills: {
            esm1: 'ESM 2',
            frontlineLeadership2: {
                label: 'Frontline Leadership 2',
                width: 190
            },
            fireServiceAdmin2: {
                label: 'Fire Service Admin 2',
                width: 180
            },
            fireCauseAndOrigin1: {
                label: 'Fire Cause & Origin 1',
                width: 180
            },
            ics300: 'ICS 300'
        }
    },
    fireOfficer3: {
        label: 'Fire Officer 3',
        shortLabel: 'FO 3',
        skills: {
            budgetManagement: {
                label: 'Bugdet Management',
                width: 180
            },
            organizationalLeadership1: {
                label: 'Organizational Leadership 1',
                width: 220
            },
            leadingPeople1: {
                label: 'Leading People 1',
                width: 160
            },
            planningAndEvaluation1: {
                label: 'Planning & Evaluation 1',
                width: 190
            },
            ics400: 'ICS 400',
            informationOfficer: {
                label: 'Information Officer',
                width: 170
            }
        }
    },
    fireOfficer4: {
        label: 'Fire Officer 4',
        shortLabel: 'FO 4',
        skills: {
            businessOperations: {
                label: 'Business Operations',
                width: 170
            },
            organizationalLeadership2: {
                label: 'Organizational Leadership 2',
                width: 220
            },
            leadingPeople2: {
                label: 'Leading People 2',
                width: 160
            },
            planningAndEvaluation2: {
                label: 'Planning & Evaluation 2',
                width: 190
            }
        }
    },
    inspectorInvestigator: {
        label: 'Inspector/Investigator',
        width: 180,
        skills: {
            fireInspector1: {
                label: 'Fire Inspector 1',
                width: 150
            },
            fireInspector2: {
                label: 'Fire Inspector 2',
                width: 150
            },
            fireInvestigator: {
                label: 'Fire Investigator',
                width: 150
            }

        }
    },
    rescueTraining: {
        label: 'Rescue Training',
        width: 150,
        extra: true,
        skills: {
            lowAngle: 'Low Angle',
            highAngleOps: {
                label: 'High Angle Ops',
                width: 150
            },
            highAngleTech: {
                label: 'High Angle Technician',
                width: 190
            },
            towerRescue: 'Tower Rescue',
            hazmatTech: {
                label: 'HAZMAT Technician',
                width: 170
            },
            marina: {
                label: 'Marina Firefighting',
                width: 180
            },
            iceRescueTech: {
                label: 'Ice Rescue Technician',
                width: 180
            },
            swiftWaterAwareness: {
                label: 'Swift Water Awareness',
                width: 190
            },
            swiftWaterOps: {
                label: 'Swift Water Ops',
                width: 150
            },
            swiftWaterTech: {
                label: 'Swift Water Technician',
                width: 190
            },
            confinedSpaceAwareness: {
                label: 'Confined Space Awareness',
                width: 220
            },
            confinedSpaceOps: {
                label: 'Confined Space Ops',
                width: 170
            },
            confinedSpaceTech: {
                label: 'Confined Space Technician',
                width: 220
            },
            forcibleEntryAwareness: {
                label: 'Forcible Entry Awareness',
                width: 210
            },
            forcibleEntryOps: {
                label: 'Forcible Entry Ops',
                width: 180
            },
            forcibleEntryTech: {
                label: 'Forcible Entry Technician',
                width: 210
            },
            largeAnimal: {
                label: 'Large Animal Rescue',
                width: 190
            },
            passengerVehicle: {
                label: 'Passenger Vehicle Rescue',
                width: 210
            },
            advancedAutoEx: {
                label: 'Advanced Auto-Ex',
                width: 170
            }
        }
    },
    wildland: {
        label: 'Wildland',
        extra: true,
        skills: {
            s100: 'S-100',
            s100a: 'S-100A',
            spp115: 'WSPP-115',
            s185: {
                label: 'S-185 (Fire Entrapment Avoidance)',
                width: 210
            },
            s190: {
                label: 'S-190 (Safety & Fire Behaviour)',
                width: 210
            },
            s212: {
                label: 'S-212 (Fireline Communications)',
                width: 210
            },
            s290: {
                label: 'S-290 (Intermediate Wildland Fire Behavior)',
                width: 210
            },
            engineBoss: {
                skill: 'teamLeader.engineBoss'
            },
            taskForceLeader: {
                label: 'Task Force Leader',
                width: 170
            },
            divisonSupervisor: {
                label: 'Division Supervisor',
                width: 170
            },
            fireSmart101: {
                label: 'FireSmart 101',
                width: 140
            },
            localFireSmartRep: {
                label: 'Local FireSmart Rep.',
                width: 170
            },
            wildfireMitigationSpecialist: {
                label: 'Wildfire Mitigation Specialist',
                width: 210
            },
            fireSmartCoordinator: {
                label: 'FireSmart Coordinator',
                width: 180
            },
            wildfireRiskReduction: {
                label: 'Wildfire Risk Reduction',
                width: 180
            }
        }
    },
    education: {
        label: 'Education',
        extra: true,
        skills: {
            EMR: 'EMR',
            fireServiceInstructor2: {
                label: 'Fire Service Instructor 2',
                width: 190
            },
            fireAndLifeSafetyEducator1: {
                label: 'Fire & Life Safety Educator 1',
                width: 210
            },
            firstResponderInstructor: {
                label: 'FR Instructor',
                width: 120
            },
            emrInstructor: {
                label: 'EMR Instructor',
                width: 130
            },
            sppWff1Instructor: {
                label: 'WSPP-WFF 1 Instructor',
                width: 190
            },
            emergencyVehicleRegulations: {
                label: 'Emergency Vehicle Driving Regulations',
                shortLabel: 'Emerg. Vehicle Reg.',
                width: 210
            }
        }
    },
    alternativeFuelVehicles: {
        label: 'Alternative Fuel Vehicles',
        width: 200,
        extra: true,
        skills: {
            pleasureCraft: {
                label: 'Pleasure Craft',
                width: 130
            },
            smallNonPleasureVesselBasicSafety: {
                label: 'Small Non-Pleasure Vessel Basic Safety',
                width: 240
            },
            smallVesselOperatorProficiency: {
                label: 'Small Vessel Operator Proficiency',
                width: 260
            },
            atvTraining: {
                label: 'ATV Training',
                width: 120
            },
            utilityVehicleTraining: {
                label: 'Utility Vehicle Training',
                width: 180
            },
            alternativeFuelVehicles: {
                label: 'Alternative Fuel Vehicles',
                width: 210
            },
            alternativeFuelVehiclesInstructor: {
                label: 'Alternative Fuel Vehicles Instructor',
                width: 260
            }
        }
    },
    drones: {
        label: 'Drones',
        extra: true,
        skills: {
            basic: {
                label: 'Basic Operations',
                width: 140
            },
            advanced: {
                label: 'Advanced Operations',
                width: 180
            }
        }
    },
    ptsd: {
        label: 'PTSD',
        extra: true,
        skills: {
            assistingIndividuals: {
                label: 'Assisting Individuals In Crisis',
                width: 220
            },
            resilientMinds: {
                label: 'Resilient Minds',
                width: 140
            },
            resilientMindsInstructor: {
                label: 'Resilient Minds Instructor',
                width: 200
            }
        }
    },
    fitTesting: {
        label: 'Fit Testing',
        extra: true,
        skills: {
            fitTesting: {
                label: 'Fit Testing',
                width: 110
            },
            fitTestingInstructor: {
                label: 'Fit Testing Instructor',
                width: 170
            }
        }
    },
    scottSCBA: {
        label: '3M Scott',
        extra: true,
        skills: {
            certifiedTechnician1: {
                label: 'SCBA Certified Technician 1',
                width: 220
            },
            certifiedTechnician2: {
                label: 'SCBA Certified Technician 2',
                width: 240
            }
        }
    },
    jibc: {
        label: 'JIBC',
        extra: true,
        skills: {
            evaluator: {
                label: 'Evaluator',
                hasExpiry: true
            }
        }
    },
    viera: {
        label: 'VIERA',
        extra: true,
        skills: {
            evaluator: {
                label: '1001 Evaluator',
                width: 150
            },
            writtenProctor: {
                label: 'Written Proctor',
                width: 150
            },
            practicalProctor: {
                label: 'Practical Proctor',
                width: 150
            }
        }
    },
    eoc: {
        label: 'EOC',
        extra: true,
        skills: {
            ops: 'Ops',
            planning: 'Planning',
            logistics: 'Logistics',
            finance: 'Finance',
            informationOfficer: {
                label: 'Information Officer',
                width: 170
            },
            intro: 'EOC Intro',
            essentials: {
                label: 'EOC Essentials',
                width: 140
            }
        }
    },
    iso: {
        label: 'Incident Safety Officer',
        extra: true,
        width: 190,
        skills: {
            iso: {
                label: 'Incident Safety Officer',
                width: 190
            }
        }
    }
};

export const SkillStages = {
    INCOMPLETE: {
        label: 'Incomplete',
        color: 'primary',
        icon: <DoNotDisturbIcon />
    },
    NEEDED: {
        label: 'Needed',
        color: 'needed',
        icon: <ErrorOutlineIcon />
    },
    SCHEDULED: {
        label: 'Scheduled',
        color: 'inProgress',
        icon: <CalendarMonthIcon />
    },
    INPROGRESS: {
        label: 'In Progress',
        color: 'inProgress',
        icon: <HourglassBottomIcon />
    },
    COMPLETE: {
        label: 'Complete',
        color: 'complete',
        icon: <CheckIcon />
    }
};

export const callFunction = async (path, params, opts) => {
    try {
        const token = await auth.currentUser.getIdToken();

        const isDev = process.env.NODE_ENV === 'development';
        const usingApiKey = process.env.REACT_APP_API_KEY;
        const base = (isDev && !usingApiKey) ? `http://127.0.0.1:5001/${projectId}/northamerica-northeast1/` : `https://northamerica-northeast1-${projectId}.cloudfunctions.net/`;
        const url = `${base}${path}`;

        const {headers, ...rest} = opts || {};

        const {data} = await axios.post(url, params, {
            headers: {
                Authorization: `Bearer ${token}`,
                ...headers
            },
            ...rest
        });

        return data;
    } catch(e) {
        const message = get(e, 'response.data.error', e.message);
        throw new Error(message);
    }
};

export const exportGrid = async (type, {startDate, endDate}) => {
    let fn;

    if (type === 'training') {
        fn = 'exportTraining';
    } else if (type === 'incidents') {
        fn = 'exportIncidents';
    }

    if (!fn) {
        throw new Error('Invalid export type');
    }

    return callFunction(fn, {startDate, endDate}, {
        responseType: 'blob'
    });
};

export const uploadImage = async (path, file, multiple = false) => {
    return callFunction('uploadImage', {path, file, multiple}, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};

export const uploadFile = async (path, file, grouping, single = false) => {
    return callFunction('uploadFile', {path, file, grouping, single}, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};

export const rankComparator = (v1, v2) => {
    if (typeof v1 === 'string') {
        return Object.keys(Ranks).indexOf(v2) - Object.keys(Ranks).indexOf(v1);
    }

    const {role: v1Role} = v1 || {};
    const {role: v2Role} = v2 || {};

    if (!v1Role && !v2Role) {
        return 0;
    }

    return Object.keys(Ranks).indexOf(v2Role) - Object.keys(Ranks).indexOf(v1Role);
};