import {useContext} from 'react';
import {get} from 'lodash';

import {SettingsContext} from '-/contexts/Settings';

import {Skills} from '-/data/utils';

export default function useAllSkills() {
    const {qualificationAdditionalCategories = []} = useContext(SettingsContext);
  
    const customSkills = qualificationAdditionalCategories.reduce((result, category) => {
        const {uid, label, skills = []} = category;
  
        if (skills.length) {
            result[uid] = {
                label,
                extra: true,
                custom: true,
                skills: skills.reduce((result, skill) => {
                    const {uid, label} = skill;
                    result[uid] = label;
                    return result;
                }, {})
            };
        }
  
        return result;
    }, {});
  
    const skills = {
        ...Skills,
        ...customSkills
    };

    return {
        skills,
        getSkillLabel: field => {
            if (!field) {
                return '';
            }
        
            const [, level, skill] = field.split('.');
            const levelLabel = get(skills, `${level}.label`);
            const value = get(skills, `${level}.skills.${skill}`);
            const {label: skillLabel, hasExpiry = false} = typeof value === 'object' ? value : {label: value};
        
            return {
                label: `${levelLabel} - ${skillLabel}`,
                levelLabel,
                skillLabel,
                hasExpiry
            };
        }
    };
};