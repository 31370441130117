import React, {useState, useEffect, useContext} from 'react';
import {useMediaQuery, Box} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useTheme} from '@mui/material/styles';
import {get, isNumber} from 'lodash';
import {Ranks} from '@embertracking/common';

import {UserContext} from '-/contexts/User';

import {rankComparator} from '-/data/utils';

import SearchableDataGrid from '-/components/SearchableDataGrid';
import UserAvatar from '-/components/UserAvatar';

const renderImageCell = params => {
    const {row} = params;

    return (
        <Box sx={{display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
            <UserAvatar user={row} />
        </Box>
    );
};

const Grid = ({loading, incidents = [], training = []}) => {
    const [rows, setRows] = useState([]);
    const {enqueueSnackbar} = useSnackbar();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const {users = []} = useContext(UserContext);

    useEffect(() => {
        try {
            let docs = users.map(doc => {
                const {uid} = doc;

                const attendedTraining = training.filter(t => (t.members || []).includes(uid));
                const attendedTrainingHours = attendedTraining.reduce((total, row) => {
                    const {hours, hoursOverrides = {}} = row;
                    const userSalaryOverride = hoursOverrides[uid];

                    if (isNumber(userSalaryOverride)) {
                        return total + userSalaryOverride;
                    }

                    if (!hours) {
                        return total;
                    }

                    return total + parseFloat(hours);
                }, 0);

                const attendedIncidents = incidents.filter(t => (t.users || []).includes(uid));
                const attendedIncidentsHours = attendedIncidents.reduce((total, incident) => {
                    const {durations} = incident;
                    const {salary, salaryOverrides = {}} = durations || {};
                    const userSalaryOverride = salaryOverrides[uid];

                    if (isNumber(userSalaryOverride)) {
                        return total + userSalaryOverride;
                    }

                    if (!salary) {
                        return total;
                    }

                    return total + salary;
                }, 0);

                const hours = {
                    incidents: attendedIncidentsHours,
                    training: attendedTrainingHours
                };

                hours.total = hours.incidents + hours.training;

                return {
                    ...doc,
                    hours
                };
            });

            docs = docs.filter(doc => doc.hours.total > 0);

            setRows(docs);
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }
    }, [training, incidents, users]);

    const columns = [
        {
            field: 'Image',
            headerName: '',
            disableExport: true,
            align: 'center',
            width: isSmall ? 40 : 60,
            valueGetter: (value, row) => {
                return row && row.imageUrl ? row.imageUrl : '';
            },
            renderCell: renderImageCell,
            sortable: false
        },
        {
            field: 'fullName',
            headerName: 'Name',
            flex: 1,
            minWidth: 200,
            renderCell: params => {
                const {row} = params || {};
                const {fullName, email} = row || {};
                return fullName || email;
            }
        },
        {
            field: 'email',
            minWidth: 150,
            headerName: 'Email',
            sortable: false
        },
        {
            field: 'employeeId',
            headerName: 'Employee ID',
            minWidth: 130,
            valueFormatter: value => {
                return (value || '').toUpperCase();
            }
        },
        {
            field: 'station',
            headerName: 'Station',
            minWidth: 100,
            valueGetter: value => {
                const {name} = value || {};
                return name || '-';
            }
        },
        {
            field: 'incidents',
            headerName: 'Incidents',
            minWidth: 120,
            type: 'number',
            valueGetter: (value, row) => {
                return get(row, 'hours.incidents', 0);
            }
        },
        {
            field: 'trainings',
            headerName: 'Training',
            minWidth: 120,
            type: 'number',
            valueGetter: (value, row) => {
                return get(row, 'hours.training', 0);
            }
        },
        {
            field: 'total',
            headerName: 'Total Hours',
            minWidth: 140,
            type: 'number',
            valueGetter: (value, row) => {
                return get(row, 'hours.total', 0);
            }
        },
        {
            field: 'role',
            headerName: 'Rank',
            minWidth: 190,
            valueFormatter: value => {
                return Ranks[value];
            },
            sortComparator: rankComparator
        }
    ];

    return (
        <SearchableDataGrid
            stateId="payroll-grid"
            rows={rows}
            loading={loading}
            columns={columns}
            hideFooter
            initialState={{
                columns: {
                    columnVisibilityModel: {
                        email: false,
                        station: false,
                        employeeId: false
                    }
                },
                aggregation: {
                    model: {
                        incidents: 'sum',
                        trainings: 'sum',
                        total: 'sum'
                    }
                }
            }}
        />
    );
};

export default Grid;