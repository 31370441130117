import React, {useState, useEffect} from 'react';
import {TextField, Autocomplete} from '@mui/material';
import {useController} from 'react-hook-form';
import {sortBy, isString, capitalize} from 'lodash';
import {useSnackbar} from 'notistack';

import {db} from '-/firebase';

import {getCollection} from '-/data/utils';

export default function ControlledAutocompleteField(props) {
    // TODO ensure required/rules & errors are implemented
    const {collection: collectionName, displayProperty = 'name', name, helperText, multiple = true, label, required, rules: rawRules, filterArchived = false, ...rest} = props;
    const rules = {...rawRules};
    const fieldLabel = label !== false ? label || capitalize(name) : null;

    if (required && !rules.required) {
        rules.required = fieldLabel ? `${fieldLabel} is required` : 'This field is required';
    }

    const [loading, setLoading] = useState(true);
    const [options, setOptions] = useState([]);
    const {enqueueSnackbar} = useSnackbar();
    
    useEffect(() => {
        let isSubscribed = true;

        async function fetch() {
            try {
                let docs = await getCollection(db, collectionName);

                if (filterArchived) {
                    docs = docs.filter(doc => !doc.archived);
                }

                if (isSubscribed) {
                    setOptions(sortBy(docs, displayProperty));
                }
            } catch(e) {
                enqueueSnackbar(e.message, {variant: 'error'});
            }

            if (isSubscribed) {
                setLoading(false);
            }
        }

        fetch();
        
        return () => isSubscribed = false;
    }, [enqueueSnackbar, db, collectionName, displayProperty, filterArchived]);
    
    const {field, fieldState: {error}} = useController({name, rules});
    const {value, onChange, ...restField} = field;

    return (
        <Autocomplete
            {...rest}
            {...restField}
            loading={loading}
            multiple={multiple}
            value={value || []}
            options={options}
            onChange={(e, newValue) => {
                if (!newValue) {
                    onChange(multiple ? [] : '');
                    return;
                }

                if (!multiple) {
                    onChange(isString(newValue) ? newValue : (newValue || {}).uid);
                    return;
                }

                onChange(newValue.map(v => {
                    if (isString(v)) {
                        return v;
                    }

                    return v.uid;
                }));
            }}
            getOptionLabel={option => {
                if (isString(option)) {
                    option = options.find(o => o.uid === option);
                }

                return (option || {})[displayProperty] || '';
            }}
            isOptionEqualToValue={(option, value) => {
                if (value && isString(value)) {
                    return option.uid === value;
                }

                return value && option.uid === value.uid;
            }}
            filterSelectedOptions
            renderInput={params => (
                <TextField
                    {...params}
                    required={required}
                    label={label}
                    error={!!error}
                    helperText={error && helperText !== false ? error.message : helperText}
                />
            )}
        />
    );
};
