import React, {useCallback, useContext} from 'react';
import {Stack, Box} from '@mui/material';
import {useFormContext} from 'react-hook-form';

import {SettingsContext} from '-/contexts/Settings';

import UserAutocompleteField from '-/form/UserAutocompleteField';
import ApparatusField from '-/form/ApparatusField';

import ImportDialog from '-/pages/responses/ImportDialog';

export default function Responses(props) {
    const {loading, ...rest} = props;

    const {usesIAR} = useContext(SettingsContext);
    const {watch} = useFormContext();

    const date = watch('date');
    const dispatchTime = watch('dispatchTime');
    const sceneCleared = watch('sceneCleared');
    const users = watch('users') || [];
    const standby = watch('standby') || [];
    const cancelled = watch('cancelled') || [];

    const handleUserFilter = useCallback(allowedKey => {
        const keys = ['users', 'standby', 'cancelled'];
        const data = {
            users,
            standby,
            cancelled
        };

        return docs => {
            return docs.filter(doc => {
                for (const key of keys) {
                    if (key === allowedKey) {
                        continue;
                    }

                    const value = data[key] || [];
                    if (value && value.includes(doc.uid)) {
                        return false;
                    }
                }

                return true;
            });
        };
    }, [users, standby, cancelled]);

    return (
        <Box {...rest}>
            <Stack spacing={2}>
                <ApparatusField
                    fullWidth
                    label="Apparatus"
                    name="apparatus"
                    disabled={loading}
                />

                <UserAutocompleteField
                    fullWidth
                    showCount
                    label="Members"
                    name="users"
                    onFilter={handleUserFilter('users')}
                    disabled={loading}
                />
                
                <UserAutocompleteField
                    fullWidth
                    showCount
                    label="Standby"
                    name="standby"
                    onFilter={handleUserFilter('standby')}
                    disabled={loading}
                />

                <UserAutocompleteField
                    fullWidth
                    showCount
                    label="Cancelled"
                    name="cancelled"
                    onFilter={handleUserFilter('cancelled')}
                    disabled={loading}
                />

                {usesIAR && (
                    <Stack direction="row" justifyContent="flex-end" spacing={2}>
                        <ImportDialog date={date} startTime={dispatchTime} endTime={sceneCleared} />
                    </Stack>
                )}
            </Stack>
        </Box>
    );
};