import React, {useContext, useState, useEffect, useCallback, useMemo} from 'react';
import {Box, Button, DialogActions, DialogContent, DialogTitle, Typography, Divider} from '@mui/material';

import {useForm, FormProvider} from 'react-hook-form';
import {pickBy} from 'lodash';
import {RecordTypes} from '@embertracking/common';

import {UserContext} from '-/contexts/User';

import SelectField from '-/form/SelectField';
import TextField from '-/form/TextField';
import UserAutocompleteField from '-/form/UserAutocompleteField';
import FileField from '-/form/FileField';

import ResponsiveDialog from '-/components/ResponsiveDialog';

export default function RecordDialog({onSubmit: onUpdate, onDelete, record, open, handleClose}) {
    const [loading, setLoading] = useState(false);
    const {currentUser} = useContext(UserContext);
    const lastSelectedUser = localStorage.getItem('lastSelectedUser');

    const defaultValues = useMemo(() => {
        const {uid, role} = currentUser || {};
        let users = [];

        if (lastSelectedUser) {
            users.push(lastSelectedUser);
        } else if (role !== 'STATION') {
            users.push(uid);
        }

        return {
            type: 'MAINTENANCE',
            note: '',
            users,
            files: [],
            ...pickBy(record)
        };
    }, [record, currentUser, lastSelectedUser]);
    const methods = useForm({
        defaultValues,
        mode: 'onChange'
    });
    const {reset, handleSubmit, watch} = methods;
    const users = watch('users', []);

    useEffect(() => {
        reset({
            ...defaultValues,
            ...pickBy(record)
        });
    }, [reset, defaultValues, record]);

    useEffect(() => {
        const [user] = users || [];
        if (user) {
            const {uid} = currentUser || {};
            const userUid = typeof user === 'object' ? user.uid : user;

            if (uid !== userUid) {
                localStorage.setItem('lastSelectedUser', userUid);
            }
        }

    }, [users, currentUser]);

    const handleDelete = async () => {
        setLoading();

        const result = await onDelete(record);
        if (result) {
            handleClose();
            reset({});
        }

        setLoading(false);
    };

    const onSubmit = useCallback(async data => {
        setLoading(true);

        const result = await onUpdate(data);
        if (result) {
            handleClose();
            reset({});
        }

        setLoading(false);
    }, [handleClose, reset, onUpdate]);

    return (
        <FormProvider {...methods}>
            <ResponsiveDialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    noValidate: true,
                    onSubmit: handleSubmit(onSubmit)
                }}
            >
                <DialogTitle>{record ? 'Update Record' : 'Add New Record'}</DialogTitle>
                <DialogContent dividers>
                    <SelectField
                        sx={{mt: 1}}
                        required
                        name="type"
                        label="Type"
                        options={Object.keys(RecordTypes).map(value => {
                            return {
                                value,
                                label: RecordTypes[value]
                            };
                        })}
                        fullWidth
                        disabled={!onUpdate || loading}
                    />
                    <TextField
                        sx={{mt: 2}}
                        name="note"
                        label="Note"
                        placeholder={'Regular annual inspection, completed by John Doe Corp.'}
                        fullWidth
                        disabled={!onUpdate || loading}
                    />
                    <UserAutocompleteField
                        sx={{mt: 2}}
                        name="users"
                        label="Members"
                        fullWidth
                        required
                        disabled={!onUpdate || loading}
                    />

                    <Typography variant="h6" sx={{mt: 2}}>Files</Typography>
                    <Divider sx={{mb: 2}} />

                    <FileField
                        name="files"
                        disabled={!onUpdate || loading}
                    />
                </DialogContent>
                <DialogActions>
                    {(record && onDelete) && (
                        <>
                            <Button onClick={handleDelete} disabled={loading}>Delete Record</Button>
                            <Box sx={{flex: 1}} />
                        </>
                    )}
                    <Button onClick={handleClose} disabled={loading}>{onUpdate ? 'Cancel' : 'Close'}</Button>

                    {onUpdate && <Button variant="contained" loading={loading} type="submit" disabled={loading}>{record ? 'Update' : 'Add'} Record</Button>}
                </DialogActions>
            </ResponsiveDialog>
        </FormProvider>
    );
};