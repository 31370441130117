import React, {useState, useCallback, useContext, useEffect} from 'react';
import {Button, Box, Stack, Grid2 as Grid, Skeleton, Divider, Typography} from '@mui/material';
import {doc, collection, getDoc, addDoc, updateDoc, deleteDoc, deleteField} from 'firebase/firestore';
import {useForm, FormProvider} from 'react-hook-form';
import {useParams, useNavigate} from 'react-router-dom';
import {useSnackbar} from 'notistack';
import {get, omit} from 'lodash';
import {useConfirm} from 'material-ui-confirm';

import {UserContext} from '-/contexts/User';
import {StationsContext} from '-/contexts/Stations';
import {db, storage} from '-/firebase';

import {hasPermission, processRawDoc, ensureMomentDates, ensureJSDates, uploadImage} from '-/data/utils';

import TextField from '-/form/TextField.js';
import CheckboxField from '-/form/CheckboxField.js';
import SelectField from '-/form/SelectField.js';
import StationsField from '-/form/StationsField.js';
import ImageUploadField from '-/form/ImageUploadField';
import ColorField from '-/form/ColorField';

export default function Apparatus() {
    const {id: uid} = useParams();
    const isNew = !uid;

    const [loading, setLoading] = useState(!isNew);
    const [deleting, setDeleting] = useState(false);
    const {currentUser} = useContext(UserContext);
    const {stations} = useContext(StationsContext);
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const confirm = useConfirm();

    const methods = useForm({
        defaultValues: {
            type: 'ENGINE',
            station: null,
            tag: null,
            color: '',
            imageUrl: '',
            imageThumbnailUrl: '',
            imageFile: '',
            axles: 1,
            hasAirBrakes: false,
            pumpCapacity: '',
            tankCapacity: '',
            archived: false
        },
        mode: 'onChange'
    });
    const {handleSubmit, formState, reset, watch, setValue} = methods;
    const {isValid} = formState;

    const type = watch('type');
    const stationUid = watch('station');
    const archived = watch('archived');
    const station = stationUid && stations.find(station => station.uid === stationUid);
    const tagPlaceholder = (type && station) ? `${type[0]}${get(station, 'name')}` : null;

    useEffect(() => {
        let isSubscribed = true;

        if (isNew && !hasPermission(currentUser, 'apparatus.write')) {
            navigate('/apparatus');
            return;
        }

        const fetch = async() => {
            if (uid && !isNew) {
                const docRef = doc(db, 'apparatus', uid);
                const raw = await getDoc(docRef);
                const row = processRawDoc(raw);
                
                if (isSubscribed) {
                    reset(ensureMomentDates(row));

                    setLoading(false);
                }
            }
        };

        fetch();

        return () => isSubscribed = false;
    }, [db, isNew, reset, uid, navigate]);

    const handleArchive = async() => {
        setLoading(true);

        try {
            const docRef = doc(db, 'apparatus', uid);
            await updateDoc(docRef, {
                archived: !archived,
                ...(!archived && {
                    archivedAt: new Date(),
                    archivedBy: get(currentUser, 'uid')
                })
            });

            setValue('archived', !archived);

            enqueueSnackbar(`Apparatus ${archived ? 'archived' : 'unarchived'}`, {variant: 'success'});
        } catch (e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        setLoading(false);
    };

    const handleDelete = async() => {
        const onDelete = async() => {
            setDeleting(true);

            const ref = doc(db, 'apparatus', uid);
            await deleteDoc(ref);

            navigate('/apparatus');
        };

        const {confirmed} = await confirm({
            description: 'Are you sure you want to delete this apparatus? This cannot be undone.',
            confirmationText: 'Delete Apparatus'
        });

        if (confirmed) {
            onDelete();
        }
    };

    const onSubmit = useCallback(async data => {
        setLoading(true);

        try {
            const {tag, imageFile, ...rest} = data;

            if (isNew) {
                let docRef = collection(db, 'apparatus');
                if (imageFile) {
                    await uploadImage(`apparatus/${uid}`, imageFile);
                }

                const {id: uid} = await addDoc(docRef, ensureJSDates({
                    tag: tag || tagPlaceholder,
                    ...rest
                }));

                enqueueSnackbar('Apparatus created', {variant: 'success'});

                navigate(-1);
            } else {
                const toUpdate = ensureJSDates(omit({
                    tag: tag || tagPlaceholder,
                    ...rest
                }, 'image'));

                if (imageFile) {
                    await uploadImage(`apparatus/${uid}`, imageFile);
                } else if (imageFile == null) {
                    toUpdate.image = deleteField();
                }

                const docRef = doc(db, 'apparatus', uid);
                await updateDoc(docRef, toUpdate);

                reset(ensureMomentDates(toUpdate));

                enqueueSnackbar('Apparatus updated', {variant: 'success'});

                navigate(-1);
            }
        } catch (e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        setLoading(false);
    }, [db, isNew, uid, navigate, storage, enqueueSnackbar]);

    return (
        <FormProvider {...methods}>
            <Typography variant="h5" gutterBottom sx={{flex: 1}}>{loading ? <Skeleton width={180} /> : 'Edit Apparatus'}</Typography>

            <Divider sx={{mb: 2}} />

            <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{mt: 1}}>
                <Grid container spacing={2}>
                    <Grid size={{xs: 12, sm: 4}}>
                        <ImageUploadField name="image" />
                    </Grid>

                    <Grid size={{xs: 12, sm: 8}} container>
                        <Grid size={6}>
                            <TextField
                                fullWidth
                                label="Tag"
                                name="tag"
                                required
                                placeholder={tagPlaceholder}
                                disabled={loading || archived}
                            />
                        </Grid>

                        <Grid size={6}>
                            <SelectField
                                fullWidth
                                required
                                label="Type"
                                name="type"
                                disabled={loading || archived}
                                options={[
                                    {value: 'ENGINE', label: 'Engine'},
                                    {value: 'PUMPER', label: 'Pumper'},
                                    {value: 'TENDER', label: 'Tender'},
                                    {value: 'BUSH', label: 'Bush Truck'},
                                    {value: 'LADDER', label: 'Ladder'},
                                    {value: 'RESCUE', label: 'Rescue Truck'},
                                    {value: 'UTILITY', label: 'Utility Truck'},
                                    {value: 'TRAILER', label: 'Trailer'},
                                    {value: 'CAR', label: 'Car'}
                                ]}
                            />
                        </Grid>

                        <Grid size={6}>
                            <StationsField
                                fullWidth
                                disabled={loading || archived}
                            />
                        </Grid>

                        <Grid size={6}>
                            <ColorField
                                fullWidth
                                label="Color"
                                name="color"
                                disabled={loading || archived}
                            />
                        </Grid>

                        <Grid size={6}>
                            <TextField
                                fullWidth
                                label="Pump Capacity"
                                name="pumpCapacity"
                                placeholder="1200 gpm"
                                disabled={loading || archived}
                            />
                        </Grid>

                        <Grid size={6}>
                            <TextField
                                fullWidth
                                label="Tank Capacity"
                                name="tankCapacity"
                                placeholder="1000 gal"
                                disabled={loading || archived}
                            />
                        </Grid>

                        <Grid size={6}>
                            <SelectField
                                fullWidth
                                required
                                label="Axles"
                                name="axles"
                                disabled={loading || archived}
                                options={[
                                    {value: 1, label: 'Single'},
                                    {value: 2, label: 'Tandem'},
                                    {value: 3, label: 'Tridem'}
                                ]}
                            />
                        </Grid>
                        
                        <Grid size={6}>
                            <CheckboxField name="hasAirBrakes" label="Has Air Brakes?" disabled={loading || archived} />
                        </Grid>
                    </Grid>
                </Grid>

                <Divider sx={{mt: 2, mb: 2}} />

                <Stack direction="row" spacing={1}>
                    {!isNew && hasPermission(currentUser, 'apparatus.delete') && (
                        <Button
                            onClick={handleDelete}
                            disabled={deleting || loading}
                        >
                            Delete
                        </Button>
                    )}

                    {!isNew && hasPermission(currentUser, 'apparatus.archive') && (
                        <Button
                            onClick={handleArchive}
                            disabled={loading}
                        >
                            {archived ? 'Unarchive' : 'Archive'}
                        </Button>
                    )}

                    <Stack direction="row" spacing={1} sx={{flex: 1}} justifyContent="flex-end">
                        <Button
                            type="submit"
                            variant="contained"
                            onClick={handleSubmit(onSubmit)}
                            disabled={loading || !isValid || deleting}
                            loading={loading}
                        >
                            Save Apparatus
                        </Button>
                    </Stack>
                </Stack>
            </Box>
        </FormProvider>
    );
}