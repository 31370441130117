import React, {useState, useContext} from 'react';
import {Typography, Stack, FormControlLabel, Checkbox, Divider} from '@mui/material';

import {UserContext} from '-/contexts/User';

import {hasPermission} from '-/data/utils';

import Apparatuses from './apparatuses/Grid';

export default function() {
    const [includeArchived, setIncludeArchived] = useState(false);
    const {currentUser} = useContext(UserContext);

    return (
        <>
            <Stack direction="row" alignItems="center" sx={{mb: 1}} spacing={1}>
                <Typography variant="h5" sx={{flex: 1}}>Apparatus</Typography>

                {hasPermission(currentUser, 'apparatus.write') && (
                    <FormControlLabel
                        control={(
                            <Checkbox
                                onChange={() => setIncludeArchived(!includeArchived)}
                                checked={includeArchived}
                            />
                        )}
                        label="Include Archived"
                    />
                )}
            </Stack>

            <Divider sx={{mb: 2}} />

            <Apparatuses includeArchived={includeArchived} />
        </>
    );
};