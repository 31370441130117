import React, {useState, useEffect, useContext} from 'react';
import {Box, Typography} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useTheme, darken} from '@mui/material/styles';
import {Ranks, ChiefRanks, OfficerRanks} from '@embertracking/common';
import {isNumber} from 'lodash';

import {SettingsContext} from '-/contexts/Settings';
import {UserContext} from '-/contexts/User';

import SearchableDataGrid from '-/components/SearchableDataGrid';

const exceljsPostProcess = async ({worksheet}) => {
    const font = {name: 'Aptos Narrow', size: 11};

    worksheet.columns.forEach(column => {
        column.width = 11;
        column.alignment = {
            vertical: 'distributed',
            horizontal: 'center'
        };
    });

    worksheet.getColumn(1).width = 15;
    worksheet.getColumn(1).alignment.horizontal = 'left';

    worksheet.getColumn(2).width = 30;
    worksheet.getColumn(2).alignment.horizontal = 'left';

    worksheet.eachRow(row => {
        const {number} = row;

        if (number > 4) {
            worksheet.columns.forEach(column => {
                const {number} = column;
                const cell = row.getCell(number);
        
                if (cell.value) {
                    cell.border = {
                        top: {style: 'thin'},
                        left: {style: 'thin'},
                        bottom: {style: 'thin'},
                        right: {style: 'thin'}
                    };

                    if (number === worksheet.actualColumnCount) {
                        cell.fill = {type: 'pattern', pattern: 'solid', fgColor: {argb: 'FF92D051'}};
                    }
                }
            });
        }

        if (number > 8) {
            const cell = row.getCell(1);
            const {value} = cell;

            if (value) {
                const role = Object.keys(Ranks).find(key => Ranks[key] === value);
                if (!ChiefRanks.includes(role)) {
                    if (OfficerRanks.includes(role)) {
                        cell.fill = {type: 'pattern', pattern: 'solid', fgColor: {argb: 'FFFF0000'}};
                    } else {
                        cell.fill = {type: 'pattern', pattern: 'solid', fgColor: {argb: 'FFFFFF00'}};
                    }
                }
            }
        }

        if (number === 1) {
            row.hidden =  true;
        }

        if (number > 3 && number < 10) {
            worksheet.getCell(number, 2).alignment = {horizontal: 'right'};
        }

        row.eachCell(cell => {
            cell.font = {
                ...font,
                ...number < 4 && {
                    size: 16,
                    bold: true
                }
            };
        });
    });
};

const IncidentAttendance = ({loading, incidents = []}) => {
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const {enqueueSnackbar} = useSnackbar();
    const {siteName} = useContext(SettingsContext);
    const {users = []} = useContext(UserContext);

    const defaultColumns = [
        {
            field: 'role',
            minWidth: 140,
            valueGetter: value => {
                if (Ranks[value]) {
                    return Ranks[value];
                }

                return value;
            },
            colSpan: (value, row, colDef, grid) => {
                const {id} = row;
        
                if (['siteName', 'title'].includes(id)) {
                    const allColumns = (grid.current || {}).getAllColumns();
                    return allColumns.length;
                }
        
                return 1;
            },
            renderCell: params => {
                const {value, row} = params;
                const {id, role} = row;
                if (['siteName', 'title'].includes(id)) {
                    const variant = id === 'siteName' ? 'h4' : 'h6';
                    return (
                        <Box sx={{flex: 1, alignItems: 'center', p: 1}}>
                            <Typography sx={{textAlign: 'center'}} variant={variant}>{role}</Typography>
                        </Box>
                    );
                }
        
                return (
                    <Box sx={{p: 0.5}}>
                        {value}
                    </Box>
                );
            },
            cellClassName: params => {
                const {value} = params;

                if (value) {
                    const role = Object.keys(Ranks).find(key => Ranks[key] === value);
                    if (role) {
                        if (ChiefRanks.includes(role)) {
                            return 'bordered';
                        }

                        if (OfficerRanks.includes(role)) {
                            return 'red bordered';
                        }

                        return 'yellow bordered';
                    }
                }
            }
        },
        {
            field: 'fullName',
            minWidth: 220,
            renderCell: params => {
                const {value, row} = params;
                const {uid} = row;

                return (
                    <Box sx={{p: 0.5, flex: 1, textAlign: uid ? 'left' : 'right'}}>
                        {value}
                    </Box>
                );
            },
            cellClassName: params => {
                const {value} = params;

                if (value) {
                    return 'bordered';
                }
            }
        }
    ];

    const theme = useTheme();

    useEffect(() => {
        try {
            let docs = users.map(doc => {
                const {uid: userUid, firstName, lastName, email} = doc;

                const attendedIncidents = incidents.filter(t => (t.users || []).includes(userUid));
                const attendedIncidentsHours = attendedIncidents.reduce((total, incident) => {
                    const {durations} = incident;
                    const {salary, salaryOverrides = {}} = durations || {};
                    const userSalaryOverride = salaryOverrides[userUid];

                    if (isNumber(userSalaryOverride)) {
                        return total + userSalaryOverride;
                    }

                    if (!salary) {
                        return total;
                    }

                    return total + salary;
                }, 0);

                return {
                    ...doc,
                    attendedIncidents,
                    fullName: [lastName, firstName].filter(Boolean).join(', ') || email,
                    hours: attendedIncidentsHours,
                    ...attendedIncidents.reduce((result, incident) => {
                        const {uid: incidentUid, durations} = incident;
                        const {salary, salaryOverrides = {}} = durations || {};
                        const userSalaryOverride = salaryOverrides[userUid];

                        result[incidentUid] = isNumber(userSalaryOverride) ? userSalaryOverride : salary || 0;

                        return result;
                    }, {})
                };
            });

            docs = docs.filter(doc => doc.hours > 0);

            docs.sort((a, b) => {
                const {role: roleA} = a;
                const {role: roleB} = b;

                return Object.keys(Ranks).indexOf(roleB) - Object.keys(Ranks).indexOf(roleA);
            });

            let addedChiefDivider = false;
            let addedOfficerDivider = false;

            docs = docs.reduce((result, doc) => {
                const {role} = doc;
                const isChief = ChiefRanks.includes(role);
                const isOfficer = OfficerRanks.includes(role);

                if (!isChief && !addedChiefDivider) {
                    result.push({id: 'chiefDivider'});
                    addedChiefDivider = true;
                }
                if (!isOfficer && !addedOfficerDivider) {
                    result.push({id: 'officerDivider'});
                    addedOfficerDivider = true;
                }

                result.push(doc);

                return result;
            }, []);

            const incidentsGroupedByMonth = incidents.reduce((result, incident) => {
                const {date} = incident;
                const month = date.format('MMMM');
                if (!result[month]) {
                    result[month] = [];
                }

                result[month].push(incident);

                return result;
            }, {});

            const newColumns = [
                ...defaultColumns,
                ...incidents.map(incident => {
                    const {uid: incidentUid, date, incidentNumber} = incident;
                    const month = date.format('MMMM');
                    const monthIncidents = incidentsGroupedByMonth[month] || [];

                    return {
                        field: incidentUid,
                        headerName: incidentNumber,
                        minWidth: 110,
                        align: 'center',
                        colSpan: (value, row) => {
                            const {id} = row;
                            if (id === 'month') {
                                return monthIncidents.length;
                            }

                            return 1;
                        },
                        cellClassName: params => {
                            const {value} = params;
    
                            if (value) {
                                return 'bordered';
                            }
                        },
                        valueGetter: (value, row) => {
                            const {id} = row;
                            if (/divider/i.test(id)) {
                                return null;
                            }

                            return value || ' ';
                        },
                        renderCell: params => {
                            const {value} = params;

                            return (
                                <Box sx={{p: 0.5}}>
                                    {value}
                                </Box>
                            );
                        }
                    };
                }),
                {
                    field: 'totalHours',
                    align: 'center',
                    width: 110,
                    cellClassName: params => {
                        const {value, row} = params;
                        const {id} = row;

                        if (value && id !== 'month') {
                            return 'green end';
                        }
                        
                        return 'end';
                    },
                    valueGetter: (value, row) => {
                        const {id} = row;
                        if (id === 'month') {
                            return 'Total Hours';
                        } else if (id === 'total') {
                            const total = incidents.reduce((result, incident) => {
                                const {users = [], durations} = incident;
                                const {salary = 0, salaryOverrides = {}} = durations || {};

                                const total = users.reduce((total, user) => {
                                    const userSalaryOverride = salaryOverrides[user];
                                    if (isNumber(userSalaryOverride)) {
                                        return total + userSalaryOverride;
                                    }

                                    return total + salary;
                                }, 0);
                        
                                return result + total;
                            }, 0);

                            return total;
                        }

                        const {hours} = row;
                        return hours;
                    },
                    renderCell: params => {
                        const {value} = params;

                        return (
                            <Box sx={{p: 0.5}}>
                                {value}
                            </Box>
                        );
                    }
                }
            ];

            setColumns(newColumns);

            setRows([
                {
                    id: 'siteName',
                    role: siteName
                },
                {
                    id: 'title',
                    role: 'INCIDENT ATTENDANCE REPORT'
                },
                {
                    id: 'topDivider'
                },
                {
                    id: 'month',
                    fullName: 'FOR THE MONTH OF',
                    ...incidents.reduce((result, incident) => {
                        const {uid: incidentUid, date} = incident;
    
                        result[incidentUid] = date.format('MMMM');
    
                        return result;
                    }, {}),
                    totalHours: 'Total Hours'
                },
                {
                    id: 'date',
                    fullName: 'DATE',
                    ...incidents.reduce((result, incident) => {
                        const {uid: incidentUid, date} = incident;
    
                        result[incidentUid] = date.format('D');
    
                        return result;
                    }, {})
                },
                {
                    id: 'dispatchTime',
                    fullName: 'CALL START',
                    ...incidents.reduce((result, incident) => {
                        const {uid: incidentUid, date, dispatchTime} = incident;
                        if (!dispatchTime || !date) {
                            return result;
                        }

                        if (typeof dispatchTime === 'string') {
                            result[incidentUid] = dispatchTime;
                        } else {
                            result[incidentUid] = (dispatchTime || date).format('HH:mm');
                        }
    
                        return result;
                    }, {})
                },
                {
                    id: 'sceneCleared',
                    fullName: 'CALL END',
                    ...incidents.reduce((result, incident) => {
                        const {uid: incidentUid, sceneCleared} = incident;
                        if (!sceneCleared) {
                            return result;
                        }

                        if (typeof sceneCleared === 'string') {
                            result[incidentUid] = sceneCleared;
                        } else {
                            result[incidentUid] = sceneCleared.format('HH:mm');
                        }
    
                        return result;
                    }, {})
                },
                {
                    id: 'incidentNumber',
                    role: 'POSITION',
                    fullName: 'CALL NUMBER',
                    ...incidents.reduce((result, incident) => {
                        const {uid: incidentUid, incidentNumber} = incident;
    
                        result[incidentUid] = incidentNumber || '';
    
                        return result;
                    }, {})
                },
                ...docs,
                {
                    id: 'bottomDivider'
                },
                {
                    id: 'total',
                    fullName: 'Total Hours/Call',
                    ...incidents.reduce((result, incident) => {
                        const {uid: incidentUid, users = [], durations} = incident;
                        const {salary = 0, salaryOverrides = {}} = durations || {};

                        const total = users.reduce((total, user) => {
                            const userUid = typeof user === 'object' ? user.uid : user;
                            const userSalaryOverride = salaryOverrides[userUid];
                            if (isNumber(userSalaryOverride)) {
                                return total + userSalaryOverride;
                            }

                            return total + salary;
                        }, 0);
                        
                        result[incidentUid] = total;
    
                        return result;
                    }, {})
                }
            ]);
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }
    }, [incidents, users]);

    return (
        <SearchableDataGrid
            rows={rows}
            loading={loading}
            columns={columns}
            columnHeaderHeight={0}
            hideFooter
            disableRowSelectionOnClick
            getRowHeight={() => 'auto'}
            initialState={{
                density: 'compact'
            }}
            slotProps={{
                toolbar: {
                    disableColumnFilter: true,
                    csvOptions: {disableToolbarButton: true},
                    excelOptions: {
                        exceljsPostProcess
                    }
                }
            }}
            getRowClassName={params => {
                const {id} = params.row;
                if (/divider/i.test(id)) {
                    return 'divider';
                }
            }}
            sx={{
                '& .MuiDataGrid-row:hover': {
                    backgroundColor: 'inherit' // Or 'transparent' or whatever color you'd like
                },
                '& .MuiDataGrid-cell:not(.MuiDataGrid-cellEmpty)': {
                    borderRight: '1px solid var(--template-palette-divider)'
                },
                '& .MuiDataGrid-cell, & .MuiDataGrid-cell.end': {
                    borderColor: 'transparent'
                },
                '& .MuiDataGrid-cell.green': {
                    backgroundColor: 'complete.main',
                    borderColor: darken(theme.palette.complete.main, 0.2),
                    borderRight: `1px solid ${darken(theme.palette.complete.main, 0.2)}`
                },
                '& .MuiDataGrid-cell.red': {
                    backgroundColor: '#ff0000',
                    borderColor: darken('#ff0000', 0.1),
                    borderRight: `1px solid ${darken('#ff0000', 0.1)}`
                },
                '& .MuiDataGrid-cell.yellow': {
                    backgroundColor: '#ffeb00',
                    borderColor: darken('#ffeb00', 0.2),
                    borderRight: `1px solid ${darken('#ffeb00', 0.2)}`
                },
                '& .MuiDataGrid-cell.bordered': {
                    borderColor: 'var(--template-palette-divider)'
                },
                '& .MuiDataGrid-row.divider': {
                    height: 20,
                    '& .MuiDataGrid-cell:not(.MuiDataGrid-cellEmpty)': {
                        borderTopColor: 'var(--template-palette-divider)',
                        borderRightColor: 'transparent'
                    }
                }
            }}
        />
    );
};

export default IncidentAttendance;