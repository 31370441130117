import React, {useState, useEffect, useMemo, useContext, useRef, useCallback} from 'react';
import {useGridApiRef, GridActionsCellItem} from '@mui/x-data-grid';
import {Box, Typography, Button, Stack, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useForm, FormProvider} from 'react-hook-form';
import {LoadingButton} from '@mui/lab';
import {unstable_debounce as debounce} from '@mui/utils';
import moment from 'moment';
import {collection, doc, getDocs, getCountFromServer, addDoc, deleteDoc, limit, query, orderBy, startAfter} from 'firebase/firestore';
import {
    Add as AddIcon,
    Delete as DeleteIcon
} from '@mui/icons-material';
import {OfficerRanks, DrivingCodes} from '@embertracking/common';

import {db} from '-/firebase.js';

import {UserContext} from '-/contexts/User';
import {processRawDocs, hasPermission, ensureJSDates, populateKeyFromCollection} from '-/data/utils';

import SearchableDataGrid from '-/components/SearchableDataGrid';

import DatePickerField from '-/form/DatePickerField';
import CollectionAutocompleteField from '-/form/CollectionAutocompleteField';
import UserAutocompleteField from '-/form/UserAutocompleteField';
import SelectField from '-/form/SelectField';
import NumberField from '-/form/NumberField';

import ResponsiveDialog from '-/components/ResponsiveDialog';

const PAGE_SIZE = 10;

const AddDialog = function({onSubmit: onAdd, open, handleClose}) {
    const [loading, setLoading] = useState(false);
    const methods = useForm({
        defaultValues: {
            createdAt: moment(),
            apparatus: null,
            user: null,
            supervisor: null,
            code: 'ROUTINE'
        },
        mode: 'onChange'
    });
    const {reset, handleSubmit} = methods;

    const onSubmit = useCallback(async data => {
        setLoading(true);

        const result = await onAdd(data);
        
        if (result) {
            handleClose();
            reset({});
        }

        setLoading(false);
    }, [handleClose, onAdd, reset]);

    useEffect(() => {
        reset({
            createdAt: moment(),
            code: 'ROUTINE'
        });
    }, [open]);

    return (
        <FormProvider {...methods}>
            <ResponsiveDialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    noValidate: true,
                    onSubmit: handleSubmit(onSubmit)
                }}
            >
                <DialogTitle>Add New Driver Training Log</DialogTitle>
                <DialogContent dividers>
                    <Stack spacing={2}>
                        <Stack spacing={2} direction="row">
                            <DatePickerField
                                name="createdAt"
                                label="Date"
                                fullWidth
                                required
                                disabled={loading}
                            />
                            <CollectionAutocompleteField
                                name="apparatus"
                                label="Apparatus"
                                fullWidth
                                required
                                disabled={loading}
                                collection="apparatus"
                                displayProperty="tag"
                                filterArchived
                                multiple={false}
                            />
                            <SelectField
                                name="code"
                                label="Code"
                                fullWidth
                                required
                                disabled={loading}
                                options={Object.keys(DrivingCodes).map(key => ({value: key, label: DrivingCodes[key]}))}
                            />
                        </Stack>
                        <Stack spacing={2} direction="row">
                            <UserAutocompleteField
                                name="user"
                                label="Driver"
                                fullWidth
                                required
                                disabled={loading}
                                multiple={false}
                            />
                            <UserAutocompleteField
                                name="supervisor"
                                label="Right Seat"
                                fullWidth
                                required
                                allowedRanks={[
                                    ...OfficerRanks,
                                    'TEAM_LEADER'
                                ]}
                                disabled={loading}
                                multiple={false}
                            />
                            <NumberField
                                name="hours"
                                label="Hours"
                                fullWidth
                                required
                                disabled={loading}
                            />
                        </Stack>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} disabled={loading}>Cancel</Button>
                    <LoadingButton loading={loading} variant="contained" type="submit" disabled={loading}>Add Driver Log</LoadingButton>
                </DialogActions>
            </ResponsiveDialog>
        </FormProvider>
    );
};

const DriverLogs = function() {
    const {currentUser} = useContext(UserContext);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const [adding, setAdding] = useState(false);

    const handleDeleteClick = uid => async() => {
        const onDelete = async() => {
            const ref = doc(db, 'driverLogs', uid);
            await deleteDoc(ref);
            await reload();
        };

        enqueueSnackbar('Are you sure you want to delete this driving log?', {
            variant: 'warning',
            action: key => {
                return (
                    <>
                        <Button onClick={() => {
                            closeSnackbar(key);
                            onDelete();
                        }}>
                            Delete
                        </Button>
                        <Button onClick={() => closeSnackbar(key)}>
                            Cancel
                        </Button>
                    </>
                );
            }
        });
    };

    const canDelete = hasPermission(currentUser, 'driverLogs.delete');

    const columns = [
        {
            field: 'createdAt',
            headerName: 'Date',
            width: 100,
            type: 'date',
            valueFormatter: value => value ? moment(value).format('MM/DD/YYYY') : ''
        },
        {
            field: 'user',
            headerName: 'Driver',
            flex: 1,
            minWidth: 140,
            valueGetter: user => {
                const {fullName, email} = user || {};
                return fullName || email;
            }
        },
        {
            field: 'supervisor',
            headerName: 'Right Seat',
            flex: 1,
            minWidth: 140,
            valueGetter: user => {
                const {fullName, email} = user || {};
                return fullName || email;
            }
        },
        {
            field: 'apparatus',
            headerName: 'Apparatus',
            minWidth: 90,
            width: 110,
            valueGetter: apparatus => {
                const {tag} = apparatus || {};
                return tag;
            }
        },
        {
            field: 'code',
            headerName: 'Code',
            width: 100,
            minWidth: 100,
            valueGetter: value => DrivingCodes[value]
        },
        {
            field: 'hours',
            headerName: 'Hours',
            width: 100,
            minWidth: 100
        },
        ...canDelete ? [{
            type: 'actions',
            field: 'actions',
            headerName: '',
            width: 50,
            cellClassName: 'actions',
            getActions: ({id}) => {
                return [
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        key={`delete-${id}`}
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />
                ];
            }
        }] : []
    ];

    const handleAdd = async data => {
        const ref = collection(db, 'driverLogs');
        await addDoc(ref, ensureJSDates(data));

        await reload();

        return true;
    };

    const apiRef = useGridApiRef();
    const [initialRows, setInitialRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [lastDoc, setLastDoc] = useState(null);
    const lastRowRef = useRef(null);

    const fetchRowsFromFirestore = useCallback(async params => {
        const {firstRowToRender, lastRowToRender} = params;

        try {
            let limitCount = PAGE_SIZE;

            let q = query(
                collection(db, 'driverLogs'),
                orderBy('createdAt', 'desc'),
                limit(limitCount)
            );

            if (lastDoc && firstRowToRender !== 0 && lastRowToRender !== lastRowRef.current) {
                limitCount = lastRowToRender - lastRowRef.current;

                q = query(
                    collection(db, 'driverLogs'),
                    orderBy('createdAt', 'desc'),
                    startAfter(lastDoc),
                    limit(limitCount)
                );
            } else if (lastDoc) {
                limitCount = lastRowToRender - firstRowToRender;

                q = query(
                    collection(db, 'driverLogs'),
                    orderBy('createdAt', 'desc'),
                    limit(limitCount)
                );
            }

            const snapshot = await getDocs(q);
            let docs = processRawDocs(snapshot);
            docs = await populateKeyFromCollection(db, docs, 'apparatus', 'apparatus');
            docs = await populateKeyFromCollection(db, docs, 'supervisor', 'users');
            docs = await populateKeyFromCollection(db, docs, 'user', 'users');

            setLastDoc(snapshot.docs[snapshot.docs.length - 1]);
            lastRowRef.current = lastRowToRender;

            return docs;
        } catch (error) {
            return [];
        }
    }, [lastDoc]);

    const fetch = async() => {
        try {
            const result = await getCountFromServer(collection(db, 'driverLogs'));
            const count = result.data().count;

            const rows = await fetchRowsFromFirestore({
                firstRowToRender: 0,
                lastRowToRender: lastRowRef.current || PAGE_SIZE
            });

            setInitialRows(rows);
            setRowCount(count);
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }
    };

    const reload = async() => {
        const result = await getCountFromServer(collection(db, 'driverLogs'));
        const {count} = result.data();

        const docs = await fetchRowsFromFirestore({
            firstRowToRender: 0,
            lastRowToRender: Math.min(count - 1, lastRowRef.current || PAGE_SIZE)
        });

        setRowCount(count);

        apiRef.current.unstable_replaceRows(0, docs);

        setInitialRows(docs);
    };

    useEffect(() => {
        (async () => {
            fetch();
        })();
    }, []);

    const handleFetchRows = useCallback(async params => {
        const {firstRowToRender, lastRowToRender} = params;
        if (lastRowRef.current && lastRowToRender === lastRowRef.current) {
            return;
        }

        const startingRow = lastRowRef.current || firstRowToRender || 0;
        const docs = await fetchRowsFromFirestore({
            firstRowToRender: lastRowRef.current,
            lastRowToRender
        });
        apiRef.current.unstable_replaceRows(startingRow, docs);
    }, [apiRef, fetchRowsFromFirestore, lastDoc]);

    const debouncedHandleFetchRows = useMemo(() => {
        return debounce(handleFetchRows, 200);
    }, [handleFetchRows]);

    return (
        <Box sx={{flex: 1, display: 'flex', flexDirection: 'column'}}>
            <AddDialog open={adding} onSubmit={handleAdd} handleClose={() => setAdding(false)} />

            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{mb: 2}}>
                <Typography variant="h5" gutterBottom>Driver Training Logs</Typography>
                <Button variant="contained" onClick={() => setAdding(true)} startIcon={<AddIcon />}>Add Driver Training Log</Button>
            </Stack>

            <Box sx={{flex: 1, mt: 2, position: 'relative', minHeight: 300}}>
                <Box sx={{inset: 0, position: 'absolute'}}>
                    <SearchableDataGrid
                        hasConatiner={false}
                        rows={initialRows}
                        columns={columns}
                        apiRef={apiRef}
                        hideFooterPagination
                        rowCount={rowCount}
                        disableColumnSorting
                        disableColumnFilter
                        rowsLoadingMode="server"
                        onFetchRows={debouncedHandleFetchRows}
                        localeText={{noRowsLabel: 'No driver training logs'}}
                        sx={{
                            '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {py: '8px'},
                            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {py: 1},
                            '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {py: '22px'}
                        }}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default DriverLogs;