import React, {useState, useCallback, useContext, useEffect} from 'react';
import {Stack, Button, Checkbox, FormControlLabel} from '@mui/material';
import {get} from 'lodash';
import {doc, onSnapshot} from 'firebase/firestore';
import {useSnackbar} from 'notistack';

import {db} from '-/firebase.js';
import {UserContext} from '-/contexts/User';
import useSkills from '-/hooks/useSkills';

import {hasPermission, ensureMomentDates, callFunction} from '-/data/utils';

import MemberTraining from '-/dialogs/MemberTraining';

import {TrainingGrid, renderTrainingCell, renderTrainingLevelCell} from '-/pages/matrix/Qualification';

export default function User(props) {
    const {user: rawUser} = props;

    const [user, setUser] = useState(rawUser || {});
    const [loading, setLoading] = useState(true);
    const [downloading, setDownloading] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [showAllQualifications, setShowAllQualifications] = useState(false);
    const [skillsGridRows, setSkillsGridRows] = useState([]);
    const {currentUser} = useContext(UserContext);
    const {role} = currentUser || {};
    const {enqueueSnackbar} = useSnackbar();
    const {skills: allSkills} = useSkills();

    const handleFetch = async raw => {
        let userData = ensureMomentDates({
            id: raw.id,
            uid: raw.id,
            ...raw.data()
        });

        setLoading(false);
        setUser(userData);

        let newSetSkillsGridRows = [];

        Object.keys(allSkills).forEach(skill => {
            const {label, skills = [], qualification = true} = allSkills[skill];

            if (qualification === false) {
                return;
            }

            newSetSkillsGridRows.push({
                id: skill,
                path: label,
                isLevel: true
            });

            Object.keys(skills).forEach(subSkill => {
                const rawSubSkill = skills[subSkill];
                const {label: subSkillLabel, skill: subSkillSkill} = typeof rawSubSkill === 'string' ? {label: rawSubSkill} : rawSubSkill;
                const field = `skills.${skill}.${subSkill}`;
                let value = get(userData, field, 'INCOMPLETE');
                value = typeof value === 'string' ? {status: value} : value;

                if (subSkillSkill) {
                    return;
                }

                newSetSkillsGridRows.push({
                    ...userData,
                    id: `${label}/${subSkill || subSkillLabel}`,
                    path: `${label}/${subSkill || subSkillLabel}`,
                    groupingLabel: subSkillLabel,
                    field,
                    ...value
                });
            });
        });

        if (isSubscribed) {
            setSkillsGridRows(newSetSkillsGridRows);
        }
    };
    
    let isSubscribed = true;

    const handleDownloadAllQualifications = async () => {
        setDownloading(true);

        try {
            const data = await callFunction('downloadAllQualifications', {uid: user.uid}, {responseType: 'blob'});
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'qualifications.zip');
            document.body.appendChild(link);
            link.click();
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }
        
        setDownloading(false);
    };

    useEffect(() => {
        const {uid} = rawUser || {};
        if (!uid) {
            return;
        }

        const ref = doc(db, 'users', uid);
        const snapshot = onSnapshot(ref, handleFetch);
        
        return () => {
            snapshot();
            isSubscribed = false;
        };
    }, [rawUser, currentUser]);

    const handleCellClick = useCallback(params => {
        const {row} = params;
        const {id, isLevel = false} = row;
        let {field} = row;

        if (!hasPermission(currentUser, 'users.write')) {
            return;
        }

        if (isLevel) {
            field = `skills.${id}`;
        }

        setDialogOpen({
            field,
            isLevel,
            member: user
        });
    }, [user]);

    const canViewDialog = hasPermission(currentUser, 'users.write') || role === 'ADMINISTRATOR';

    let rows = skillsGridRows;
    if (!showAllQualifications) {
        rows = rows.filter(row => row.status && row.status !== 'INCOMPLETE');
    }

    return (
        <>
            {canViewDialog && (
                <>
                    {!!dialogOpen && <MemberTraining open={!!dialogOpen} {...dialogOpen} editable={hasPermission(currentUser, 'users.write')} onClose={() => setDialogOpen(false)} />}

                    <Stack direction="row" spacing={2} sx={{mb: 2, justifyContent: 'flex-end'}}>
                        <FormControlLabel
                            control={(
                                <Checkbox
                                    onChange={() => setShowAllQualifications(!showAllQualifications)}
                                    checked={showAllQualifications}
                                />
                            )}
                            label="Show Incomplete Qualifications"
                        />
                        
                        <Button loading={downloading} variant="contained" onClick={handleDownloadAllQualifications}>Download All Qualifications</Button>
                    </Stack>
                </>
            )}

            <TrainingGrid
                loading={loading}
                columns={[{
                    field: 'status',
                    flex: 1,
                    headerName: 'Status',
                    sortable: false,
                    renderCell: params => {
                        const {row} = params;
                        const {id, isLevel = false, field} = row;
                        if (isLevel) {
                            return renderTrainingLevelCell(id)({row: user});
                        }
                        
                        return renderTrainingCell(field)({row: user});
                    }
                }]}
                treeData
                getTreeDataPath={row => row.path.split('/')}
                onCellClick={handleCellClick}
                groupingColDef={{
                    headerName: 'Level',
                    flex: 1.5,
                    cellClassName: params => {
                        const {rowNode} = params;
                        const {depth = 0} = rowNode;
                        
                        if (depth === 0) {
                            return 'grouping-cell-header';
                        }

                        return 'grouping-cell';
                    },
                    valueGetter: (value, row) => {
                        const {groupingLabel} = row;
                        if (groupingLabel) {
                            return groupingLabel;
                        }

                        return value;
                    }
                }}
                rows={rows}
                defaultGroupingExpansionDepth={-1}
            />
        </>
    );
}