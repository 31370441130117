import React, {useEffect, useState, useCallback, useContext, useMemo} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Button, Stack, Paper, Box, Typography, Skeleton, Card, CardActionArea, FormControlLabel, Divider, Checkbox, CardContent, Grid2 as Grid} from '@mui/material';
import {collection, onSnapshot, addDoc, query, where} from 'firebase/firestore';
import {get} from 'lodash';
import {useSnackbar} from 'notistack';
import * as MaterialIcons from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import {ref, getDownloadURL} from 'firebase/storage';

import * as Icons from '-/components/Icons';

import {db, storage} from '-/firebase';
import {UserContext} from '-/contexts/User';

import {hasPermission, processRawDocs, populateKeyFromCollection} from '-/data/utils';

import CategoryDialog from './CategoryDialog';
import ArchivedChip from '-/components/ArchivedChip';

const CategoryItem = ({doc, onClick}) => {
    const {icon, name, image, apparatus, archived, archivedAt} = doc;
    const Icon = icon && ((Icons || {})[icon] || MaterialIcons[icon]);
    const [imageUrl, setImageUrl] = useState(null);

    useEffect(() => {
        const {filePath, thumbnailPath} = image || {};

        let isSubscribed = true;

        const fetch = async() => {
            try {
                if (thumbnailPath) {
                    const url = await getDownloadURL(ref(storage, thumbnailPath));
                    if (isSubscribed) {
                        setImageUrl(url);
                    }

                    return;
                }

                if (filePath) {
                    const url = await getDownloadURL(ref(storage, filePath));
                    if (isSubscribed) {
                        setImageUrl(url);
                    }

                    return;
                }
            } catch(e) {
                console.warn(e);
            }
        };
        
        setImageUrl(null);
        fetch();

        return () => isSubscribed = false;
    }, [image]);

    return (
        <Grid key={doc.id} size={{xs: 6, sm: 4, md: 4, lg: 3}}>
            <Card variant="outlined" {...archived && {severity: 'warning'}}>
                <CardActionArea onClick={e => onClick(e, doc)}>
                    <CardContent
                        sx={{
                            aspectRatio: '4/3',
                            p: 0,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1,
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'relative'
                        }}
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                                zIndex: 0,
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: 0,
                                ...imageUrl && {backgroundImage: `url(${imageUrl})`},
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                opacity: archived ? 0.1 : 0.5,
                                filter: 'blur(3px) brightness(1.2)'
                            }}
                        />
                        <Box
                            sx={{
                                zIndex: 1,
                                background: imageUrl && 'rgba(255, 255, 255, 0.8)',
                                p: 1,
                                borderRadius: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                gap: 1
                            }}
                        >
                            {Icon && <Icon sx={{fontSize: 50}} />}
                            <Typography variant="h5" sx={{textAlign: 'center'}}>{name}</Typography>
                            {apparatus && <Typography variant="body2">{get(apparatus, 'tag')}</Typography>}
                        </Box>
                        <ArchivedChip {...{archived, archivedAt}} />
                    </CardContent>
                </CardActionArea>
            </Card>
        </Grid>
    );
};

export default function CategoriesGrid(props) {
    const {archived, ...rest} = props;

    const params = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [docs, setDocs] = useState([]);
    const [open, setOpen] = useState(false);
    const [includeArchived, setIncludeArchived] = useState(false);
    const {currentUser} = useContext(UserContext);
    const {uid: userUid} = currentUser;
    const {enqueueSnackbar} = useSnackbar();

    const isRoot = Object.keys(params).length === 1;
    const baseUrl = useMemo(() => {
        let parts = [];

        Object.entries(params).forEach(([key, value]) => {
            if (key !== '*' && value) {
                parts.push('gear', value);
            }
        });

        if (parts.length === 0) {
            return '/gear/';
        }

        return `/${parts.join('/')}/gear/`;
    }, [params]);

    const onClick = useCallback((e, doc) => {
        const {metaKey} = e;
        const {id} = doc;

        const url = `${baseUrl}${id}`;
        if (metaKey) {
            window.open(url);
            return;
        }

        navigate(url);
    }, [navigate, baseUrl]);

    const refArgs = useMemo(() => {
        let refArgs = [db];

        Object.entries(params).forEach(([key, param]) => {
            if (key !== '*' && param) {
                refArgs.push('gear', param);
            }
        });

        refArgs.push('gear');

        return refArgs;
    }, [db, params]);

    useEffect(() => {
        setLoading(true);

        let ref = collection(...refArgs);
        if (!includeArchived) {
            ref = query(ref, where('archived', '!=', true));
        }

        const unsubscribe = onSnapshot(ref, async snapshot => {
            let docs = processRawDocs(snapshot);
            docs = await populateKeyFromCollection(db, docs, 'apparatus', 'apparatus');
            setDocs(docs);

            setLoading(false);
        });
        
        return () => {
            unsubscribe();
        };
    }, [db, refArgs, includeArchived]);

    const onAdd = useCallback(async data => {
        try {
            const ref = collection(...refArgs);
            const category = {
                ...data,
                createdAt: new Date(),
                user: userUid
            };

            await addDoc(ref, category);
            return category;
        } catch (e) {
            enqueueSnackbar(e.message, {variant: 'error'});
            setLoading(false);
        }
    }, [enqueueSnackbar, refArgs, userUid]);

    let addButton = null;
    if (!archived && hasPermission(currentUser, 'gear.write')) {
        addButton = (
            <>
                <CategoryDialog onSubmit={onAdd} parent={!isRoot} open={open} handleClose={() => setOpen(false)} />
                <Button disabled={loading} startIcon={<AddIcon />} variant="outlined" size="small" onClick={() => setOpen(true)}>Add New {isRoot ? 'Category' : 'Subcategory'}</Button>
            </>
        );
    }

    const isEmpty = docs.length === 0 && !loading;

    if ((!hasPermission(currentUser, 'gear.write') || archived) && isEmpty) {
        return null;
    }

    const Container = isRoot ? Box : Paper;

    return (
        <Container {...rest} {...!isRoot && {variant: 'outlined', sx: {p: 2, mb: 2}}}>
            <Stack direction="row" alignItems="center" justifyContent={isEmpty ? 'flex-end' : 'space-between'} spacing={1}>
                {!isEmpty && (
                    <Typography variant="h5" sx={{flex: 1}}>
                        {isRoot ? 'Gear' : 'Subcategories'}
                    </Typography>
                )}

                {hasPermission(currentUser, 'gear.delete') && (
                    <FormControlLabel
                        control={(
                            <Checkbox
                                onChange={() => setIncludeArchived(!includeArchived)}
                                checked={includeArchived}
                            />
                        )}
                        label="Include Archived"
                    />
                )}
                
                {!isRoot && addButton}
            </Stack>

            {!isEmpty && <Divider sx={{mt: isRoot ? 1 : 2, mb: 2}} />}

            <Grid container spacing={2}>
                {loading ? (
                    <Grid size={{xs: 12, sm: 6, md: 4, lg: 3}}>
                        <Skeleton variant="rounded" height={180} />
                    </Grid>
                ) : (
                    !!docs.length && docs.map(doc => {
                        return (
                            <CategoryItem key={doc.id} doc={doc} onClick={onClick}  />
                        );
                    })
                )}

                {!loading && !docs.length && isRoot && (
                    <Grid size={{xs: 12}}>
                        <Typography variant="body2">No categories found.</Typography>
                    </Grid>
                )}
            </Grid>

            {isRoot && addButton && (
                <Stack sx={{mt: 2}} alignItems="flex-end">
                    {addButton}
                </Stack>
            )}
        </Container>
    );
};