import {useEffect, useContext} from 'react';
import {logEvent} from '@firebase/analytics';
import {useLocation} from 'react-router-dom';

import {analytics} from '-/firebase';

import {SettingsContext} from '-/contexts/Settings';

export default function(title) {
    const {siteName = 'Ember Tracking'} = useContext(SettingsContext);
    const location = useLocation();

    useEffect(() => {
        document.title = title ? `${title} | ${siteName}` : siteName;
    }, [title]);

    useEffect(() => {
        try {
            logEvent(analytics, 'screen_view', {
                firebase_screen: location.pathname,
                firebase_screen_class: 'firebase-routes-analytics'
            });
        } catch(e) {
            console.warn('Failed to log screen view:', e);
        }
    }, [location]);

    return null;
}